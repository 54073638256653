import { ConditionCode } from '@/shared/api/protocol-ts/model/dto_report_condition_codes_pb';
import { Localization } from '@/shared/api/protocol-ts/model/dto_report_localization_pb';
import { Condition } from '@/shared/api/protocol-ts/model/dto_report_condition_pb';

export const getSlicesMasks =
  (conditionCodes: ConditionCode[]) => (conditions: Condition[]) =>
    conditions.reduce((masks, condition) => {
      if (conditionCodes.includes(condition.Code)) {
        return masks.concat(condition.Localizations);
      }

      return masks;
    }, [] as Localization[]);
