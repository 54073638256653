import { FC, ReactNode, useEffect, useRef } from 'react';
import cn from 'classnames';

import { CORS_POLICY, RenderPreviewSettings } from '@/shared/config';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import {
  MedicalImageRender,
  PBLProps,
} from '@/shared/graphics/medicalImageRender/MedicalImageRender';
import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';
import { MedicalImageViewOptions } from '@/shared/api/protocol-ts/model/dto_common_image_view_options_pb';

import { reportsModel } from '@/entities/reports';
import { toothModel } from '@/entities/tooth';
import {
  IOXRayImagesInterfaceModel,
  groupIOXRayImagesByPartition,
} from '@/entities/IOXRayImagesMatrix';
import { organizationModel } from '@/entities/organization';

import { Landmark, useTeethLandmarks } from '@/features/toothLandmark';
import { hoveredConditionBBoxesModel } from '@/features/hoveredConditionBBoxes';
import { maskFiltersModel } from '@/features/renderMasks';
import { convertGroupedIOXRayImagesToMIRenderImageInterface } from '@/features/MIRenderInterface';

import styles from './IOXRayReportRender.module.scss';

type IOXRayReportRenderProps = {
  className?: string;
  children?: ReactNode;
  previewSettings?: RenderPreviewSettings;
};

export const IOXRayReportRender: FC<IOXRayReportRenderProps> = (props) => {
  const { className, children, previewSettings } = props;

  const dispatch = useAppDispatch();

  const reportID = useAppSelector(reportsModel.selectors.selectCurrentReportID);
  const reportViewOptions = useAppSelector(
    reportsModel.selectors.selectCurrentReportViewOptions,
  );
  const showPBL = useAppSelector(
    maskFiltersModel.selectors.selectIsLandmarksShown,
  );
  const hidePBLFeatureFlag = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_Hide_PBLRulesAndMeasurements,
    ),
  );
  const reportReadyForRender = useAppSelector(
    reportsModel.selectors.selectReportReadyForRender,
  );
  const reportMasksReadyForRender = useAppSelector(
    reportsModel.selectors.selectReportMasksReadyForRender,
  );
  const reportPBLReadyForRender = useAppSelector(
    reportsModel.selectors.selectReportPBLReadyForRender,
  );
  const IOXRayImagesInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectIOXRayImagesInterfaceByReportID(
      reportID || '',
    ),
  );
  const activeMaskFilters = useAppSelector(
    maskFiltersModel.selectors.selectActiveFilters,
  );

  const mainViewRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const groupedIOXRayImages = groupIOXRayImagesByPartition(
    IOXRayImagesInterface,
  );

  const teethIDs = useAppSelector(
    toothModel.selectors.selectReportROITeethIDs(reportID as string),
  );

  const { getPBLsForImage } = useTeethLandmarks(teethIDs);

  // Hovered condition BBoxes
  const hoveredBBoxes = useAppSelector(
    hoveredConditionBBoxesModel.selectors.selectHoveredBBoxList,
  );

  const masksRenderData = useAppSelector(
    maskFiltersModel.selectors.selectReportActiveMasks,
  );

  const hideMasksAndBBoxes = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_Hide_ConditionsMasks,
    ),
  );

  useEffect(() => {
    MedicalImageRender.setViewRef(mainViewRef, 'main');
    MedicalImageRender.setCanvasRef(canvasRef);
  }, []);

  const teeth = useAppSelector(toothModel.selectors.selectAll);

  useEffect(() => {
    if (MedicalImageRender.isRunning()) {
      MedicalImageRender.updateLayout(
        convertGroupedIOXRayImagesToMIRenderImageInterface(groupedIOXRayImages),
      );
    }
  }, [groupedIOXRayImages]);

  const isPreviewMode = previewSettings?.isPreview;

  // render initialization
  useEffect(() => {
    if (
      reportReadyForRender &&
      !!IOXRayImagesInterface.length &&
      teeth.length
    ) {
      performance.mark('InitializeIOXRayRender:start');

      MedicalImageRender.setCredentials(CORS_POLICY);

      const convertedImages =
        convertGroupedIOXRayImagesToMIRenderImageInterface(groupedIOXRayImages);

      const isRenderStartCorrect = MedicalImageRender.run(
        reportID!,
        [
          [
            convertedImages.UpperLeft,
            convertedImages.UpperMiddle,
            convertedImages.UpperRight,
          ],
          [
            convertedImages.MiddleLeft,
            convertedImages.MiddleMiddle,
            convertedImages.MiddleRight,
          ],
          [
            convertedImages.LowerLeft,
            convertedImages.LowerMiddle,
            convertedImages.LowerRight,
          ],
        ],
        reportViewOptions as MedicalImageViewOptions,
      );

      if (!isRenderStartCorrect) {
        return;
      }

      if (isPreviewMode) {
        // WARN: this is temporary solution to show masks in preview mode. It will be fixed later.
        // MedicalImageRender.layoutModes.fullScreenMatrix();
        MedicalImageRender.activateMode('printMode');
      } else {
        MedicalImageRender.setTeeth(teeth);

        MedicalImageRender.addEventListener('layout', (event) => {
          if (event.mode === 'focus') {
            dispatch(reportsModel.actions.setToolbarActiveControl('view'));
            dispatch(reportsModel.actions.setFocusedMetaImageID(event.id));
          }
        });
      }

      performance.mark('InitializeIOXRayRender:end');
      performance.measure(
        'InitializeIOXRayRender',
        'InitializeIOXRayRender:start',
        'InitializeIOXRayRender:end',
      );
    }
  }, [reportReadyForRender, IOXRayImagesInterface, groupedIOXRayImages, teeth]);

  // NOTE: PBL should be set up just one time after render is done.
  useEffect(() => {
    if (reportPBLReadyForRender && MedicalImageRender.isRunning()) {
      performance.mark('setPBLs:start');
      if (!isPreviewMode) {
        const PBLsData = IOXRayImagesInterface.map((data) => {
          const initialPBLList = getPBLsForImage(data.asset.ID);

          if (initialPBLList.length === 0) {
            return undefined;
          }

          const getPBL = (landmark: Landmark) => ({
            start: {
              x: landmark.lowPoint?.ModelPosition?.X || 0,
              y: landmark.lowPoint?.ModelPosition?.Y || 0,
            },
            end: {
              x: landmark.upPoint?.ModelPosition?.X || 0,
              y: landmark.upPoint?.ModelPosition?.Y || 0,
            },
            color: landmark.color,
            textProps: {
              color: landmark.color === '#D4D4D4' ? 0 : 0xffffff,
            },
          });

          return {
            imageID:
              data.imageMeta?.GeneratedAssetID || data.imageMeta?.StudyAssetID,
            PBLs: initialPBLList.map((pbl) => {
              return getPBL(pbl);
            }),
            scale: data.imageMeta.Scale?.X || 1,
          };
        }).filter((data) => data) as PBLProps[];

        if (PBLsData) {
          MedicalImageRender.setPBLs(PBLsData);

          if (showPBL && !hideMasksAndBBoxes && !hidePBLFeatureFlag) {
            MedicalImageRender.showPBLs();
          }
          performance.mark('setPBLs:end');
          performance.measure('setPBLs', 'setPBLs:start', 'setPBLs:end');
        }
      }
    }
  }, [reportPBLReadyForRender]);

  // Toggle PBL
  // TODO: Is it possible to move it on perio toggle function to avoid useEffect?
  useEffect(() => {
    if (reportPBLReadyForRender && MedicalImageRender.isRunning()) {
      if (hidePBLFeatureFlag) {
        MedicalImageRender.hidePBLs();
      } else if (!hideMasksAndBBoxes && showPBL) {
        MedicalImageRender.showPBLs();
      } else {
        MedicalImageRender.hidePBLs();
      }
    }
  }, [
    reportPBLReadyForRender,
    showPBL,
    hideMasksAndBBoxes,
    hidePBLFeatureFlag,
  ]);

  useEffect(
    () => () => {
      MedicalImageRender.shutdown();
    },
    [],
  );

  // Render masks
  useEffect(() => {
    if (previewSettings?.isPreview) {
      if (
        MedicalImageRender.isRunning() &&
        reportMasksReadyForRender &&
        !hideMasksAndBBoxes
      ) {
        MedicalImageRender.deleteMasks();

        if (masksRenderData.length > 0 && previewSettings?.showMasks) {
          MedicalImageRender.addMasks(masksRenderData);
          MedicalImageRender.showMasks(activeMaskFilters);
        }
      }
    } else {
      if (
        MedicalImageRender.isRunning() &&
        reportMasksReadyForRender &&
        !hideMasksAndBBoxes
      ) {
        MedicalImageRender.deleteMasks();

        if (masksRenderData.length > 0) {
          MedicalImageRender.addMasks(masksRenderData);
          MedicalImageRender.showMasks(activeMaskFilters);
        }
      }
    }
  }, [
    masksRenderData.length, // WARN: do not add array of object as dependency in useEffect, because it adds dramatic perfomace issue.
    reportMasksReadyForRender,
    hideMasksAndBBoxes,
    activeMaskFilters,
    previewSettings?.showMasks,
    previewSettings?.isPreview,
  ]);

  // BBoxes render
  useEffect(() => {
    if (MedicalImageRender.isRunning() && !hideMasksAndBBoxes) {
      if (hoveredBBoxes) {
        MedicalImageRender.deleteConditionBoxes();
        MedicalImageRender.addConditionBoxes(hoveredBBoxes);
      } else {
        MedicalImageRender.deleteConditionBoxes();
      }
    }
  }, [hoveredBBoxes]);

  // Preview settings
  useEffect(() => {
    if (MedicalImageRender.isRunning() && previewSettings?.isPreview) {
      MedicalImageRender.activateMode('printMode');

      if (previewSettings?.isInverted) {
        MedicalImageRender.invertColors();
      } else if (!previewSettings?.isInverted) {
        MedicalImageRender.straightColors();
      }
    }
  }, [previewSettings?.isPreview, previewSettings?.isInverted]);

  return (
    <div
      className={cn(
        styles.container,
        previewSettings?.isPreview ? styles.preview : '',
        className,
      )}
      ref={mainViewRef}
      id="report_render"
    >
      <canvas ref={canvasRef} className={styles.canvas} />
      {children}
    </div>
  );
};
