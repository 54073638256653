import { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import smileCat from '@/shared/assets/images/cats/smile.png';

import styles from './ReportPreviewPlaceholder.module.scss';

type ReportPreviewPlaceholderProps = {
  className?: string;
  testID?: string;
};

export const ReportPreviewPlaceholder: FC<ReportPreviewPlaceholderProps> = (
  props,
) => {
  const { className, testID } = props;
  const { formatMessage } = useIntl();

  return (
    <div data-testid={testID} className={cn(styles.container, className)}>
      <img
        src={smileCat}
        width={140}
        height={140}
        alt={formatMessage({
          id: 'imgAltText.smileCat',
          defaultMessage: 'Smile cat',
        })}
      />

      <p className={cn(styles.text, 'p2')}>
        <span>
          {formatMessage({
            id: 'reportCard.preview.generationReady',
            defaultMessage: 'Report is ready.',
          })}
        </span>

        <span>
          {formatMessage({
            id: 'reportCard.preview.generatingPreview',
            defaultMessage: 'Generating preview',
          })}
        </span>
      </p>
    </div>
  );
};
