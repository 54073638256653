import { createSelector } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';

import { Account } from '@/shared/api/protocol-ts/api/billing_new/dto_account_new_pb';
import {
  ProductState,
  ProductStateStatus,
} from '@/shared/api/protocol-ts/api/billing_new/dto_product_new_pb';
import { Order } from '@/shared/api/protocol-ts/api/billing_new/dto_order_new_pb';
import { ServiceType } from '@/shared/api/protocol-ts/api/billing_new/dto_services_new_pb';
import { StudyType } from '@/shared/api/protocol-ts/model/dto_study_pb';

import { RootState } from '@/app/model/store';

import { isProductActive } from '../lib/isProductActive';
import { getServicesFromSubscriptionList } from '../lib';
import { STUDY_TO_SERVICES_MAP, StudyToServiceMap } from '../config';

export const selectAccount = (state: RootState): Account =>
  state.billing.account;

export const selectInventory = (state: RootState): ProductState[] =>
  state.billing.inventory;

export const selectInvoices = (state: RootState): Order[] =>
  state.billing.invoices;

export const selectCurrentSubscription = createSelector(
  selectInventory,
  (inventory) =>
    inventory
      ?.filter(
        (inventoryItem) =>
          inventoryItem.Model?.Kind.case === 'Subscription' &&
          inventoryItem.Status === ProductStateStatus.ProductStateStatusActive,
      )
      ?.at(0),
);

export const selectSubscriptionLifeTime = (state: RootState): number =>
  state.billing.subscriptionLifeTime;

export const selectBillingZoneAndCountry = createSelector(
  selectAccount,
  (account): { billingZone: string; country: string } => ({
    billingZone: account?.BillingZones?.at(0) as string,
    country: account?.Country,
  }),
);

export const selectCurrentAddonsAndPackages = createSelector(
  selectInventory,
  (inventory) =>
    inventory.filter(
      (inventoryItem) =>
        inventoryItem.Status === ProductStateStatus.ProductStateStatusActive &&
        (inventoryItem?.Model?.Kind.case === 'Addon' ||
          inventoryItem?.Model?.Kind.case === 'Package'),
    ),
);

export const selectBilledInvoices = createSelector(selectInvoices, (invoices) =>
  invoices.filter((invoice) => invoice.Kind.case === 'Billed'),
);

export const selectActiveProducts = createSelector(
  selectInventory,
  (inventory) => {
    if (inventory.length === 0) return [] as ProductState[];

    return inventory.filter(isProductActive);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  },
);

export const selectAvailableProducts = (state: RootState) =>
  state.billing.availableProducts;

export const selectAvailableSubscriptions = createSelector(
  selectAvailableProducts,
  (availableProducts) =>
    availableProducts.filter(
      (product) =>
        product.Kind.case === 'Subscription' && !product.AvailableForAdminsOnly,
    ),
);
export const selectAvailableAddons = createSelector(
  selectAvailableProducts,
  (availableProducts) =>
    availableProducts.filter(
      (product) =>
        product.Kind.case === 'Addon' && !product.AvailableForAdminsOnly,
    ),
);

export const selectSelectedTopUpPackageID = (state: RootState) =>
  state.billing.selectedTopUpPackageID;

export const selectLoading = (state: RootState) => state.billing.loading;

export const selectActiveServicesList = createSelector(
  selectActiveProducts,
  (productList) => {
    const services = getServicesFromSubscriptionList(productList);

    const activeServicesList = services.reduce(
      (activeServices, service) => {
        service.Types.forEach((serviceType) => {
          const isEnabler = service.Kind.case === 'Enabler';

          const leftover =
            (service.Kind.case === 'Countable' &&
              Number(service.Kind.value.Leftover)) ||
            0;

          activeServices[serviceType] = isEnabler ? 1 : leftover;
        });

        return activeServices;
      },
      {} as Record<ServiceType, number>,
    );

    return activeServicesList;
  },
);

export const selectIsReportOrderAvailableByStudyType = (
  studyType: StudyType | undefined,
) =>
  createSelector(
    selectActiveServicesList,
    (services) =>
      !services[STUDY_TO_SERVICES_MAP[studyType as keyof StudyToServiceMap]],
  );

export const selectIsServiceActiveByType = (serviceType: ServiceType) =>
  createSelector(selectActiveServicesList, (services) =>
    Boolean(services[serviceType]),
  );
