import { Report } from '@/shared/api/protocol-ts/model/dto_report_pb';
import { Study, StudyType } from '@/shared/api/protocol-ts/model/dto_study_pb';

export const getLatestDate = (
  report: Report | undefined,
  studies: Study[],
): number | undefined => {
  const cbctStudy = studies.find(
    (study) => study.Type === StudyType.StudyType_CBCT,
  );

  // use study.Time (as metadata CBCT study date) if it exists, otherwise use study.Created.At
  if (cbctStudy) {
    const cbctStudyDate = cbctStudy.Time
      ? cbctStudy.Time.toDate().getTime()
      : undefined;
    const studyCreatedDate = cbctStudy.Created?.At
      ? cbctStudy.Created.At.toDate().getTime()
      : undefined;

    return cbctStudyDate ?? studyCreatedDate;
  }

  // use study.Created.At for all other studies types
  const studiesDate = studies.filter((study) => study.Created?.At);

  if (studiesDate.length > 0) {
    return Math.max(
      ...studiesDate.map(
        (study) =>
          study.Created?.At?.toDate().getTime() ?? new Date().getTime(),
      ),
      0,
    );
  }

  // TODO: [2|m] remove this to separated function
  const reportsDate = report && report.Created?.At;

  if (reportsDate) {
    return reportsDate.toDate().getTime();
  }

  return undefined;
};
