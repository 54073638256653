import { FC, useRef, useState } from 'react';

import {
  Report,
  ReportType,
} from '@/shared/api/protocol-ts/model/dto_report_pb';
import { Skeleton } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';

import {
  protocolLocalizationToBBox,
  ViewOptionsInterface,
} from '@/entities/reports';
import { conditionModel } from '@/entities/condition';

import { PanoImage } from '@/widgets/PanoImage';

import { IContext } from 'graphics';

import { PANO_DEFAULT_HEIGHT, PANO_TOOLS } from '../../config/constants';

type ImageProps = {
  viewOptions: ViewOptionsInterface;
  assetID: string;
  panoramaURL: string;
  panoramaSplitURL?: string;
};

type ReportImageProps = {
  report: Report;
  panoImage: ImageProps;
  getPanoImageContext: (context: IContext) => void;
};

export const ReportImage: FC<ReportImageProps> = (props) => {
  const { report, getPanoImageContext, panoImage } = props;

  const [imageLoaded, setImageLoaded] = useState(false);

  const panoContainer = useRef<HTMLDivElement>(null);

  const hoveredConditions = useAppSelector(
    conditionModel.selectors.selectHoveredConditions,
  );

  const bbox = hoveredConditions.flatMap((condition) =>
    condition?.Localizations.map(protocolLocalizationToBBox),
  );

  const onLoaded = () => {
    setImageLoaded(true);
  };

  const imageSkeleton = (
    <Skeleton
      width="100%"
      borderRadius="12px"
      height={`${PANO_DEFAULT_HEIGHT}px`}
    />
  );

  return !panoImage ? (
    imageSkeleton
  ) : (
    <div ref={panoContainer}>
      <PanoImage
        kind={
          report.Type === ReportType.ReportType_CBCT_GP ? 'dicom' : 'raster'
        }
        controls={PANO_TOOLS}
        src={panoImage.panoramaURL}
        splitUrl={panoImage.panoramaSplitURL}
        toolbarPosition="inside"
        handlePanoContext={(context) => {
          onLoaded();
          getPanoImageContext(context);
        }}
        assetID={panoImage.assetID}
        reportID={report.ID}
        viewOptions={panoImage.viewOptions}
        containerWidth={panoContainer?.current?.clientWidth ?? 0}
        fixedHeight={imageLoaded ? PANO_DEFAULT_HEIGHT : 0}
        localizations={bbox ?? []}
      />
    </div>
  );
};
