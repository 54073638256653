import { useState } from 'react';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { Tooth } from '@/shared/api/protocol-ts/model/dto_report_tooth_pb';
import { ApproveReportToothResp } from '@/shared/api/protocol-ts/api/core/svc_report_pb';

import { reportsModel, useCheckReportSignature } from '@/entities/reports';
import { toothModel } from '@/entities/tooth';
import { conditionModel } from '@/entities/condition';

export const useApproveTooth = (tooth: Tooth) => {
  const [isApproveToothLoading, setApproveToothLoading] = useState(false);

  const { checkReportSignature } = useCheckReportSignature();

  const yellowConditionsIDs = useAppSelector(
    conditionModel.selectors.selectYellowIDsByToothID(tooth?.ID),
  );

  const dispatch = useAppDispatch();

  const toggleApproveTooth = () => {
    checkReportSignature({
      onSignatureChecked: async () => {
        setApproveToothLoading(true);

        try {
          const currentTooth = !tooth?.IsApproved
            ? await dispatch(
                reportsModel.thunks.setReportToothApproved({
                  ToothID: tooth.ID,
                  ToothConditionIDs: yellowConditionsIDs,
                }),
              ).unwrap()
            : await dispatch(
                reportsModel.thunks.setReportToothDisapproved({
                  ToothID: tooth.ID,
                }),
              ).unwrap();

          if (currentTooth instanceof ApproveReportToothResp) {
            dispatch(
              conditionModel.actions.setNewestMany(currentTooth.Conditions),
            );
          }

          if (currentTooth?.Tooth) {
            dispatch(toothModel.actions.setNewestOne(currentTooth.Tooth));
          }
        } finally {
          setApproveToothLoading(false);
        }
      },
    });
  };

  return { toggleApproveTooth, isApproveToothLoading };
};
