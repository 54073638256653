import {
  conditionIsUncertain,
  shouldConditionItemBeShown,
} from '@/shared/embeddedLibs/conditionsAndMasks';
import { ConditionCode } from '@/shared/api/protocol-ts/model/dto_report_condition_codes_pb';
import { Decision } from '@/shared/api/protocol-ts/model/dto_report_common_pb';
import { Condition } from '@/shared/api/protocol-ts/model/dto_report_condition_pb';

import {
  CHILD_CONDITION_GROUP_BY_CODE,
  CONDITION_GROUP_BY_CODE,
  ONE_OF_CONDITION_GROUPS,
  getConditionCodeColor,
  getConditionModelScorePercentage,
} from '@/entities/condition';
import type { LogicalCondition, CompactCondition } from '@/entities/condition';

export const makeCompactConditions = (
  logicalConditions: LogicalCondition[],
  lowProbabilityMode?: boolean,
  hideProbabilities?: boolean,
): CompactCondition[] => {
  return logicalConditions.map((condition): CompactCondition => {
    const code = condition.Code as ConditionCode;
    const isEndo = code === ConditionCode.EndoTreated;
    const conditionColor = getConditionCodeColor(
      code,
      conditionIsUncertain(condition.Certainty),
      lowProbabilityMode,
    );
    const conditionGroup = CONDITION_GROUP_BY_CODE[code];

    const childConditionInterfaces = condition.Children.map(
      (childCondition): CompactCondition => {
        const code = childCondition.Code as ConditionCode;
        const childConditionGroup = CHILD_CONDITION_GROUP_BY_CODE[code];

        return {
          id: childCondition?.ID,
          ids: childCondition?.IDs,
          toothID:
            childCondition?.Attribution.case === 'Tooth'
              ? childCondition?.Attribution.value.ToothID
              : '',
          code: childCondition.Code,
          parentCode: childCondition.ParentCode,
          parentID: childCondition.ParentID,
          color: isEndo
            ? getConditionCodeColor(
                code,
                conditionIsUncertain(childCondition.Certainty),
                lowProbabilityMode,
              )
            : conditionColor,
          probability: childCondition?.Certainty?.ModelScore,
          probabilityText: hideProbabilities
            ? ''
            : getConditionModelScorePercentage(childCondition as Condition),
          isChecked: shouldConditionItemBeShown(lowProbabilityMode)(
            childCondition as Condition,
          ),
          isOneOf: false,
          hasLocalizations: !!childCondition?.Localizations?.length,
          modelPositive:
            childCondition?.Certainty?.ModelDecision ===
            Decision.PositiveDecision,
          childConditionInterfaces: [],
          group: childConditionGroup,
        };
      },
    );

    return {
      id: condition?.ID as string,
      ids: condition?.IDs as string[],
      toothID:
        condition?.Attribution.case === 'Tooth'
          ? condition?.Attribution.value.ToothID
          : '',
      code,
      parentID: '',
      parentCode: 0,
      color: conditionColor,
      probability: condition?.Certainty?.ModelScore,
      probabilityText: hideProbabilities
        ? ''
        : getConditionModelScorePercentage(condition as unknown as Condition),
      isChecked: shouldConditionItemBeShown(lowProbabilityMode)(
        condition as unknown as Condition,
      ),
      isOneOf: ONE_OF_CONDITION_GROUPS.includes(conditionGroup),
      hasLocalizations: !!condition?.Localizations?.length,
      modelPositive:
        condition?.Certainty?.ModelDecision === Decision.PositiveDecision,
      childConditionInterfaces,
      group: conditionGroup,
    };
  });
};
