import { AutosizeSvg } from "../AnnotationsLayer/AutosizeSvg"
import { useContext, useMemo } from "react"
import { vec2 } from "../vec2"
import { ObjectFitContaintContext } from "../ObjectFitContain"
import { cssToLogical } from "../AnnotationsLayer/csstoLogical"

export type Polygon = vec2[]

export type PolygonMask = {
    polygon: Polygon
    color?: string
    stroke?: string
    opacity?: number
}

export type RCPolygonMaskProps = {
    masks: PolygonMask[]
}

export const RCPolygonMask = (props: RCPolygonMaskProps) => {

    const { masks } = props

    return (
        <AutosizeSvg style={{ display: 'block', pointerEvents: 'none' }}>
            {masks.map((mask, i) => (
                <Polygon key={i} points={mask.polygon} fill={mask.color} opacity={mask.opacity} stroke={mask.stroke} />
            ))}
        </AutosizeSvg>
    )
}

type PolygonProps = {
    points: vec2[]
    fill?: string
    stroke?: string
    opacity?: number
}

const Polygon = (props: PolygonProps) => {
    const { points, fill, opacity, stroke } = props

    const objectFit = useContext(ObjectFitContaintContext);

    const stringPoints = useMemo(() => {
        return points.map(p => `${p.x},${p.y}`).join(' ')
    }, [points])

    return (
        <>
            <polygon points={stringPoints} fill={fill} opacity={opacity || 0.8} />
            <polygon points={stringPoints} fill={"none"} opacity={1} stroke={stroke} strokeWidth={cssToLogical(2, objectFit)} />
        </>
    )
}