import { ConditionCode } from '@/shared/api/protocol-ts/model/dto_report_condition_codes_pb';

import { CompactCondition, ConditionInterface } from '../config/types';

import { isConditionItemInGroups } from './isConditionInGroups';
import { collectConditionCodesFromGroups } from './collectConditionCodesFromGroups';

/**
 * @deprecated
 */
export const filterConditionItemsByGroups =
  (groups: Record<string, ConditionCode[]>) =>
  (conditionItems: ConditionInterface[]): ConditionInterface[] =>
    conditionItems.filter((item) => isConditionItemInGroups(groups)(item));

export const isCompactConditionInGroups =
  (groups: Record<string, ConditionCode[]>) =>
  (conditionItem: CompactCondition) =>
    collectConditionCodesFromGroups(groups).includes(conditionItem.code);

export const filterCompactConditionsByGroups =
  (groups: Record<string, ConditionCode[]>) =>
  (conditionItems: CompactCondition[]): CompactCondition[] =>
    conditionItems.filter((item) => isCompactConditionInGroups(groups)(item));
