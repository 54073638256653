import { FormattedMessage, useIntl } from 'react-intl';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';

import { getInitials, getName } from '@/shared/lib';
import { PatientsListColumnName } from '@/shared/config';
import {
  Invitation,
  Invitation_InvitationStatus,
} from '@/shared/api/protocol-ts/model/dto_access_pb';
import { Avatar } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';

import {
  getAvatarSrcByUserID,
  getDisplayAssetSrcByPatientID,
} from '@/entities/assets';
import { userModel } from '@/entities/user';

import styles from '../ui/SharedPatientList/SharedPatientList.module.scss';
import { PatientColumnHeader } from '../ui/PatientColumnHeader/PatientColumnHeader';
import { ColumnValues } from '../config/i18n';
import { RevokeSharedPatientColumn } from '../ui/RevokeSharedPatientColumn/RevokeSharedPatientColumn';

export const useSharedPatientColumnsScheme = () => {
  const { formatMessage, formatDate } = useIntl();
  const userLocale = useAppSelector(userModel.selectors.selectUserLocale);

  const columnHelper = createColumnHelper<Invitation>();

  const sharedByMeColumnsScheme = useMemo(
    () => [
      columnHelper.accessor(
        (row) => ({
          patientFullName: getName(
            row.TargetDetails?.PatientFirstName,
            row.TargetDetails?.PatientLastName,
            { userLocale },
          ),
          patientId:
            row.Target?.Target.case === 'Patient'
              ? row.Target.Target.value.PatientID
              : undefined,
        }),
        {
          id: PatientsListColumnName.PatientName,
          enableSorting: true,
          header: formatMessage(
            ColumnValues[PatientsListColumnName.PatientName],
          ),
          cell: (cell) => {
            const { patientFullName, patientId } = cell.getValue();
            const displayAssetSrc = getDisplayAssetSrcByPatientID(
              patientId ?? '',
              'thumbnail',
            );

            return (
              <PatientColumnHeader
                patientName={patientFullName}
                displayAssetSrc={displayAssetSrc}
              />
            );
          },
        },
      ),
      columnHelper.accessor(
        (row) => ({
          sharedByMeItemID: row.ID,
          senderName: getName(
            row.TargetDetails?.SenderFirstName,
            row.TargetDetails?.SenderLastName,
            { userLocale },
          ),
          createdBy: row.Created?.By,
        }),
        {
          id: PatientsListColumnName.SharedBy,
          enableSorting: true,
          enableHiding: true,
          header: formatMessage(ColumnValues[PatientsListColumnName.SharedBy]),
          cell: (cell) => {
            const { sharedByMeItemID, senderName, createdBy } = cell.getValue();
            const senderInitials = getInitials(senderName);

            const avatarSrc = getAvatarSrcByUserID(
              createdBy ?? '',
              'thumbnail',
            );

            return (
              <div className={styles.infoInCell}>
                <Avatar
                  src={avatarSrc}
                  alt={senderName}
                  initials={senderInitials}
                  key={sharedByMeItemID}
                />
                <div className={styles.fullName}>{senderName}</div>
              </div>
            );
          },
        },
      ),
      columnHelper.accessor((row) => row.Recipient?.Email, {
        id: PatientsListColumnName.SharedWith,
        enableSorting: true,
        header: formatMessage(ColumnValues[PatientsListColumnName.SharedWith]),
        cell: (cell) => {
          const email = cell.getValue();

          return (
            <div className={styles.infoInCell}>
              <span className={styles.forWhiteSpace}>{email}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor((row) => row.Created?.At, {
        id: PatientsListColumnName.SharingDate,
        enableSorting: true,
        maxSize: 100,
        header: formatMessage(ColumnValues[PatientsListColumnName.SharingDate]),
        cell: (cell) => {
          const date = cell.getValue();

          if (!date) {
            return null;
          }

          return (
            <div className={styles.infoInCell}>
              <span className={styles.forWhiteSpace}>
                {formatDate(new Date(date.toDate()), {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                })}
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor((row) => row.Status, {
        id: PatientsListColumnName.Status,
        enableSorting: true,
        maxSize: 75,
        header: formatMessage(ColumnValues[PatientsListColumnName.Status]),
        cell: (cell) => {
          const status = cell.getValue();
          const isInviteAccepted =
            status === Invitation_InvitationStatus.StatusAccepted;

          const statusMessage = isInviteAccepted ? (
            <FormattedMessage
              id="sharedPatient.accepted"
              defaultMessage="accepted"
            />
          ) : (
            <FormattedMessage
              id="sharedPatient.notAccepted"
              defaultMessage="not accepted"
            />
          );

          return (
            <div className={styles.infoInCell}>
              <span className={styles.forWhiteSpace}>{statusMessage}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor(
        (row) => ({
          invitationID: row.ID,
          patientID:
            row.Target?.Target.case === 'Patient'
              ? row.Target.Target.value.PatientID
              : undefined,
          status: row.Status,
          sharedPatientDoctorID: row.Recipient?.UserID,
        }),
        {
          id: PatientsListColumnName.Delete,
          enableSorting: false,
          maxSize: 50,
          header: '',
          cell: (cell) => {
            const { patientID, status, invitationID, sharedPatientDoctorID } =
              cell.getValue();

            const isInviteAccepted =
              status === Invitation_InvitationStatus.StatusAccepted;

            return (
              <RevokeSharedPatientColumn
                patientID={patientID ?? ''}
                sharedPatientDoctorID={sharedPatientDoctorID ?? ''}
                invitationID={invitationID}
                isInviteAccepted={isInviteAccepted}
              />
            );
          },
        },
      ),
    ],
    [columnHelper],
  );

  const sharedWithMeColumnsScheme = useMemo(
    () => [
      columnHelper.accessor(
        (row) => ({
          patientFullName: getName(
            row.TargetDetails?.PatientFirstName,
            row.TargetDetails?.PatientLastName,
            { userLocale },
          ),
          patientId:
            row.Target?.Target.case === 'Patient'
              ? row.Target.Target.value.PatientID
              : undefined,
          status: row.Status,
        }),
        {
          id: PatientsListColumnName.PatientName,
          enableSorting: true,
          header: formatMessage(
            ColumnValues[PatientsListColumnName.PatientName],
          ),
          cell: (cell) => {
            const { patientId, patientFullName, status } = cell.getValue();
            const isInviteAccepted =
              status === Invitation_InvitationStatus.StatusAccepted;
            const displayAssetSrc = getDisplayAssetSrcByPatientID(
              patientId ?? '',
              'thumbnail',
            );

            return (
              <PatientColumnHeader
                patientName={patientFullName}
                displayAssetSrc={displayAssetSrc}
                isSharedPatientAccepted={isInviteAccepted}
              />
            );
          },
        },
      ),
      columnHelper.accessor((row) => row.TargetDetails?.OrganizationName, {
        id: PatientsListColumnName.ClinicName,
        enableSorting: true,
        header: formatMessage(ColumnValues[PatientsListColumnName.ClinicName]),
        cell: (cell) => {
          const clinicName = cell.getValue();

          return (
            <div className={styles.infoInCell}>
              <div className={styles.fullName}>{clinicName}</div>
            </div>
          );
        },
      }),
      columnHelper.accessor(
        (row) => ({
          sharedWithMeItemID: row.ID,
          senderName: getName(
            row.TargetDetails?.SenderFirstName,
            row.TargetDetails?.SenderLastName,
            { userLocale },
          ),
          createdBy: row?.Created?.By,
        }),
        {
          id: PatientsListColumnName.SharedBy,
          enableSorting: true,
          header: formatMessage(ColumnValues[PatientsListColumnName.SharedBy]),
          cell: (cell) => {
            const { sharedWithMeItemID, senderName, createdBy } =
              cell.getValue();
            const senderInitials = getInitials(senderName);
            const avatarSrc = getAvatarSrcByUserID(
              createdBy ?? '',
              'thumbnail',
            );

            return (
              <div className={styles.infoInCell}>
                <Avatar
                  src={avatarSrc}
                  alt={senderName}
                  initials={senderInitials}
                  key={sharedWithMeItemID}
                />
                <div className={styles.fullName}>{senderName}</div>
              </div>
            );
          },
        },
      ),
      columnHelper.accessor((row) => row.TargetDetails?.SenderEmail, {
        id: PatientsListColumnName.SenderEmail,
        enableSorting: true,
        header: formatMessage(ColumnValues[PatientsListColumnName.SenderEmail]),
        cell: (cell) => {
          const senderEmail = cell.getValue();

          return (
            <div className={styles.infoInCell}>
              <div className={styles.fullName}>{senderEmail}</div>
            </div>
          );
        },
      }),
      columnHelper.accessor((row) => row?.Created?.At, {
        id: PatientsListColumnName.SharingDate,
        maxSize: 100,
        enableSorting: true,
        header: formatMessage(ColumnValues[PatientsListColumnName.SharingDate]),
        cell: (cell) => {
          const createdAt = cell.getValue();

          if (!createdAt) {
            return null;
          }

          return (
            <div className={styles.infoInCell}>
              <span className={styles.forWhiteSpace}>
                {formatDate(new Date(createdAt.toDate()), {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                })}
              </span>
            </div>
          );
        },
      }),
    ],
    [columnHelper],
  );

  return {
    sharedByMeColumnsScheme,
    sharedWithMeColumnsScheme,
  };
};
