import { OrganizationUserRole } from '@/shared/api/protocol-ts/model/dto_organization_pb';
import { getName } from '@/shared/lib';
import { useAppSelector } from '@/shared/hooks';

import { getAvatarSrcByUserID } from '@/entities/assets';
import { userModel } from '@/entities/user';

export const useDoctorDetails = (userRole: OrganizationUserRole) => {
  const userLocale = useAppSelector(userModel.selectors.selectUserLocale);
  const doctorFirstName = userRole.PersonalData?.FirstName;
  const doctorLastName = userRole.PersonalData?.LastName;

  const doctorFullName = getName(doctorFirstName, doctorLastName, {
    userLocale,
  });
  const doctorInitials = getName(doctorFirstName, doctorLastName, {
    userLocale,
    shortFirstName: true,
    shortLastName: true,
  });
  const doctorAvatarSrc = getAvatarSrcByUserID(userRole.UserID, 'thumbnail');

  return { doctorFullName, doctorInitials, doctorAvatarSrc };
};
