// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_report_request.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { Report_CBCT_IOS_Segment } from "./dto_report_common_pb.js";
import { Report_CBCT_IOS_Superimposition_Orientation } from "./dto_report_type_cbct_ios_superimposition_pb.js";

/**
 * @generated from message com.diagnocat.model.ReportRequest
 */
export const ReportRequest = proto3.makeMessageType(
  "com.diagnocat.model.ReportRequest",
  () => [
    { no: 1, name: "Input_CBCT_GP", kind: "message", T: ReportRequest_InputType_CBCT_GP, oneof: "Input" },
    { no: 2, name: "Input_CBCT_Endo", kind: "message", T: ReportRequest_InputType_CBCT_Endo, oneof: "Input" },
    { no: 3, name: "Input_CBCT_Implant", kind: "message", T: ReportRequest_InputType_CBCT_Implant, oneof: "Input" },
    { no: 4, name: "Input_CBCT_Molar", kind: "message", T: ReportRequest_InputType_CBCT_Molar, oneof: "Input" },
    { no: 5, name: "Input_CBCT_Ortho", kind: "message", T: ReportRequest_InputType_CBCT_Ortho, oneof: "Input" },
    { no: 6, name: "Input_Pano_GP", kind: "message", T: ReportRequest_InputType_Pano_GP, oneof: "Input" },
    { no: 7, name: "Input_IOXRay_GP", kind: "message", T: ReportRequest_InputType_IOXRay_GP, oneof: "Input" },
    { no: 8, name: "Input_DentalPhoto_GP", kind: "message", T: ReportRequest_InputType_DentalPhoto_GP, oneof: "Input" },
    { no: 9, name: "Input_CBCT_Segmentation", kind: "message", T: ReportRequest_InputType_CBCT_Segmentation, oneof: "Input" },
    { no: 10, name: "Input_CBCT_IOS_Superimposition", kind: "message", T: ReportRequest_InputType_CBCT_IOS_Superimposition, oneof: "Input" },
    { no: 11, name: "Input_IOS_Segmentation", kind: "message", T: ReportRequest_InputType_IOS_Segmentation, oneof: "Input" },
    { no: 13, name: "Input_StudioImplant", kind: "message", T: ReportRequest_InputType_StudioImplant, oneof: "Input" },
    { no: 15, name: "Input_StudioOrtho", kind: "message", T: ReportRequest_InputType_StudioOrtho, oneof: "Input" },
  ],
);

/**
 * @generated from message com.diagnocat.model.ReportRequest.InputType_CBCT_GP
 */
export const ReportRequest_InputType_CBCT_GP = proto3.makeMessageType(
  "com.diagnocat.model.ReportRequest.InputType_CBCT_GP",
  () => [
    { no: 1, name: "CBCTStudyID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "ReportRequest_InputType_CBCT_GP"},
);

/**
 * @generated from message com.diagnocat.model.ReportRequest.InputType_CBCT_Endo
 */
export const ReportRequest_InputType_CBCT_Endo = proto3.makeMessageType(
  "com.diagnocat.model.ReportRequest.InputType_CBCT_Endo",
  () => [
    { no: 1, name: "CBCTStudyID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ToothNumberISO", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "StepMM", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "ThicknessMM", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
  {localName: "ReportRequest_InputType_CBCT_Endo"},
);

/**
 * @generated from message com.diagnocat.model.ReportRequest.InputType_CBCT_Implant
 */
export const ReportRequest_InputType_CBCT_Implant = proto3.makeMessageType(
  "com.diagnocat.model.ReportRequest.InputType_CBCT_Implant",
  () => [
    { no: 1, name: "CBCTStudyID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "StartToothNumberISO", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "EndToothNumberISO", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "StepMM", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "ThicknessMM", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
  {localName: "ReportRequest_InputType_CBCT_Implant"},
);

/**
 * @generated from message com.diagnocat.model.ReportRequest.InputType_CBCT_Molar
 */
export const ReportRequest_InputType_CBCT_Molar = proto3.makeMessageType(
  "com.diagnocat.model.ReportRequest.InputType_CBCT_Molar",
  () => [
    { no: 1, name: "CBCTStudyID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ToothNumberISO", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
  {localName: "ReportRequest_InputType_CBCT_Molar"},
);

/**
 * @generated from message com.diagnocat.model.ReportRequest.InputType_CBCT_Ortho
 */
export const ReportRequest_InputType_CBCT_Ortho = proto3.makeMessageType(
  "com.diagnocat.model.ReportRequest.InputType_CBCT_Ortho",
  () => [
    { no: 1, name: "CBCTStudyID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "EnableMeasures", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
  {localName: "ReportRequest_InputType_CBCT_Ortho"},
);

/**
 * @generated from message com.diagnocat.model.ReportRequest.InputType_Pano_GP
 */
export const ReportRequest_InputType_Pano_GP = proto3.makeMessageType(
  "com.diagnocat.model.ReportRequest.InputType_Pano_GP",
  () => [
    { no: 1, name: "PanoramicXRayStudyID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "ReportRequest_InputType_Pano_GP"},
);

/**
 * @generated from message com.diagnocat.model.ReportRequest.InputType_IOXRay_GP
 */
export const ReportRequest_InputType_IOXRay_GP = proto3.makeMessageType(
  "com.diagnocat.model.ReportRequest.InputType_IOXRay_GP",
  () => [
    { no: 1, name: "IntraoralXRayStudyID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "ReportRequest_InputType_IOXRay_GP"},
);

/**
 * @generated from message com.diagnocat.model.ReportRequest.InputType_DentalPhoto_GP
 */
export const ReportRequest_InputType_DentalPhoto_GP = proto3.makeMessageType(
  "com.diagnocat.model.ReportRequest.InputType_DentalPhoto_GP",
  () => [
    { no: 1, name: "DentalPhotoStudyID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "ReportRequest_InputType_DentalPhoto_GP"},
);

/**
 * @generated from message com.diagnocat.model.ReportRequest.InputType_CBCT_Segmentation
 */
export const ReportRequest_InputType_CBCT_Segmentation = proto3.makeMessageType(
  "com.diagnocat.model.ReportRequest.InputType_CBCT_Segmentation",
  () => [
    { no: 1, name: "CBCTStudyID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Segments", kind: "message", T: Report_CBCT_IOS_Segment, repeated: true },
  ],
  {localName: "ReportRequest_InputType_CBCT_Segmentation"},
);

/**
 * @generated from message com.diagnocat.model.ReportRequest.InputType_IOS_Segmentation
 */
export const ReportRequest_InputType_IOS_Segmentation = proto3.makeMessageType(
  "com.diagnocat.model.ReportRequest.InputType_IOS_Segmentation",
  () => [
    { no: 1, name: "IOSMeshesStudyID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "ReportRequest_InputType_IOS_Segmentation"},
);

/**
 * @generated from message com.diagnocat.model.ReportRequest.InputType_CBCT_IOS_Superimposition
 */
export const ReportRequest_InputType_CBCT_IOS_Superimposition = proto3.makeMessageType(
  "com.diagnocat.model.ReportRequest.InputType_CBCT_IOS_Superimposition",
  () => [
    { no: 1, name: "CBCTStudyID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "STLStudyID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "DentalPhotoStudyID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "Orientation", kind: "enum", T: proto3.getEnumType(Report_CBCT_IOS_Superimposition_Orientation) },
    { no: 5, name: "Segments", kind: "message", T: Report_CBCT_IOS_Segment, repeated: true },
  ],
  {localName: "ReportRequest_InputType_CBCT_IOS_Superimposition"},
);

/**
 * @generated from message com.diagnocat.model.ReportRequest.InputType_StudioImplant
 */
export const ReportRequest_InputType_StudioImplant = proto3.makeMessageType(
  "com.diagnocat.model.ReportRequest.InputType_StudioImplant",
  () => [
    { no: 1, name: "CBCTStudyID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "IOSMeshesStudyID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "DentalPhotoStudyID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "ReportRequest_InputType_StudioImplant"},
);

/**
 * @generated from message com.diagnocat.model.ReportRequest.InputType_StudioOrtho
 */
export const ReportRequest_InputType_StudioOrtho = proto3.makeMessageType(
  "com.diagnocat.model.ReportRequest.InputType_StudioOrtho",
  () => [
    { no: 1, name: "CBCTStudyID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "IOSMeshesStudyID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "DentalPhotoStudyID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "Language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "ReportRequest_InputType_StudioOrtho"},
);

