import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import cn from 'classnames';

import { Button, Modal } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { ToothNumeration } from '@/shared/api/protocol-ts/model/dto_report_common_pb';
import { checkDuplicatesInLocalizations } from '@/shared/lib';

import { ModalID, modalModel } from '@/entities/modal';
import { reportsModel, useCheckReportSignature } from '@/entities/reports';
import {
  LocalizationWithISO,
  TeethNumbersPopup,
  toothModel,
} from '@/entities/tooth';
import { organizationModel } from '@/entities/organization';
import {
  IOXRayImagesInterfaceModel,
  IOXrayImageInterface,
  groupIOXRayImagesByPartition,
} from '@/entities/IOXRayImagesMatrix';
import { assetsModel, getImageSrc } from '@/entities/assets';
import { conditionModel } from '@/entities/condition';

import { ImageWithTeethNumbers } from '@/features/ImageWithTeethNumbers';

import styles from './PanowingChangeTeethNumberModal.module.scss';

type PanowingChangeTeethNumberModalProps = {
  className?: string;
};

// TODO: [m|8] write universal HOC component for modals to prevent hidden modals rendering
export const PanowingChangeTeethNumberModal = () => {
  const { visible } = useAppSelector(
    modalModel.selectors.selectTeethNumberingModal,
  );

  return visible ? <PanowingChangeTeethNumberModalComponent /> : null;
};

export const PanowingChangeTeethNumberModalComponent: FC<
  PanowingChangeTeethNumberModalProps
> = (props) => {
  const { className } = props;

  const { reportID } = useParams();

  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const { visible } = useAppSelector(
    modalModel.selectors.selectTeethNumberingModal,
  );
  const report = useAppSelector(reportsModel.selectors.selectCurrentReport);
  const reportTeeth = useAppSelector(
    toothModel.selectors.selectByReportID(reportID as string),
  );
  const deletedTooth404 = useAppSelector(
    toothModel.selectors.select404ByReportID(reportID as string),
  );
  const dentalNotationFormat = useAppSelector(
    organizationModel.selectors.selectDentalNotationFormat,
  );

  const IOXRayImagesInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectIOXRayImagesInterfaceByReportID(
      report?.ID as string,
    ),
  ) as IOXrayImageInterface[];

  const panoStudyAssetID =
    report?.Specific.case === 'DataPanoBitewings'
      ? report?.Specific.value?.PanoStudyAssetID
      : '';
  const panoAsset = useAppSelector(
    assetsModel.selectors.selectAssetByID(panoStudyAssetID),
  );

  const [selectedLocalization, setSelectedToothLocalization] = useState<
    LocalizationWithISO | undefined
  >(undefined);
  const [selectedAssetISONumbers, setSelectedAssetISONumbers] = useState<
    number[] | undefined
  >(undefined);
  const [isToothRemoving, setIsToothRemoving] = useState(false);
  const [animatedToothNumber, setAnimatedToothNumber] = useState<number>(0);
  const [panoScaledSize, setPanoScaledSize] = useState({
    width: 0,
    height: 0,
    scale: 0,
  });
  const [bitewingsScale, setBitewingsScale] = useState(1);

  const panoContainerRef = useRef<HTMLDivElement>(null);
  const bitewingsContainerRef = useRef<HTMLDivElement>(null);

  const { checkReportSignature } = useCheckReportSignature();

  const allReportTeeth = deletedTooth404
    ? [...reportTeeth, deletedTooth404]
    : reportTeeth;

  const localizationItems = allReportTeeth.flatMap((tooth) =>
    tooth.Detections.map((detection) => {
      const localization = detection.Localizations;

      return {
        isRemoved: detection?.IsRemoved,
        ISONumber: tooth.Numeration?.ISO,
        SupernumeraryIndex: tooth.Numeration?.SupernumeraryIndex,
        ...localization,
        ID: detection.ID,
      };
    }),
  ) as LocalizationWithISO[];

  const panoLocalizations = localizationItems.filter(
    (localizationItem) => localizationItem.TargetAssetID === panoAsset?.ID,
  ) as LocalizationWithISO[];

  const title = formatMessage({
    id: 'report.EditTeethNumbers',
    defaultMessage: 'Edit teeth numbers',
  });

  const groupedIOXRayImages = groupIOXRayImagesByPartition(
    IOXRayImagesInterface,
  );

  const bitewingsImagesInterface = [
    ...groupedIOXRayImages['MiddleLeft'],
    ...groupedIOXRayImages['MiddleRight'],
  ];

  const assetLocalizationsByBitewingImage = bitewingsImagesInterface.map(
    (imageItem) =>
      localizationItems.filter(
        (localizationItem) =>
          localizationItem.TargetAssetID === imageItem.asset.ID,
      ) as LocalizationWithISO[],
  );

  const disabledByDuplicates =
    checkDuplicatesInLocalizations(panoLocalizations);

  const disabledByDuplicatesByBitewing = assetLocalizationsByBitewingImage
    .map(checkDuplicatesInLocalizations)
    .some(Boolean);

  const handleClose = useCallback(() => {
    dispatch(modalModel.actions.closeModal(ModalID.TeethNumberingModal));
  }, [dispatch]);

  const handleToothClick = (localization: LocalizationWithISO | undefined) => {
    setSelectedToothLocalization(localization);
  };

  // TODO?: mb move to separated hook
  const handleChangeToothNumber = (
    toothLocalization: LocalizationWithISO,
    newToothISONumber: number,
  ) => {
    checkReportSignature({
      onSignatureChecked: async () => {
        // TODO: [2/m] add SupernumeraryIndex in ToothNumeration, otherwise it will be equal to 0
        try {
          setAnimatedToothNumber(newToothISONumber);

          const { Teeth, Conditions, Report } = await dispatch(
            reportsModel.thunks.setToothDetectionsNumerationAndRevive({
              ToothDetectionID: toothLocalization.ID,
              Numeration: {
                ISO: newToothISONumber,
                SupernumeraryIndex: 0,
              } as ToothNumeration,
            }),
          ).unwrap();

          dispatch(toothModel.actions.addMany(Teeth));

          dispatch(conditionModel.actions.setNewestMany(Conditions));

          if (Report) {
            dispatch(reportsModel.actions.setNewestOne(Report));
          }
          setAnimatedToothNumber(0);
        } finally {
          setSelectedToothLocalization(undefined);
        }
      },
    });
  };

  const handleRemoveTooth = (toothLocalization: LocalizationWithISO) => {
    checkReportSignature({
      onSignatureChecked: async () => {
        setIsToothRemoving(true);
        try {
          const { Tooth, Report } = await dispatch(
            reportsModel.thunks.markToothDetectionAsDeleted({
              ToothDetectionID: toothLocalization.ID,
            }),
          ).unwrap();

          if (Tooth) {
            dispatch(toothModel.actions.setNewestOne(Tooth));
          }

          if (Report) {
            dispatch(reportsModel.actions.setNewestOne(Report));
          }
        } finally {
          setSelectedToothLocalization(undefined);
          setIsToothRemoving(false);
        }
      },
    });
  };

  const toothClickHandle = (
    viewedISONumbers: number[],
    localization: LocalizationWithISO,
  ) => {
    setSelectedAssetISONumbers(viewedISONumbers);
    handleToothClick(localization);
  };

  // TODO: move observer to hook
  useEffect(() => {
    const panoResizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { height } = entry.contentRect;

        const panoStudyAsset =
          panoAsset?.Kind.case === 'Study' ? panoAsset.Kind.value : undefined;
        const panoramicXRay =
          panoStudyAsset?.Content.case === 'PanoramicXRay'
            ? panoStudyAsset.Content.value
            : undefined;
        const panoramicXRayImage =
          panoramicXRay?.PanoramaImage?.Metadata.case === 'Image'
            ? panoramicXRay.PanoramaImage.Metadata.value
            : undefined;

        const PANO_CONTAINER_HEIGHT_STYLE = 0.75; // use for adopt scale to container flex: 0 0 70%

        const scalePanoByOriginalHeight =
          (height / Number(panoramicXRayImage?.Height ?? 1)) *
          PANO_CONTAINER_HEIGHT_STYLE;

        setPanoScaledSize({
          width:
            Number(panoramicXRayImage?.Width ?? 1) * scalePanoByOriginalHeight,
          height,
          scale: scalePanoByOriginalHeight,
        });
      }
    });

    if (panoContainerRef.current) {
      panoResizeObserver.observe(panoContainerRef.current);
    }

    return () => {
      panoResizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const bitewingsResizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { height } = entry.contentRect;

        const bitewingsMaxPreviewHeight = bitewingsImagesInterface.reduce(
          (accum, bitewingItem) => {
            const bitewingOriginalHeight = bitewingItem.originalSize.height;
            if (bitewingOriginalHeight > accum) {
              return bitewingOriginalHeight;
            } else {
              return accum;
            }
          },
          0,
        );

        const scaleBitewingsByOriginalHeight =
          height / bitewingsMaxPreviewHeight;

        setBitewingsScale(scaleBitewingsByOriginalHeight);
      }
    });

    if (bitewingsContainerRef.current) {
      bitewingsResizeObserver.observe(bitewingsContainerRef.current);
    }

    return () => {
      bitewingsResizeObserver.disconnect();
    };
  }, []);

  return (
    <Modal
      title={title}
      isOpen={visible}
      onCancel={handleClose}
      className={styles.modal}
      bodyClassName={styles.modalBody}
      containerClassName={styles.modalContainer}
      hideFooter
      borderless
      shouldRenderCloseIconButton={false}
      shouldCloseOnOverlayClick={
        !disabledByDuplicates || !disabledByDuplicatesByBitewing
      }
      shouldRenderCancelButton={false}
    >
      <div className={cn(styles.container, className)}>
        <div className={styles.matrixContainer} ref={panoContainerRef}>
          <ImageWithTeethNumbers
            className={styles.panoWrap}
            localizations={panoLocalizations}
            src={getImageSrc(panoAsset?.ID as string, 'original')}
            onToothClick={toothClickHandle}
            dentalNotation={dentalNotationFormat}
            scale={panoScaledSize.scale}
            style={{
              width: panoScaledSize.width,
              height: panoScaledSize.height,
            }}
          />

          <div className={styles.bitewingsWrap} ref={bitewingsContainerRef}>
            {bitewingsImagesInterface.map((imageItem) => {
              const assetLocalizations = localizationItems.filter(
                (localizationItem) =>
                  localizationItem.TargetAssetID === imageItem.asset.ID,
              ) as LocalizationWithISO[];

              return (
                <ImageWithTeethNumbers
                  style={{
                    width: imageItem.originalSize.width * bitewingsScale,
                    height: imageItem.originalSize.height * bitewingsScale,
                  }}
                  localizations={assetLocalizations}
                  src={getImageSrc(imageItem.asset.ID, 'original')}
                  dentalNotation={dentalNotationFormat}
                  onToothClick={toothClickHandle}
                  scale={bitewingsScale}
                />
              );
            })}
          </div>
        </div>

        <TeethNumbersPopup
          isOpen={!!selectedLocalization}
          onChangeToothNumber={handleChangeToothNumber}
          onChangeSelectedLocalization={handleToothClick}
          title={title}
          selectedToothLocalization={selectedLocalization}
          selectedAssetISONumbers={selectedAssetISONumbers}
          isToothRemoving={isToothRemoving}
          dentalNotationFormat={dentalNotationFormat}
          onRemoveTooth={handleRemoveTooth}
          animatedToothNumber={animatedToothNumber}
        />
      </div>

      <div className={styles.footer}>
        <Button
          size="medium"
          onClick={handleClose}
          className={styles.approveButton}
        >
          <FormattedMessage id="patient.confirm" defaultMessage="Confirm" />
        </Button>
      </div>
    </Modal>
  );
};
