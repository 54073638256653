// fix todos about types in this file
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
  ColumnSort,
} from '@tanstack/react-table';
import { useVirtual } from '@tanstack/react-virtual';
import { useIntl } from 'react-intl';

import { useAppSelector } from '@/shared/hooks';
import sadCatImg from '@/shared/assets/images/sad-cat.png';
import {
  NEW_LINE,
  SliceName,
  SortByFunctionType,
  PatientsListColumnName,
} from '@/shared/config';
import { Invitation_InvitationStatus } from '@/shared/api/protocol-ts/model/dto_access_pb';

import { DoctorsDropdownFilter } from '@/features/doctorsDropdownFilter';

import { RootState } from '@/app/model/store';

import { Icon } from '../Icon/Icon';
import { Result } from '../Result/Result';
import { Skeleton } from '../Skeleton/Skeleton';

import styles from './TableWithInfiniteScroll.module.scss';

type TableWithInfiniteScrollProps<DataType> = {
  itemCounter: number;
  columnsScheme: ColumnDef<DataType>[];
  listData: DataType[];
  reFetchList: (id: string) => void;
  loadingState: keyof typeof SliceName;
  testID?: string;
  className?: string;
  showSharedByColumn?: boolean;
  showNothingFound?: boolean;
  showSkeleton?: boolean;
  showEmpty?: boolean;
  serverSideSorting?: boolean;
  sortBy?: SortByFunctionType;
  title?: string | React.ReactNode;
  emptyComponent?: React.ReactNode;
  clickOnRow?: (id: string) => void;
  conditionalAdditionalClickOnRow?: (id: string) => void;
  onDoctorFilterChange?: (doctorsIDs: string[]) => void;
};

// TODO: [4/m]: there is no loadind state an all slices
const getLoadingStateSelector =
  (loadingState: keyof typeof SliceName) => (state: RootState) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state[loadingState]?.loading;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TableWithInfiniteScroll = <DataType extends Record<string, any>>(
  props: TableWithInfiniteScrollProps<DataType>,
) => {
  const {
    className,
    testID,
    showSharedByColumn = true,
    title,
    reFetchList,
    listData,
    itemCounter,
    clickOnRow,
    conditionalAdditionalClickOnRow,
    columnsScheme,
    sortBy,
    showSkeleton,
    showNothingFound,
    showEmpty,
    emptyComponent,
    loadingState,
    serverSideSorting,
    onDoctorFilterChange,
  } = props;

  // we need a reference to the scrolling element for logic down below
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const prevListDataLength = useRef(listData.length);

  const selectLoadingState = useMemo(
    () => getLoadingStateSelector(loadingState),
    [loadingState],
  );

  const listLoading = useAppSelector(selectLoadingState);

  const [sorting, setSorting] = useState<SortingState>([]);

  const totalDBRowCount = itemCounter ?? 0;

  const totalFetched = listData.length;

  const lastItemInListID = listData[listData.length - 1]?.ID;

  const { formatMessage } = useIntl();

  // called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        const shouldFetchMore =
          scrollTop &&
          scrollHeight - scrollTop - clientHeight < 300 &&
          listLoading === 'succeeded' &&
          totalFetched < totalDBRowCount;
        // && prevListDataLength.current !== listData.length;

        // once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
        if (shouldFetchMore) {
          prevListDataLength.current = listData.length;
          reFetchList(lastItemInListID);
        }
      }
    },
    [reFetchList, listLoading, totalFetched, totalDBRowCount, lastItemInListID],
  );

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  const handleSortChange = (sort: () => ColumnSort[]) => {
    if (sort().length) {
      const { id, desc } = sort()[0];

      if (sortBy) {
        sortBy(id as PatientsListColumnName, desc);
      }
    }

    setSorting(sort);
  };

  const table = useReactTable({
    data: listData,
    columns: columnsScheme,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: serverSideSorting,
    state: {
      sorting,
    },
    // Question: [1/l] What is the purpose of separate setSorting if setSorting calls inside handleSortChange?
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onSortingChange: serverSideSorting ? handleSortChange : setSorting,
    enableSortingRemoval: false,
  });

  const { rows } = table.getRowModel();

  // Virtualizing is optional, but might be necessary if we are going to potentially have hundreds or thousands of rows
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 1200,
  });
  const { virtualItems: virtualRows } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;

  return (
    <div
      className={cn(
        styles.container,
        showNothingFound && styles.displayGrid,
        className,
      )}
      data-testid={testID}
      onScroll={(event) => {
        fetchMoreOnBottomReached(event.target as HTMLDivElement);
      }}
      ref={tableContainerRef}
    >
      {title && <h2 className={cn(styles.title, 'h4')}>{title}</h2>}

      {showNothingFound && (
        <Result
          className={styles.empty}
          icon={
            <img
              src={sadCatImg}
              width={130}
              height={130}
              alt={formatMessage({
                id: 'imgAltText.sadCat',
                defaultMessage: 'Sad cat',
              })}
            />
          }
          text={formatMessage(
            {
              id: 'patientList.nothingFound',
              defaultMessage: `It looks like there are no patients with these search parameters.{newLine}Try to change search or filtering parameters`,
            },
            { newLine: NEW_LINE },
          )}
        />
      )}

      {!showNothingFound && (
        <table>
          <thead className={styles.tableHeader}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const isTabletColumnHidden =
                    header.id === PatientsListColumnName.SharedBy ||
                    header.id === PatientsListColumnName.SharingDate;

                  const isSortActive =
                    header.column.columnDef.enableSorting ?? true;

                  const isHideColumn =
                    header.column.columnDef.enableHiding ?? false;

                  const isSharedByColumnHidden =
                    !showSharedByColumn && isHideColumn;

                  const isDoctorsColumn = header.id === 'Doctors';

                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={cn(
                        styles.tableHeaderColumn,
                        isTabletColumnHidden && styles.displayNoneForTablet,
                        isSharedByColumnHidden && styles.displayNone,
                        'p2',
                      )}
                      style={{ width: header.getSize() }}
                    >
                      <div
                        className={styles.tableHeaderColumnWrapper}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {isDoctorsColumn &&
                        typeof onDoctorFilterChange === 'function' ? (
                          <DoctorsDropdownFilter
                            onChange={onDoctorFilterChange}
                          />
                        ) : (
                          <>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}

                            {isSortActive && (
                              <div className={styles.arrowIconsWrapper}>
                                <Icon
                                  name="arrowUp2"
                                  size={8}
                                  className={cn(
                                    styles.arrowIcon,
                                    header.column.getIsSorted() === 'desc' &&
                                      styles.activeIcon,
                                  )}
                                />
                                <Icon
                                  name="arrowDown2"
                                  size={8}
                                  className={cn(
                                    styles.arrowIcon,
                                    header.column.getIsSorted() === 'asc' &&
                                      styles.activeIcon,
                                  )}
                                />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          {!showEmpty && (
            <>
              {showSkeleton && (
                <div className={styles.skeletonContainer}>
                  <Skeleton.Row rowsQuantity={4} />
                </div>
              )}

              <tbody>
                {paddingTop > 0 && (
                  <tr>
                    <td style={{ height: `${paddingTop}px` }} />
                  </tr>
                )}

                {virtualRows.map((virtualRow) => {
                  const row = rows[virtualRow.index] as Row<DataType>;

                  return (
                    <tr
                      key={row.id}
                      className={cn(
                        styles.tableRow,
                        !(row.original?.Paid ?? true) &&
                          !(row.original?.Overdue ?? true) &&
                          styles.newInvoice,
                        row.original?.Overdue && styles.overdueInvoice,
                        typeof clickOnRow === 'function' &&
                          styles.cursorPointer,
                        'p2',
                      )}
                    >
                      {row.getVisibleCells().map((cell) => {
                        const isDeleteColumn =
                          cell.column.id === PatientsListColumnName.Delete;

                        const isHideColumn = cell.column.columnDef.enableHiding;

                        const isTabletColumnHidden =
                          cell.column.id === PatientsListColumnName.SharedBy ||
                          cell.column.id === PatientsListColumnName.SharingDate;

                        const isSharedByColumnHidden =
                          !showSharedByColumn && isHideColumn;

                        const isTokenAndStatusPending =
                          row.original.Token &&
                          row.original.Status ===
                            Invitation_InvitationStatus.StatusPending;

                        return (
                          <td
                            key={cell.id}
                            className={cn(
                              styles.tableCell,
                              isDeleteColumn && styles.deleteColum,
                              isTabletColumnHidden &&
                                styles.displayNoneForTablet,
                              isSharedByColumnHidden && styles.displayNone,
                            )}
                            onClick={() => {
                              if (isDeleteColumn) {
                                return;
                              }

                              if (
                                isTokenAndStatusPending &&
                                conditionalAdditionalClickOnRow
                              ) {
                                conditionalAdditionalClickOnRow(
                                  row.original.Token,
                                );
                              } else {
                                const isShared = Boolean(row.original.Target);

                                typeof clickOnRow === 'function' &&
                                  clickOnRow(
                                    isShared
                                      ? row.original.Target?.Target?.value
                                          ?.PatientID
                                      : row.original?.ID,
                                  );
                              }
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,

                              cell.getContext(),
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </>
          )}
        </table>
      )}

      {showEmpty && emptyComponent}
    </div>
  );
};
