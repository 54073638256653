import { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { FormattedDate, FormattedTime } from 'react-intl/lib';

import { useAppSelector } from '@/shared/hooks';
import { ReportUserSignature } from '@/shared/api/protocol-ts/model/dto_report_pb';
import { getName } from '@/shared/lib';

import { organizationModel, i18n } from '@/entities/organization';
import { userModel } from '@/entities/user';

import styles from './ReportSign.module.scss';

type ReportSignProps = {
  user: ReportUserSignature;
  className?: string;
};

export const ReportSign: FC<ReportSignProps> = (props) => {
  const { user, className } = props;

  const { formatMessage } = useIntl();

  const signedUser = useAppSelector(
    organizationModel.selectors.selectUserByID(user.UserID),
  );
  const userLocale = useAppSelector(userModel.selectors.selectUserLocale);

  const userName = getName(user.UserFirstName, user.UserLastName, {
    userLocale,
  });

  const speciality = signedUser?.JobPositions;

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.signedUser}>
        <p className={cn(styles.signedUserName, 'p2')}>{userName}</p>
        {speciality?.length ? (
          <p className={cn(styles.signedUserSpeciality, 'p3')}>
            {formatMessage(i18n.specialityType[speciality[0]])}
          </p>
        ) : null}
      </div>
      {user.SignedAt && (
        <p className={cn(styles.signDate, 'p2')}>
          <FormattedDate
            value={user.SignedAt.toDate()}
            day="2-digit"
            month="short"
            year="numeric"
          />{' '}
          <FormattedTime value={user.SignedAt.toDate()} hour12={false} />
        </p>
      )}
    </div>
  );
};
