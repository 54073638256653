import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { Modal } from '@/shared/ui';

import * as modalModel from '../../model';
import { ModalID } from '../../config/types';

export const ConfirmModal = () => {
  const dispatch = useAppDispatch();

  const {
    visible,
    loading = false,
    data: {
      title,
      description,
      okText,
      cancelText,
      danger = false,
      onConfirm,
      onCancel,
      // okButtonProps, // TODO: replace danger and loading props for this
      cancelButtonVariant = 'secondary', // TODO [m|4]: Refactor using the whole cancelButtonProps object and add okButtonProps
      bodyClassName,
    },
  } = useAppSelector(modalModel.selectors.selectConfirmModal);

  const handleClose = useCallback(() => {
    onCancel?.();
    dispatch(modalModel.actions.closeModal(ModalID.Confirm));
  }, [dispatch, onCancel]);

  if (!visible) {
    return null;
  }

  return (
    <Modal
      title={title}
      isOpen={visible}
      onOk={onConfirm}
      onCancel={handleClose}
      okButtonText={okText}
      okButtonProps={{
        danger,
        loading,
      }}
      cancelButtonText={cancelText}
      bodyClassName={bodyClassName}
      cancelButtonProps={{ variant: cancelButtonVariant }}
    >
      {description}
    </Modal>
  );
};
