import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { equals } from 'ramda';

import { Button, Description, Modal } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { Tooth } from '@/shared/api/protocol-ts/model/dto_report_tooth_pb';
import {
  Localization,
  LocalizationType,
} from '@/shared/api/protocol-ts/model/dto_report_localization_pb';
import { ReportType } from '@/shared/api/protocol-ts/model/dto_report_pb';
import { AbsoluteBlock } from '@/shared/graphics/RenderComponents/AbsoluteBlock';
import { RCDetectedTeeth } from '@/shared/graphics/RenderComponents/RCDetectedTeeth/RCDetectedTeeth';
import { ToothNumeration } from '@/shared/api/protocol-ts/model/dto_report_common_pb';
import { getName } from '@/shared/lib';

import { ModalID, modalModel } from '@/entities/modal';
import { patientModel } from '@/entities/patient';
import { reportsModel, useCheckReportSignature } from '@/entities/reports';
import { studyModel } from '@/entities/study';
import {
  isDuplicate,
  toothModel,
  ToothWithLocalization,
} from '@/entities/tooth';
import { organizationModel } from '@/entities/organization';
import { userModel } from '@/entities/user';

import { PanoImage } from '@/widgets/PanoImage';

import { ChangeToothNumberPopup } from '../ChangeToothNumberPopup/ChangeToothNumberPopup';
import { makeDetectionsFromTeeth } from '../../lib/makeDetectionsFromTeeth';
import { makeDetectionsFromDeletedToothLocalizations } from '../../lib/makeDetectionsFromLocalizations';
import { TeethNumberingModalProps } from '../../config/types';

import styles from './TeethNumberingModal.module.scss';

// TODO: Refactor this modal
export const TeethNumberingModal: FC<TeethNumberingModalProps> = ({
  patientID,
  reportID,
  modalWidth,
  reportImage,
}) => {
  const { formatMessage } = useIntl();

  const {
    visible,
    data: { image: modalImage },
  } = useAppSelector(modalModel.selectors.selectTeethNumberingModal);
  const report = useAppSelector(reportsModel.selectors.selectCurrentReport);
  const teeth = useAppSelector(toothModel.selectors.selectByReportID(reportID));
  const patient = useAppSelector(
    patientModel.selectors.selectPatientByID(patientID),
  );
  const dentalNotationFormat = useAppSelector(
    organizationModel.selectors.selectDentalNotationFormat,
  );
  const deletedTeethByReportID = useAppSelector(
    toothModel.selectors.selectDeletedByReportID(reportID),
  );

  const studyID = report?.SourceStudyIDs?.[0];

  const study = useAppSelector(studyModel.selectors.selectByID(studyID ?? ''));

  const [selectedTooth, setSelectedTooth] = useState<
    ToothWithLocalization | undefined
  >(undefined);
  const [isToothRemoving, setIsToothRemoving] = useState(false);
  const [animatedToothNumber, setAnimatedToothNumber] = useState<number>(0);
  const [roiTeeth, setRoiTeeth] = useState<ToothWithLocalization[]>([]);

  const panoImageContainer = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const { checkReportSignature } = useCheckReportSignature();
  const userLocale = useAppSelector(userModel.selectors.selectUserLocale);

  // TODO: Refactor this modal
  // This is a fast solution to fix opening this modal in the GpReport page
  const image = reportImage ?? modalImage;

  const patientFullName = getName(
    patient?.PersonalData?.FirstName,
    patient?.PersonalData?.LastName,
    { userLocale },
  );

  const reportType = report?.Type;

  const isPatientLoaded = !!patient;

  const assetID = study?.AssetIDs?.[0];

  const disabledByDuplicates = Boolean(
    roiTeeth.filter((item) => isDuplicate(roiTeeth, item)).length,
  );
  const localizations = makeDetectionsFromTeeth(roiTeeth);

  const deletedTeethLocalizations = makeDetectionsFromDeletedToothLocalizations(
    deletedTeethByReportID || [],
  );

  const title = formatMessage({
    id: 'report.EditTeethNumbers',
    defaultMessage: 'Edit teeth numbers',
  });

  const handleClose = useCallback(() => {
    dispatch(modalModel.actions.closeModal(ModalID.TeethNumberingModal));
  }, [dispatch]);

  const handleToothClick = (tooth?: ToothWithLocalization) => {
    setSelectedTooth(tooth);
  };

  const handleChangeToothNumber = (
    tooth: ToothWithLocalization,
    toothNumber: number,
  ) => {
    checkReportSignature({
      onSignatureChecked: async () => {
        // TODO: [2/m] add SupernumeraryIndex in ToothNumeration, otherwise it will be equal to 0
        try {
          setAnimatedToothNumber(toothNumber);
          // NOTE: Need to refactor backend and then use setReportToothNumeration
          const { Tooth, Report } = await dispatch(
            reportsModel.thunks.setToothNumerationAndRevive({
              ToothID: tooth.toothID,
              Numeration: {
                ISO: toothNumber,
                SupernumeraryIndex: tooth?.SupernumeraryIndex ?? 0,
              } as ToothNumeration,
            }),
          ).unwrap();

          if (Tooth) {
            dispatch(toothModel.actions.setNewestOne(Tooth));
          }

          if (Report) {
            dispatch(reportsModel.actions.setNewestOne(Report));
          }
          setAnimatedToothNumber(0);
        } finally {
          setSelectedTooth(undefined);
        }
      },
    });
  };

  const handleRemoveTooth = (tooth: ToothWithLocalization) => {
    checkReportSignature({
      onSignatureChecked: async () => {
        setIsToothRemoving(true);
        try {
          const { Tooth, Report } = await dispatch(
            reportsModel.thunks.markToothAsDeleted({
              ToothID: tooth.toothID,
            }),
          ).unwrap();

          if (Tooth) {
            dispatch(toothModel.actions.setNewestOne(Tooth));
          }

          if (Report) {
            dispatch(reportsModel.actions.setNewestOne(Report));
          }
        } finally {
          setSelectedTooth(undefined);
          setIsToothRemoving(false);
        }
      },
    });
  };

  useEffect(() => {
    if (teeth?.length) {
      const patientTeeth = teeth.reduce((modifiedTeeth, tooth: Tooth) => {
        if (
          !tooth?.IsRemoved &&
          tooth?.Detections?.length &&
          tooth.Detections.some(
            (detection) =>
              detection.Localizations?.Type ===
              LocalizationType.LocalizationType2D,
          )
        ) {
          modifiedTeeth.push({
            toothID: tooth?.ID,
            ISONumber: tooth.Numeration?.ISO ?? 0,
            SupernumeraryIndex: tooth.Numeration?.SupernumeraryIndex ?? 0,
            Localization:
              tooth?.Detections.find(
                (detection) =>
                  detection.Localizations?.Type ===
                  LocalizationType.LocalizationType2D,
              )?.Localizations ?? ({} as Localization),
          });
        }
        return modifiedTeeth;
      }, [] as ToothWithLocalization[]);

      if (!equals(patientTeeth, roiTeeth)) {
        setRoiTeeth(patientTeeth);
      }
    }
  }, [teeth, image, roiTeeth, reportType]);

  return (
    <Modal
      title={title}
      isOpen={visible}
      onCancel={handleClose}
      className={styles.modal}
      containerClassName={styles.modalContainer}
      hideFooter
      borderless
      shouldRenderCloseIconButton={false}
      shouldCloseOnOverlayClick={!disabledByDuplicates}
      shouldRenderCancelButton={false}
      modalInlineStyle={{ width: `${modalWidth}px` }}
    >
      <div className={styles.container}>
        <div className={styles.imageContainer} ref={panoImageContainer}>
          {reportType === ReportType.ReportType_CBCT_GP && (
            <PanoImage
              src={image.src}
              controls={[]}
              kind="dicom"
              objectFit="cover"
              style={{
                width: '100%',
                height: image.height,
                objectFit: 'contain',
              }}
              containerWidth={image.width ?? 0}
              fixedHeight={image.height}
              assetID={assetID}
              viewOptions={image.viewOptions}
            />
          )}

          <AbsoluteBlock style={{ top: 0, left: 0 }}>
            <RCDetectedTeeth
              deletedTeethLocalizations={deletedTeethLocalizations}
              localizations={localizations}
              imageSize={{
                width: (image?.width ?? 0) / (image?.zoom ?? 0),
                height: (image?.height ?? 0) / (image?.zoom ?? 0),
              }}
              onToothClick={handleToothClick}
              dentalNotationFormat={dentalNotationFormat}
            />
          </AbsoluteBlock>

          <ChangeToothNumberPopup
            isOpen={!!selectedTooth}
            onChangeToothNumber={handleChangeToothNumber}
            onChangeSelectedTooth={handleToothClick}
            title={title}
            selectedTooth={selectedTooth}
            isToothRemoving={isToothRemoving}
            dentalNotationFormat={dentalNotationFormat}
            onRemoveTooth={handleRemoveTooth}
            animatedToothNumber={animatedToothNumber}
          />
        </div>
      </div>

      <div className={styles.footer}>
        {isPatientLoaded && (
          <div className={styles.descriptionContainer}>
            <Description
              label={
                <FormattedMessage
                  id="teethNymbering.patient"
                  defaultMessage="Patient"
                />
              }
            >
              {patientFullName}
            </Description>
          </div>
        )}

        <Button
          size="medium"
          onClick={handleClose}
          className={styles.approveButton}
        >
          <FormattedMessage id="patient.confirm" defaultMessage="Confirm" />
        </Button>
      </div>
    </Modal>
  );
};
