import { createSelector } from '@reduxjs/toolkit';

import { Condition } from '@/shared/api/protocol-ts/model/dto_report_condition_pb';
import {
  conditionIsUncertain,
  filterConditionsWithLocalizations,
} from '@/shared/embeddedLibs/conditionsAndMasks';
import { ConditionCode } from '@/shared/api/protocol-ts/model/dto_report_condition_codes_pb';

import { reportsModel } from '@/entities/reports';
import { assetsModel } from '@/entities/assets';

import { RootState } from '@/app/model/store';

import {
  conditionIsActive,
  filterConditionsByReportID,
  getSlicesMasks,
  processLogicalConditions,
} from '../lib';

import { conditionAdapter } from './conditionSlice';

export const conditionSelectors = conditionAdapter.getSelectors(
  (state: RootState) => state.condition,
);

export const { selectAll, selectEntities } = conditionSelectors;

export const selectByID = (toothID: string) => (state: RootState) =>
  conditionSelectors.selectById(state, toothID);

export const selectToothConditionIDs =
  (toothID: string) => (state: RootState) =>
    state.condition.teethGroup[toothID];

export const selectTeethConditionIDs =
  (teethIDs: string[]) => (state: RootState) =>
    teethIDs.flatMap((toothID) => state.condition.teethGroup[toothID]);

export const selectTeethGroup = (state: RootState) =>
  state.condition.teethGroup;

export const selectHoveredConditionIDs = (state: RootState) =>
  state.condition.hoveredConditionIDs;

export const selectMaxFaxConditionIDs = (state: RootState) =>
  state.condition.maxFaxGroup;

export const selectGroupedByToothIDConditions = createSelector(
  selectAll,
  (conditions) =>
    conditions.reduce(
      (groupedConditions, condition) => {
        const toothID =
          condition.Attribution.case === 'Tooth'
            ? condition.Attribution.value.ToothID
            : '';

        if (toothID in groupedConditions) {
          groupedConditions[toothID].push(condition);
        } else {
          groupedConditions[toothID] = [condition];
        }

        return groupedConditions;
      },
      {} as Record<string, Condition[]>,
    ),
);

export const selectByToothID = (toothID: string) =>
  createSelector(
    conditionSelectors.selectEntities,
    selectToothConditionIDs(toothID),
    (conditionEntities, toothConditionIDs) => {
      const toothConditions: Condition[] = [];

      toothConditionIDs?.forEach((id) => {
        if (conditionEntities[id]) {
          toothConditions.push(conditionEntities[id]);
        }
      });
      return toothConditions;
    },
  );

export const selectActiveByToothID = (toothID: string) =>
  createSelector(
    conditionSelectors.selectEntities,
    selectToothConditionIDs(toothID),
    (conditionEntities, toothConditionIDs) => {
      const toothConditions: Condition[] = [];

      toothConditionIDs?.forEach((id) => {
        if (conditionEntities[id] && conditionIsActive(conditionEntities[id])) {
          toothConditions.push(conditionEntities[id]);
        }
      });
      return toothConditions;
    },
  );

export const selectPreviewActiveByToothID = (toothID: string) =>
  createSelector(
    conditionSelectors.selectEntities,
    selectToothConditionIDs(toothID),
    (conditionEntities, toothConditionIDs) => {
      const toothConditions: Condition[] = [];

      toothConditionIDs?.forEach((id) => {
        if (
          conditionEntities[id] &&
          conditionIsActive(conditionEntities[id]) &&
          !conditionIsUncertain(conditionEntities[id].Certainty)
        ) {
          toothConditions.push(conditionEntities[id]);
        }
      });
      return toothConditions;
    },
  );

export const selectHoveredConditions = createSelector(
  conditionSelectors.selectEntities,
  selectHoveredConditionIDs,
  (conditionEntities, hoveredConditionIDs) => {
    const hoveredConditions: Condition[] = [];

    hoveredConditionIDs?.forEach((id) => {
      if (conditionEntities[id] && conditionIsActive(conditionEntities[id])) {
        hoveredConditions.push(conditionEntities[id]);
      }
    });
    return hoveredConditions;
  },
);

// Collect all yellow (uncertain) condition ids to use it with approve tooth request
export const selectYellowIDsByToothID = (toothID: string) =>
  createSelector(
    conditionSelectors.selectEntities,
    selectToothConditionIDs(toothID),
    (conditionEntities, toothConditionIDs) => {
      const yellowConditionIDs: string[] = [];

      toothConditionIDs?.forEach((id) => {
        if (
          conditionEntities[id] &&
          conditionIsUncertain(conditionEntities[id].Certainty)
        ) {
          yellowConditionIDs.push(conditionEntities[id].ID);
        }
      });
      return yellowConditionIDs;
    },
  );

export const selectToothLogicalConditions = (toothID: string) =>
  createSelector(selectByToothID(toothID), processLogicalConditions);

export const selectToothActiveLogicalConditions = (toothID: string) =>
  createSelector(selectActiveByToothID(toothID), processLogicalConditions);

export const selectToothPreviewActiveLogicalConditions = (toothID: string) =>
  createSelector(
    selectPreviewActiveByToothID(toothID),
    processLogicalConditions,
  );

export const selectByTeethIDs = (teethID: string[]) =>
  createSelector(
    conditionSelectors.selectEntities,
    selectTeethConditionIDs(teethID),
    (conditionEntities, teeththConditionIDs) => {
      const toothConditions: Condition[] = [];

      teeththConditionIDs?.forEach((id) => {
        if (conditionEntities[id]) {
          toothConditions.push(conditionEntities[id]);
        }
      });
      return toothConditions;
    },
  );

export const selectByReportID = createSelector(
  conditionSelectors.selectAll,
  (_: RootState, reportID: string) => reportID,
  filterConditionsByReportID,
);

export const selectMaxFax = createSelector(
  conditionSelectors.selectEntities,
  selectMaxFaxConditionIDs,
  (conditionEntities, maxFaxIDs) => {
    const maxFaxConditions: Condition[] = [];

    maxFaxIDs?.forEach((id) => {
      if (conditionEntities[id]) {
        maxFaxConditions.push(conditionEntities[id]);
      }
    });
    return maxFaxConditions;
  },
);

export const selectConditionsWithLocalizations = createSelector(
  selectAll,
  reportsModel.selectors.selectCurrentReport,
  assetsModel.selectors.selectAll,
  (conditions, report, assets) => {
    if (!report || !assets) {
      return [];
    }

    return filterConditionsWithLocalizations(conditions, report, assets);
  },
);

export const selectConditionCodesWithMasks = createSelector(
  selectConditionsWithLocalizations,
  (conditions) => conditions.map((condition) => condition.Code),
);

export const selectSlicesMasks = (
  toothID: string,
  conditionCodes: ConditionCode[],
) => createSelector(selectByToothID(toothID), getSlicesMasks(conditionCodes));

export const selectPeriapicalConditionByToothID = (toothID: string) =>
  createSelector(selectByToothID(toothID), (conditions) =>
    conditions.find(
      (condition) => condition.Code === ConditionCode.PeriapicalRadiolucency,
    ),
  );

export const selectLoading = (state: RootState) => state.condition.loading;
