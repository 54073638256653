import { prop, groupBy } from 'ramda';

import { ConditionGroups, ChildConditionGroups } from '../config/const';
import type { CompactCondition } from '../config/types';

export const compactConditionHasProbability = (
  childCondition: CompactCondition,
) => (childCondition?.probability ?? 0) > 0;

export const sortCompactConditionByProbability = (
  a: CompactCondition,
  b: CompactCondition,
) => (b.probability ?? 0) * 100 - (a.probability ?? 0) * 100;

export const groupCompactCondition = (conditionItems: CompactCondition[]) =>
  groupBy(prop('group'), conditionItems) as Record<
    ConditionGroups | ChildConditionGroups,
    CompactCondition[]
  >;
