import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Code, ConnectError } from '@bufbuild/connect';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import api from '@/shared/api/api';
import { AccountInfoStreamDataAccumulatorKeys, PATHS } from '@/shared/config';
import { ProductState } from '@/shared/api/protocol-ts/api/billing_new/dto_product_new_pb';
import { Order } from '@/shared/api/protocol-ts/api/billing_new/dto_order_new_pb';

import { billingModel } from '@/entities/billing';
import { organizationModel } from '@/entities/organization';

type AccountInfoStreamDataAccumulators = {
  [AccountInfoStreamDataAccumulatorKeys.inventory]: ProductState[];
  [AccountInfoStreamDataAccumulatorKeys.orders]: Order[];
};

const dataAccumulators: AccountInfoStreamDataAccumulators = {
  [AccountInfoStreamDataAccumulatorKeys.inventory]: [],
  [AccountInfoStreamDataAccumulatorKeys.orders]: [],
};

let abortController: AbortController;

export const useAccountInfoStream = () => {
  const organizationID = useAppSelector(
    organizationModel.selectors.selectCurrentOrganizationID,
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const openAccountInfoStream = async () => {
    abortController = new AbortController();

    dispatch(billingModel.actions.setLoading('pending'));

    try {
      const accountInfoStream = api.billing.accountInfoStream(
        { OrganizationID: organizationID },
        { signal: abortController.signal },
      );

      for await (const { Update } of accountInfoStream) {
        switch (Update.case) {
          case 'AccountInfo': {
            dispatch(billingModel.actions.setAccount(Update.value));
            break;
          }

          case 'HistoricalInventory': {
            dataAccumulators[
              AccountInfoStreamDataAccumulatorKeys.inventory
            ].push(Update.value);
            break;
          }

          case 'HistoricalOrder': {
            if (Update.value.Kind.case === 'Billed') {
              dataAccumulators[
                AccountInfoStreamDataAccumulatorKeys.orders
              ].push(Update.value);
            }
            break;
          }

          case 'EndOfHistoricalInventory': {
            dispatch(
              billingModel.actions.setInventory(
                dataAccumulators[
                  AccountInfoStreamDataAccumulatorKeys.inventory
                ],
              ),
            );

            dataAccumulators[AccountInfoStreamDataAccumulatorKeys.inventory] =
              [];
            break;
          }

          case 'EndOfHistoricalOrder': {
            dispatch(
              billingModel.actions.setInvoices(
                dataAccumulators[AccountInfoStreamDataAccumulatorKeys.orders],
              ),
            );

            dataAccumulators[AccountInfoStreamDataAccumulatorKeys.orders] = [];

            dispatch(billingModel.actions.setLoading('succeeded'));
            break;
          }

          case 'Inventory': {
            dispatch(billingModel.actions.updateInventory(Update.value));
            break;
          }

          case 'Order': {
            if (Update.value.Kind.case === 'Billed') {
              dispatch(billingModel.actions.updateOrders(Update.value));
            }
            break;
          }
        }
      }
    } catch (error) {
      if (error instanceof ConnectError && error.code !== Code.Canceled) {
        dispatch(billingModel.actions.setLoading('failed'));

        if (error.code === Code.NotFound) {
          navigate(PATHS.patients);
        }

        // eslint-disable-next-line no-console
        console.error(
          'AccountInfoStream: error: ',
          error.message,
          error.code,
          error.rawMessage,
          error,
        );
      }
    }
  };

  const closeAccountInfoStream = () => {
    if (abortController) {
      abortController.abort();
    }
  };

  useEffect(() => {
    if (organizationID) {
      openAccountInfoStream();
    }

    return () => {
      closeAccountInfoStream();
    };
  }, [organizationID]);
};
