import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SliceName } from '@/shared/config';

const slicesSlice = createSlice({
  name: SliceName.slices,
  initialState: {
    draggedSliceID: '',
  },
  reducers: {
    setDraggedSliceID: (state, { payload }: PayloadAction<string>) => {
      state.draggedSliceID = payload;
    },
  },
});

export const { actions } = slicesSlice;

export default slicesSlice.reducer;
