import { ConditionCode } from '@/shared/api/protocol-ts/model/dto_report_condition_codes_pb';

export const splitConditionCodes = (codes: ConditionCode[] = []) =>
  codes.reduce(
    (acc, code) => {
      if (code < 5000) {
        acc.parentCodes.push(code);
      } else {
        acc.childrenCodes.push(code);
      }

      return acc;
    },
    { parentCodes: [], childrenCodes: [] } as {
      parentCodes: ConditionCode[];
      childrenCodes: ConditionCode[];
    },
  );
