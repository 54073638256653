import {
  Report,
  ReportType,
} from '@/shared/api/protocol-ts/model/dto_report_pb';

// NOTE: A temporary solution until ReportType_IOS_Segmentation is fully implemented
export const isStudyUsedOnlyInIOSSegmentation = (
  studyID: string,
  reports: Report[],
): boolean => {
  const reportsWithStudy = reports.filter((report) =>
    report.SourceStudyIDs.includes(studyID),
  );

  return (
    reportsWithStudy.length > 0 &&
    reportsWithStudy.every(
      (report) => report.Type === ReportType.ReportType_IOS_Segmentation,
    )
  );
};
