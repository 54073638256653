import { generatePath, useLocation, useNavigate } from 'react-router';

import { ReportType } from '@/shared/api/protocol-ts/model/dto_report_pb';
import { LocationStateType } from '@/shared/config';
import { getSegmentronReportLink } from '@/shared/lib';
import api from '@/shared/api/api';

import { SegmentationReportTypes } from '../config';
import { getReportPath } from '../lib';

export const useNavigateToReport = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // NOTE: type casting is necessary for typification location.state
  const locationState = location?.state as LocationStateType;

  return async ({
    reportID,
    reportType,
    patientID,
    CBCTOrthoPDFUrl,
  }: {
    reportID: string;
    reportType: ReportType;
    patientID: string;
    CBCTOrthoPDFUrl: string;
  }) => {
    const isCBCTOrthoPDFReport =
      reportType === ReportType.ReportType_CBCT_OrthoSlides;

    const isSegmentronReport = SegmentationReportTypes.includes(reportType);

    if (isCBCTOrthoPDFReport) {
      // NOTE: refresh is necessary to avoid authorization issues when navigating to orthoPDF
      await api.refresher.refresh();

      window.open(CBCTOrthoPDFUrl, '_blank', 'noopener,noreferrer');
    } else if (isSegmentronReport) {
      window.open(
        getSegmentronReportLink(reportID, reportType),
        '_blank',
        'noopener,noreferrer',
      );
    } else if (patientID && reportID) {
      navigate(
        generatePath(getReportPath(reportType), {
          patientID,
          reportID,
        }),
        {
          state: {
            ...locationState,
            lastPositionPatientProfile: window.pageYOffset,
          },
        },
      );
    }
  };
};
