import { FC, useCallback } from 'react';
import cn from 'classnames';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';
import { MaskGroup } from '@/shared/config';

import { organizationModel } from '@/entities/organization';

import { MaskFilterButton } from '../MaskFilterButton/MaskFilterButton';
import { maskFiltersModel } from '../..';

import styles from './MaskFilters.module.scss';

type MaskFiltersProps = {
  className?: string;
  testID?: string;
};

export const MaskFilters: FC<MaskFiltersProps> = (props) => {
  const { className, testID } = props;

  const dispatch = useAppDispatch();

  const filterGroups = useAppSelector(maskFiltersModel.selectors.selectFilters);

  const hideMasks = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_Hide_ConditionsMasks,
    ),
  );

  const handleClick = useCallback(
    (filterGroup: MaskGroup) => {
      dispatch(maskFiltersModel.actions.toggleFilter(filterGroup));
    },
    [dispatch],
  );

  return hideMasks ? null : (
    <div data-testid={testID} className={cn(styles.container, className)}>
      {filterGroups.map(([filterGroup, { isActive }]) => (
        <MaskFilterButton
          key={filterGroup}
          filterGroup={filterGroup}
          active={isActive}
          onClick={handleClick}
        />
      ))}
    </div>
  );
};
