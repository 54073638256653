import { Effect } from '@/shared/api/protocol-ts/api/billing_new/dto_order_new_pb';
import { useAppSelector } from '@/shared/hooks';

import { billingModel } from '@/entities/billing';

export const useProductName = (
  product: Effect['Effect'] | undefined,
): string => {
  const products = useAppSelector(billingModel.selectors.selectInventory);

  if (!product) return '';

  switch (product.case) {
    case 'SubscriptionAdd':
      return product.value.Subscription?.Name ?? '';
    case 'PackageAdd':
      return product.value.Package?.Name ?? '';
    case 'ProductStateRemove':
      return (
        products?.find(({ ID }) => ID === product.value.RemoveProductStateID)
          ?.Model?.Name ?? ''
      );
    case 'SubscriptionRenew':
      return (
        products?.find(
          ({ ID }) => ID === product.value.RenewSubscriptionStateID,
        )?.Model?.Name ?? ''
      );
    case 'SubscriptionReplace':
      return product.value.NewSubscription?.Name ?? '';
    case 'SubscriptionActivate':
      return (
        products?.find(
          ({ ID }) => ID === product.value.ActivateSubscriptionStateID,
        )?.Model?.Name ?? ''
      );
    case 'SubscriptionSetAutoRenewal':
      return (
        products?.find(({ ID }) => ID === product.value.SubscriptionStateID)
          ?.Model?.Name ?? ''
      );
    case 'SubscriptionSetAutoCharge':
      return (
        products?.find(({ ID }) => ID === product.value.SubscriptionStateID)
          ?.Model?.Name ?? ''
      );
    case 'AddonAdd':
    case 'BonusAdd':
      return product.value.Addon?.Name ?? '';
    default:
      return '';
  }
};
