import { Annotation } from '@/shared/api/protocol-ts/model/dto_common_image_annotations_pb';
import { Annotation as GraphicsAnnotation } from '@/shared/graphics/RenderComponents/AnnotationsLayer/Annotation';

// TODO: this should be refactored after migration to connect lib
// Do not use this as an example of how to write code
export const formatAnnotations = (annotations: GraphicsAnnotation[]) =>
  annotations.reduce((accum, graphicAnnotation) => {
    switch (graphicAnnotation.kind) {
      case 'ruler': {
        accum.push(
          new Annotation({
            Type: {
              case: 'Ruler',
              value: {
                Start: {
                  X: graphicAnnotation.x1,
                  Y: graphicAnnotation.y1,
                },
                End: {
                  X: graphicAnnotation.x2,
                  Y: graphicAnnotation.y2,
                },
              },
            },
          }),
        );
        break;
      }
      case 'arrow': {
        accum.push(
          new Annotation({
            Type: {
              case: 'Arrow',
              value: {
                Start: {
                  X: graphicAnnotation.x1,
                  Y: graphicAnnotation.y1,
                },
                End: {
                  X: graphicAnnotation.x2,
                  Y: graphicAnnotation.y2,
                },
              },
            },
          }),
        );
        break;
      }

      case 'angle': {
        accum.push(
          new Annotation({
            Type: {
              case: 'Angle',
              value: {
                Start: {
                  X: graphicAnnotation.x1,
                  Y: graphicAnnotation.y1,
                },
                End: {
                  X: graphicAnnotation.x2,
                  Y: graphicAnnotation.y2,
                },
                Corner: {
                  X: graphicAnnotation.x3,
                  Y: graphicAnnotation.y3,
                },
              },
            },
          }),
        );
        break;
      }

      default: {
        return accum;
      }
    }

    return accum;
  }, [] as Annotation[]);
