import { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { CompactCondition, i18n, OnConditionHover } from '@/entities/condition';

import styles from './ConditionTitle.module.scss';

type ConditionTitleProps = {
  className?: string;
  conditionItem: CompactCondition;
  toothNumber: string;
  onHoverHandle: (args: OnConditionHover) => void;
  onClickHandle: () => void;
  isToothChartMode?: boolean;
};

export const ConditionTitle: FC<ConditionTitleProps> = (props) => {
  const {
    className,
    conditionItem,
    onHoverHandle,
    onClickHandle,
    toothNumber,
    isToothChartMode,
  } = props;

  const { formatMessage } = useIntl();

  return (
    <div
      className={cn(
        styles.container,
        styles[conditionItem?.color],
        isToothChartMode && styles.chartModeTitleCondition,
        'h4',
        className,
      )}
      onMouseEnter={() =>
        onHoverHandle({
          conditionItem,
          isMouseEnter: true,
        })
      }
      onMouseLeave={() =>
        onHoverHandle({
          conditionItem,
          isMouseEnter: false,
        })
      }
      onClick={onClickHandle}
    >
      {conditionItem.code in i18n.conditionText &&
        formatMessage(i18n.conditionText[conditionItem.code])}{' '}
      {toothNumber}
    </div>
  );
};
