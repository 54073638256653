import { useMemo } from 'react';

import { useAppSelector } from '@/shared/hooks/store';
// TODO: 2 entities - this file should be in the feature slice
import { Condition } from '@/shared/api/protocol-ts/model/dto_report_condition_pb';
import { Tooth } from '@/shared/api/protocol-ts/model/dto_report_tooth_pb';
import { ToothStatus } from '@/shared/config';
import { shouldConditionItemBeShown } from '@/shared/embeddedLibs/conditionsAndMasks';
import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';

import { organizationModel } from '@/entities/organization';

import { conditionModel, getToothStatus } from '../../condition';
import { reportsModel } from '../../reports';
import { ToothType } from '../config/tooth.type';

const getToothConditions = (toothID: string, conditions: Condition[]) =>
  conditions.filter(
    (condition) =>
      condition.Attribution.case === 'Tooth' &&
      condition.Attribution.value.ToothID === toothID,
  );

export type ToothWithStatus = Tooth & {
  toothStatus: ToothStatus;
};

type UseToothPropsReturnType = {
  /**
   * @deprecated
   */
  teethWithStatus: Record<string, ToothWithStatus>;
  /**
   * @deprecated
   */
  counters: Record<ToothType, number[]>;
  /**
   * @deprecated
   */
  showLowProbability: boolean;
};

type UseToothProps = (props: {
  reportID: string;
  teeth: Tooth[];
}) => UseToothPropsReturnType;

/**
 * @deprecated
 */
export const useToothProps: UseToothProps = ({ reportID, teeth }) => {
  const report = useAppSelector(reportsModel.selectors.selectByID(reportID));
  const showLowProbability = report?.Settings?.LowProbabilityMode ?? false;

  const isFDA = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_FDA_SubmissionView,
    ),
  );

  const isFDANonAided = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_FDA_NonAidedVersion,
    ),
  );

  const allConditions = useAppSelector((state) =>
    conditionModel.selectors.selectByReportID(state, reportID),
  );

  const filteredConditions = useMemo(
    () =>
      allConditions.filter((condition) =>
        shouldConditionItemBeShown(showLowProbability)(condition),
      ),
    [allConditions, showLowProbability],
  );

  const counters: Record<ToothType, number[]> = {
    lowProbability: [],
    unhealthy: [],
    treated: [],
    healthy: [],
    missing: [],
    all: [],
  };

  const teethConditions = teeth.reduce(
    (acc, tooth) => ({
      ...acc,
      [tooth.ID]: getToothConditions(tooth.ID, filteredConditions),
    }),
    {} as Record<string, Condition[]>,
  );

  const teethWithStatus = teeth.reduce(
    (acc, tooth) => {
      const toothConditions = teethConditions[tooth.ID];
      const toothISO = tooth.Numeration?.ISO;

      if (toothISO) {
        counters.all.push(toothISO);
      }

      // @ts-expect-error WARN: Deprecated
      const toothWithStatus: ToothWithStatus = {
        ...tooth,
        toothStatus: getToothStatus(toothConditions, {
          showLowProbability,
          isFDA,
          isFDANonAided,
        }),
      };

      const toothWithStatusISO = toothWithStatus.Numeration?.ISO;

      if (
        toothWithStatus.toothStatus === 'lowProbability' &&
        toothWithStatusISO
      ) {
        counters.lowProbability.push(toothWithStatusISO);
      } else if (
        toothWithStatus.toothStatus === 'unhealthy' &&
        toothWithStatusISO
      ) {
        counters.unhealthy.push(toothWithStatusISO);
      } else if (
        toothWithStatus.toothStatus === 'treated' &&
        toothWithStatusISO
      ) {
        counters.treated.push(toothWithStatusISO);
      } else if (
        toothWithStatus.toothStatus === 'healthy' &&
        toothWithStatusISO
      ) {
        counters.healthy.push(toothWithStatusISO);
      }

      return { ...acc, [tooth.ID]: toothWithStatus };
    },
    {} as Record<string, ToothWithStatus>,
  );

  return { teethWithStatus, counters, showLowProbability };
};
