import { createSelector } from '@reduxjs/toolkit';
import { head } from 'ramda';

import {
  StudyStatus,
  StudyType,
} from '@/shared/api/protocol-ts/model/dto_study_pb';

import { reportsModel } from '@/entities/reports';
import { studyModel } from '@/entities/study';
import { getImageSrc } from '@/entities/assets';

import {
  isStudyUsedOnlyInIOSSegmentation,
  reportHasStudyIDAndNotPanowing,
} from '../lib';

type StudyOrderItem = {
  id: string;
  createdAt?: Date;
  previewSrc: string;
};

export const selectStudyList = (studyType: StudyType) =>
  createSelector(
    studyModel.selectors.selectByType(studyType),
    reportsModel.selectors.selectAll,
    (studies, reports): StudyOrderItem[] => {
      return studies
        .filter(
          (study) =>
            study.Status === StudyStatus.StudyStatus_Finished &&
            !isStudyUsedOnlyInIOSSegmentation(study.ID, reports),
        )
        .map((study) => {
          const studyReports = reports.filter(
            reportHasStudyIDAndNotPanowing(study.ID),
          );

          const firstStudyReport = head(studyReports);
          const previewSrc = getImageSrc(
            firstStudyReport?.DisplayAssetID || study.DisplayAssetID,
            'preview',
          );

          return {
            id: study.ID,
            createdAt: study.Time
              ? study.Time.toDate()
              : study.Created?.At && study.Created.At.toDate(),
            previewSrc,
          };
        });
    },
  );

export const selectFirstStudyID = (studyType: StudyType) =>
  createSelector(
    selectStudyList(studyType),
    (studyList) => head(studyList)?.id,
  );
