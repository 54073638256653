import { useMemo } from 'react';

import { ConditionCode } from '@/shared/api/protocol-ts/model/dto_report_condition_codes_pb';
import { useAppSelector } from '@/shared/hooks';

import { composedModel } from '@/entities/composed';

import { filterCompactConditionsByGroups } from '../lib';
import {
  CompactCondition,
  TOOTH_ANATOMY_GROUPS,
  TOOTH_GENERAL_GROUPS,
  TOOTH_TYPE_GROUP,
} from '../config';
import * as conditionModel from '../model';

type UseToothConditionsPropsReturnType = {
  conditionIsLoaded: boolean;
  anatomyConditionItems: CompactCondition[];
  generalConditionItems: CompactCondition[];
  isMissingTooth: boolean;
};

type UseToothConditions = (props: {
  toothID: string;
}) => UseToothConditionsPropsReturnType;

export const useToothConditions: UseToothConditions = ({ toothID }) => {
  const conditionLoading = useAppSelector(
    conditionModel.selectors.selectLoading,
  );
  const conditionIsLoaded = conditionLoading === 'succeeded';

  const dataConditionsItems = useAppSelector(
    composedModel.selectors.selectCompactToothConditions(toothID),
  );

  const anatomyConditionItems = useMemo(
    () =>
      filterCompactConditionsByGroups({
        ...TOOTH_TYPE_GROUP,
        ...TOOTH_ANATOMY_GROUPS,
      })(dataConditionsItems),
    [dataConditionsItems],
  );

  const generalConditionItems = useMemo(
    () =>
      filterCompactConditionsByGroups(TOOTH_GENERAL_GROUPS)(
        dataConditionsItems,
      ),
    [dataConditionsItems],
  );

  // TODO: Make as selector and use inside tooth card? Can tooth status selector do this?
  const isMissingTooth = !!dataConditionsItems.find(
    (condition) => condition.code === ConditionCode.Missing,
  );

  return {
    conditionIsLoaded,
    anatomyConditionItems,
    generalConditionItems,
    isMissingTooth,
  };
};
