import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Button, Popup } from '@/shared/ui';

import { getDisplayToothNumber } from '@/entities/tooth';

import { ChangeToothNumberPopupProps } from '../../config/types';
import {
  LOWER_JAW_PERMANENT,
  LOWER_JAW_PRIMARY,
  UPPER_JAW_PERMANENT,
  UPPER_JAW_PRIMARY,
} from '../../config/constatnts';

import styles from './ChangeToothNumberPopup.module.scss';

export const ChangeToothNumberPopup: FC<ChangeToothNumberPopupProps> = ({
  isOpen,
  title,
  isToothRemoving,
  selectedTooth,
  dentalNotationFormat,
  onChangeSelectedTooth,
  onChangeToothNumber,
  onRemoveTooth,
  animatedToothNumber,
}) => {
  const teethNumbers = [...UPPER_JAW_PERMANENT, ...LOWER_JAW_PERMANENT];
  const teethNumbersPrimary = [...UPPER_JAW_PRIMARY, ...LOWER_JAW_PRIMARY];

  return (
    <Popup
      isOpen={isOpen}
      overlayClassname={styles.popupOverlay}
      bodyClassname={styles.popupBody}
      showHeader={false}
      onClose={() => onChangeSelectedTooth(undefined)}
    >
      <div className={styles.heading}>
        <h2 className={styles.title}>{title}</h2>
        <Button
          size="medium"
          onClick={() =>
            onRemoveTooth && selectedTooth && onRemoveTooth(selectedTooth)
          }
          icon="delete"
          variant="secondary"
          loading={isToothRemoving}
          danger
        >
          <FormattedMessage id="patient.remove" defaultMessage="Remove" />
        </Button>
      </div>

      <div className={styles.teeth}>
        {teethNumbers.map((num) => (
          <div
            key={num}
            className={cn(
              'p3',
              styles.number,
              !selectedTooth?.isRemoved && selectedTooth?.ISONumber === num
                ? styles.selected
                : '',
            )}
            onClick={() => {
              if (selectedTooth) {
                onChangeToothNumber(selectedTooth, num);
              }
            }}
          >
            {getDisplayToothNumber(num, dentalNotationFormat)}
          </div>
        ))}
      </div>

      <div className={styles.primaryTeeth}>
        {teethNumbersPrimary.map((num) => (
          <div
            key={num}
            className={cn('p3', styles.number, {
              [styles.animated]: animatedToothNumber === num,
              [styles.selected]: selectedTooth?.ISONumber === num,
            })}
            onClick={() => {
              if (selectedTooth) {
                onChangeToothNumber(selectedTooth, num);
              }
            }}
          >
            {getDisplayToothNumber(num, dentalNotationFormat)}
          </div>
        ))}
      </div>
    </Popup>
  );
};
