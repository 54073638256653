// TODO [8/l] Fix types for reducer
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import {
  AnyAction,
  // Don't remove, need to debug dispatch spam situation from streams
  // autoBatchEnhancer,
  combineReducers,
  configureStore,
  DevToolsEnhancerOptions,
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

import { ENVIRONMENT, SliceName } from '@/shared/config';

// Legacy for client
import notificationReducer from '@/entities/notification/model/notificationSlice';
import billingReducer from '@/entities/billing/model/billingSlice';
import userReducer from '@/entities/user/model/userSlice';
import authReducer from '@/entities/auth/model/authSlice';
import themeReducer from '@/entities/theme/model/themeSlice';
import reportsReducer from '@/entities/reports/model/reportSlice';
import patientReducer from '@/entities/patient/model/patientSlice';
import studyReducer from '@/entities/study/model/studySlice';
import organizationReducer from '@/entities/organization/model/organizationSlice';
import studyCountReducer from '@/entities/studyCount/model/studyCountSlice';
import accessReducer from '@/entities/access/model/accessSlice';
import allowedToothConditionsReducer from '@/entities/allowedToothConditions/model/allowedToothConditionsSlice';
import conditionReducer from '@/entities/condition/model/conditionSlice';
import toothReducer from '@/entities/tooth/model/toothSlice';
import modalReducer from '@/entities/modal/model/modalSlice';
import assetsReduce from '@/entities/assets/model/assetsSlice';
import marketingReducer from '@/entities/marketing/model/marketingSlice';
import toothLandmarksReducer from '@/entities/toothLandmarks/model/toothLandmarksSlice';
import slicesReducer from '@/entities/slices/model/slicesSlice';

import renderMasksReducer from '@/features/renderMasks/model/renderMasksSlice';

import { errorMiddleware } from './errorMiddleware';

const appReducer = combineReducers({
  [SliceName.access]: accessReducer,
  [SliceName.condition]: conditionReducer,
  [SliceName.marketing]: marketingReducer,
  [SliceName.modal]: modalReducer,
  [SliceName.auth]: authReducer,
  [SliceName.assets]: assetsReduce,
  [SliceName.patient]: patientReducer,
  [SliceName.notification]: notificationReducer,
  [SliceName.billing]: billingReducer,
  [SliceName.user]: userReducer,
  [SliceName.theme]: themeReducer,
  [SliceName.reports]: reportsReducer,
  [SliceName.study]: studyReducer,
  [SliceName.organization]: organizationReducer,
  [SliceName.studyCount]: studyCountReducer,
  [SliceName.tooth]: toothReducer,
  [SliceName.allowedToothConditions]: allowedToothConditionsReducer,
  [SliceName.toothLandmarks]: toothLandmarksReducer,
  [SliceName.maskFilters]: renderMasksReducer,
  [SliceName.slices]: slicesReducer,
});

const rootReducer = (
  state: ReturnType<typeof appReducer> | undefined,
  action: AnyAction,
) => {
  if (action.type === 'store/reset') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const devToolsConfig: DevToolsEnhancerOptions = {
  maxAge: 10,
};

export const store = configureStore({
  reducer: rootReducer,
  devTools: ENVIRONMENT !== 'production' && devToolsConfig,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(errorMiddleware),
  // Don't remove, need to debug dispatch spam situation from streams
  // enhancers: (defaultEnchancers) =>
  //   defaultEnchancers.concat(autoBatchEnhancer({ type: 'tick' })),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
