import { FC, useRef, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { Modal } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { getName } from '@/shared/lib';

import { ModalID, modalModel } from '@/entities/modal';
import { userModel } from '@/entities/user';

import styles from './ProtectedSharingModal.module.scss';

export const ProtectedSharingModal: FC = () => {
  const dispatch = useAppDispatch();
  const { formatMessage, formatDate } = useIntl();

  const shareInfoElementRef = useRef<HTMLDivElement>(null);

  const doctor = useAppSelector(userModel.selectors.selectCurrentUser);
  const {
    visible,
    data: { invitationCreateDate, accessCode },
  } = useAppSelector((state) => state.modal.ProtectedSharing);
  const userLocale = useAppSelector(userModel.selectors.selectUserLocale);

  const [copied, setCopied] = useState(false);

  const doctorName = getName(
    doctor?.PersonalData?.FirstName,
    doctor?.PersonalData?.LastName,
    {
      userLocale,
    },
  );

  const handleClose = () => {
    dispatch(modalModel.actions.closeModal(ModalID.ProtectedSharing));
  };

  const copyToClipboard = async () => {
    const shareInfoElement = shareInfoElementRef.current;

    if (!shareInfoElement?.textContent || !shareInfoElement?.innerHTML) {
      return;
    }

    const shareInfoReachTextBlob = new Blob([shareInfoElement.innerHTML], {
      type: 'text/html',
    });

    const shareInfoPlainTextBlob = new Blob([shareInfoElement.textContent], {
      type: 'text/plain',
    });

    const shareInfoTextClipboardItem = new ClipboardItem({
      'text/html': shareInfoReachTextBlob,
      'text/plain': shareInfoPlainTextBlob,
    });

    try {
      await navigator.clipboard.write([shareInfoTextClipboardItem]);

      setCopied(true);

      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      setCopied(false);
    }
  };

  return (
    <Modal
      title={
        <FormattedMessage
          id="protectedSharing.title"
          defaultMessage="Protected sharing"
        />
      }
      isOpen={visible}
      onCancel={handleClose}
      shouldRenderCancelButton={false}
      className={styles.modal}
      containerClassName={styles.modalContainer}
      okButtonText={
        copied
          ? formatMessage({
              id: 'protectedSharing.copiedToClipboard',
              defaultMessage: 'Copied to clipboard',
            })
          : formatMessage({
              id: 'protectedSharing.copyToClipboard',
              defaultMessage: 'Copy to clipboard',
            })
      }
      okButtonProps={{
        type: 'button',
        size: 'medium',
        variant: 'secondary',
        onClick: copyToClipboard,
        className: copied ? styles.copied : '',
      }}
    >
      <span className="p2">
        <FormattedMessage
          id="protectedSharing.notificationForDoctor"
          defaultMessage="Please, copy and send this information to the doctor you are sharing to."
        />
      </span>
      <div ref={shareInfoElementRef} className={styles.sharingInfo}>
        <p className={cn(styles.info, 'p2')}>
          <FormattedMessage
            values={{
              doctorName,
            }}
            id="protectedSharing.info"
            defaultMessage="{doctorName} has shared to you a patient.
            You may get access to patient’s case using the access code:"
          />
          {'\n'}
        </p>

        <p className={cn(styles.accessCode, 'p2')}>
          <FormattedMessage
            id="protectedSharing.accessCode"
            defaultMessage="Access code:"
          />{' '}
          <b>{accessCode}</b>
          {'\n'}
        </p>

        <p className={cn(styles.date, 'p2')}>
          <FormattedMessage
            id="protectedSharing.date"
            defaultMessage="Sharing date:"
          />{' '}
          {invitationCreateDate && (
            <b>
              {formatDate(invitationCreateDate, {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              })}
            </b>
          )}
        </p>
      </div>
    </Modal>
  );
};
