import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ConnectError } from '@bufbuild/connect';

import { PATHS, UserBadRequestError } from '@/shared/config';
import { confirmEmailErrorMessages } from '@/shared/i18n';
import { safeJSONParse } from '@/shared/lib';

export const useConfirmEmailError = (): {
  callConfirmEmailError: (error: ConnectError) => void;
  inlineError: string;
} => {
  const navigate = useNavigate();

  const { formatMessage } = useIntl();

  const [inlineError, setInlineError] = useState<string>('');

  const callConfirmEmailError = (error: ConnectError) => {
    const parsedMessage = safeJSONParse(error.rawMessage);
    const errorCode = parsedMessage.code as UserBadRequestError;

    if (parsedMessage.text === UserBadRequestError.NON_RESENDABLE_AUTH_METHOD) {
      return navigate(PATHS.main);
    }

    if (
      errorCode === UserBadRequestError.CODE_RETRIES_LIMIT ||
      errorCode === UserBadRequestError.CODE_IS_EXPIRED
    ) {
      return navigate(PATHS.signUp);
    }

    if (errorCode in confirmEmailErrorMessages) {
      setInlineError(formatMessage(confirmEmailErrorMessages[errorCode]));
    }
  };

  return {
    callConfirmEmailError,
    inlineError,
  };
};
