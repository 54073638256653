// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_report.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { MedicalImageFeatures } from "./dto_common_image_medical_features_pb.js";
import { Report_IOXRay_GP } from "./dto_report_type_ioxray_gp_pb.js";
import { Report_Pano_GP } from "./dto_report_type_pano_gp_pb.js";
import { Report_CBCT_GP } from "./dto_report_type_cbct_gp_pb.js";
import { Report_CBCT_Endo, Report_CBCT_Implant, Report_CBCT_Molar, Report_CBCT_Ortho } from "./dto_report_type_specific_pb.js";
import { Report_CBCT_Segmentation } from "./dto_report_type_cbct_segmentation_pb.js";
import { Report_DentalPhoto_GP } from "./dto_report_type_photo_gp_pb.js";
import { Report_CBCT_IOS_Superimposition } from "./dto_report_type_cbct_ios_superimposition_pb.js";
import { Report_Studio_Implant } from "./dto_report_type_studio_implant_pb.js";
import { Report_Studio_Ortho } from "./dto_report_type_studio_ortho_pb.js";
import { Report_Pano_Bitewings } from "./dto_report_type_pano_bitewings_pb.js";
import { Report_IOS_Segmentation } from "./dto_report_type_ios_segmentation_pb.js";
import { ReportPermissions } from "./dto_report_permissions_pb.js";
import { Created, Deleted, Revision } from "./dto_base_pb.js";
import { ReportGenerationErrorCode } from "./dto_report_generation_error_codes_pb.js";
import { OrganizationJobPosition } from "./dto_organization_job_position_pb.js";

/**
 * @generated from enum com.diagnocat.model.ReportType
 */
export const ReportType = proto3.makeEnum(
  "com.diagnocat.model.ReportType",
  [
    {no: 0, name: "ReportType_InvalidValue"},
    {no: 1, name: "ReportType_CBCT_GP"},
    {no: 2, name: "ReportType_CBCT_Segmentation"},
    {no: 3, name: "ReportType_CBCT_OrthoSlides"},
    {no: 4, name: "ReportType_CBCT_Implant"},
    {no: 5, name: "ReportType_CBCT_Endo"},
    {no: 6, name: "ReportType_CBCT_Molar"},
    {no: 7, name: "ReportType_Pano_GP"},
    {no: 8, name: "ReportType_IOXRay_GP"},
    {no: 9, name: "ReportType_DentalPhoto_GP"},
    {no: 10, name: "ReportType_IOS_Segmentation"},
    {no: 11, name: "ReportType_CBCT_IOS_Superimposition"},
    {no: 12, name: "ReportType_StudioImplant"},
    {no: 14, name: "ReportType_Photo_Ortho"},
    {no: 15, name: "ReportType_StudioOrtho"},
    {no: 16, name: "ReportType_Pano_Bitewings"},
  ],
);

/**
 * @generated from enum com.diagnocat.model.ReportPrintSettingsDescriptionLang
 */
export const ReportPrintSettingsDescriptionLang = proto3.makeEnum(
  "com.diagnocat.model.ReportPrintSettingsDescriptionLang",
  [
    {no: 0, name: "ReportPrintSettings_DescriptionLang_Clinical"},
    {no: 1, name: "ReportPrintSettings_DescriptionLang_Radiological"},
  ],
);

/**
 * @generated from enum com.diagnocat.model.ReportPrintSettingsPrintType
 */
export const ReportPrintSettingsPrintType = proto3.makeEnum(
  "com.diagnocat.model.ReportPrintSettingsPrintType",
  [
    {no: 0, name: "ReportPrintSettings_PrintType_Full"},
    {no: 1, name: "ReportPrintSettings_PrintType_Motivation"},
  ],
);

/**
 * @generated from message com.diagnocat.model.Report
 */
export const Report = proto3.makeMessageType(
  "com.diagnocat.model.Report",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "PatientID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "SourceStudyIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "SourceReportIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "Type", kind: "enum", T: proto3.getEnumType(ReportType) },
    { no: 7, name: "LegacyConclusion", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "DisplayAssetID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "MedicalImageFeatures", kind: "message", T: MedicalImageFeatures },
    { no: 101, name: "Data_IOXRay_GP", kind: "message", T: Report_IOXRay_GP, oneof: "Specific" },
    { no: 102, name: "Data_Pano_GP", kind: "message", T: Report_Pano_GP, oneof: "Specific" },
    { no: 103, name: "Data_CBCT_GP", kind: "message", T: Report_CBCT_GP, oneof: "Specific" },
    { no: 104, name: "Data_CBCT_Endo", kind: "message", T: Report_CBCT_Endo, oneof: "Specific" },
    { no: 105, name: "Data_CBCT_Implant", kind: "message", T: Report_CBCT_Implant, oneof: "Specific" },
    { no: 106, name: "Data_CBCT_Molar", kind: "message", T: Report_CBCT_Molar, oneof: "Specific" },
    { no: 107, name: "Data_CBCT_Ortho", kind: "message", T: Report_CBCT_Ortho, oneof: "Specific" },
    { no: 108, name: "Data_CBCT_Segmentation", kind: "message", T: Report_CBCT_Segmentation, oneof: "Specific" },
    { no: 109, name: "Data_DentalPhoto", kind: "message", T: Report_DentalPhoto_GP, oneof: "Specific" },
    { no: 110, name: "Data_CBCT_IOS_Superimposition", kind: "message", T: Report_CBCT_IOS_Superimposition, oneof: "Specific" },
    { no: 111, name: "Data_Studio_Implant", kind: "message", T: Report_Studio_Implant, oneof: "Specific" },
    { no: 112, name: "Data_Studio_Ortho", kind: "message", T: Report_Studio_Ortho, oneof: "Specific" },
    { no: 113, name: "Data_Pano_Bitewings", kind: "message", T: Report_Pano_Bitewings, oneof: "Specific" },
    { no: 114, name: "Data_IOS_Segmentation", kind: "message", T: Report_IOS_Segmentation, oneof: "Specific" },
    { no: 201, name: "Settings", kind: "message", T: ReportSettings },
    { no: 202, name: "PrintSettings", kind: "message", T: ReportPrintSettings },
    { no: 203, name: "Signature", kind: "message", T: ReportSignature },
    { no: 301, name: "Status", kind: "message", T: ReportStatus },
    { no: 302, name: "PipelinesTasks", kind: "message", T: Report_PipelinesTask, repeated: true },
    { no: 10020, name: "YourPermissions", kind: "message", T: ReportPermissions },
    { no: 10030, name: "Created", kind: "message", T: Created },
    { no: 10040, name: "Revision", kind: "message", T: Revision },
    { no: 10050, name: "Deleted", kind: "message", T: Deleted },
    { no: 999999, name: "BlackURL", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.model.Report.PipelinesTask
 */
export const Report_PipelinesTask = proto3.makeMessageType(
  "com.diagnocat.model.Report.PipelinesTask",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Status", kind: "message", T: ReportStatus },
    { no: 3, name: "SourceStudiesAssetIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
  {localName: "Report_PipelinesTask"},
);

/**
 * @generated from message com.diagnocat.model.ReportStatus
 */
export const ReportStatus = proto3.makeMessageType(
  "com.diagnocat.model.ReportStatus",
  () => [
    { no: 1, name: "Pending", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "WaitingForUploadsToComplete", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "TakenByWorker", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "InProgress", kind: "message", T: ReportProgress },
    { no: 5, name: "Completed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "Canceled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "Failed", kind: "message", T: ReportFailed },
  ],
);

/**
 * @generated from message com.diagnocat.model.ReportProgress
 */
export const ReportProgress = proto3.makeMessageType(
  "com.diagnocat.model.ReportProgress",
  () => [
    { no: 1, name: "PrimaryPanoramaUploaded", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "SecondaryPanoramaUploaded", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "AllTeethLocalizationsCompleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "AllConditionsCompleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 13, name: "AllAssetsUploaded", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message com.diagnocat.model.ReportFailed
 */
export const ReportFailed = proto3.makeMessageType(
  "com.diagnocat.model.ReportFailed",
  () => [
    { no: 1, name: "Message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Code", kind: "enum", T: proto3.getEnumType(ReportGenerationErrorCode) },
  ],
);

/**
 * @generated from message com.diagnocat.model.ReportSignature
 */
export const ReportSignature = proto3.makeMessageType(
  "com.diagnocat.model.ReportSignature",
  () => [
    { no: 1, name: "Signed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "UserSignatures", kind: "message", T: ReportUserSignature, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.model.ReportUserSignature
 */
export const ReportUserSignature = proto3.makeMessageType(
  "com.diagnocat.model.ReportUserSignature",
  () => [
    { no: 1, name: "UserID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "SignedAt", kind: "message", T: Timestamp },
    { no: 3, name: "SignatureAssetID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "StampAssetID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "UserFirstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "UserLastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "JobPositions", kind: "enum", T: proto3.getEnumType(OrganizationJobPosition), repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.model.ReportSettings
 */
export const ReportSettings = proto3.makeMessageType(
  "com.diagnocat.model.ReportSettings",
  () => [
    { no: 1, name: "LowProbabilityMode", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "ShowProblemAreas", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "ShowPanoramaSplitInsteadOfSingle", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "ShowConditionDetails", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message com.diagnocat.model.ReportPrintSettings
 */
export const ReportPrintSettings = proto3.makeMessageType(
  "com.diagnocat.model.ReportPrintSettings",
  () => [
    { no: 1, name: "DescriptionLang", kind: "enum", T: proto3.getEnumType(ReportPrintSettingsDescriptionLang) },
    { no: 2, name: "PrintType", kind: "enum", T: proto3.getEnumType(ReportPrintSettingsPrintType) },
    { no: 3, name: "ShowDiagnoses", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "ShowDiagnosesProbabilities", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "ShowDentalChart", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "ShowSlices", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "ShowTopJaw", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "ShowBottomJaw", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "ShowBBoxes", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "ShowReferralRecommendations", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "ShowOrthodonticScreening", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "ShowStudyMetadata", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "ShowMasks", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 13, name: "BlackAndWhiteMode", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "IsInverted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

