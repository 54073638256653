import { FC, memo, useCallback } from 'react';
import cn from 'classnames';
import { useParams } from 'react-router';

import { MedicalImage } from '@/shared/ui';
import { useAppDispatch } from '@/shared/hooks';
import { MedicalImageInterface } from '@/shared/config';

import { ModalID, modalModel } from '@/entities/modal';

import styles from './UploadedSlicesList.module.scss';

type GroupMedicalImagesListProps = {
  className?: string;
  canUserDeleteSlices: boolean;
  medicalImages: MedicalImageInterface[];
  selectedMedicalImages: MedicalImageInterface[];
  toggleSelected: (medicalImage: MedicalImageInterface) => void;
};

export const UploadedSlicesList: FC<GroupMedicalImagesListProps> = memo(
  (props) => {
    const {
      className,
      selectedMedicalImages,
      medicalImages = [],
      canUserDeleteSlices,
      toggleSelected,
    } = props;

    const { toothID } = useParams();

    const dispatch = useAppDispatch();

    const handleOpenModal = useCallback(
      (medicalImage?: MedicalImageInterface) => {
        if (!medicalImage) return;

        dispatch(
          modalModel.actions.openModal({
            modalID: ModalID.ZoomedMedicalImage,
            data: {
              medicalImage,
              toothID: toothID ?? '',
              isSelectionEnabled: true,
            },
          }),
        );
      },
      [toothID],
    );

    const checkIsTheMedicalImageSelected = useCallback(
      (src: string) =>
        selectedMedicalImages?.some(
          (medicalImage) => medicalImage.src === src,
        ) ?? false,
      [selectedMedicalImages],
    );

    return (
      <div className={cn(styles.container, className)}>
        {medicalImages.map((medicalImage) => (
          <div key={medicalImage.src} className={styles.medicalImageWrapper}>
            <MedicalImage
              draggable
              key={medicalImage.src}
              medicalImage={medicalImage}
              onClick={() => handleOpenModal(medicalImage)}
              annotations={medicalImage.annotations}
              toggleSelected={toggleSelected}
              checkIsTheMedicalImageSelected={checkIsTheMedicalImageSelected}
              wc={medicalImage?.viewOptions?.wwwc?.wc}
              ww={medicalImage?.viewOptions?.wwwc?.ww}
              sharpness={medicalImage?.viewOptions?.sharpness}
              inverted={medicalImage?.viewOptions?.invert}
              order={medicalImage.order}
              canUserDeleteSlices={canUserDeleteSlices}
            />
          </div>
        ))}
      </div>
    );
  },
);
