import cn from 'classnames';
import { useIntl, FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';

import { Modal, Button } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { ReportType } from '@/shared/api/protocol-ts/model/dto_report_pb';
import { StudyType } from '@/shared/api/protocol-ts/model/dto_study_pb';
import {
  ReportRequest_InputType_CBCT_IOS_Superimposition,
  ReportRequest_InputType_StudioImplant,
  ReportRequest_InputType_StudioOrtho,
} from '@/shared/api/protocol-ts/model/dto_report_request_pb';

import { ModalID, modalModel } from '@/entities/modal';
import { patientModel } from '@/entities/patient';

import { useNewReport } from '@/features/addNewReport';
import { useRemoveStudy } from '@/features/removeStudy';

import { selectors } from '../../model';
import { StudyInfo } from '../StudyInfo/StudyInfo';
import { ReportsVersions } from '../ReportsVersions/ReportVersions';
import { ReportInfoRow } from '../ReportInfoRow/ReportInfoRow';
import { findStudyMetaInfoByType } from '../../lib';

import styles from './ReportInfoModal.module.scss';

export const ReportInfoModal = () => {
  const dispatch = useAppDispatch();

  const { patientID = '' } = useParams();

  const {
    visible,
    data: { reportID },
  } = useAppSelector(modalModel.selectors.selectReportInfoModal);
  const { currentReport, studiesMetaInfo, reportsInfo } = useAppSelector(
    selectors.selectReportInfo(reportID),
  );

  const canRequestReport = useAppSelector(
    patientModel.selectors.selectCanRequestReport(patientID),
  );

  const { formatMessage } = useIntl();
  const {
    requestIOXRayGPReport,
    requestPanoGPReport,
    requestCBCTGPReport,
    requestCBCTSegmentationReport,
    requestStudioOrthoReport,
    requestStudioImplantReport,
    requestCBCTIOSSuperimpositionReport,
    requestCBCTOrthoReport,
  } = useNewReport();
  const removeStudy = useRemoveStudy();

  const closeModal = () => {
    dispatch(modalModel.actions.closeModal(ModalID.ReportInfo));
  };

  // NOTE: When the last report is deleted, the ReportInfoModal should close
  const closeModalAndRemoveStudyHandler = async () => {
    if (reportsInfo.length === 1) {
      closeModal();

      // NOTE: A temporary solution until ReportType_IOS_Segmentation is fully implemented. The ReportType for all reportsInfo is the same
      if (
        reportsInfo[0].reportType === ReportType.ReportType_IOS_Segmentation
      ) {
        await removeStudy(studiesMetaInfo[0].id);
      }
    }
  };

  // TODO: [8|m] Make reusable hook for reordering reports
  const reorderReport = () => {
    const CBCTStudyID = studiesMetaInfo.find(
      findStudyMetaInfoByType(StudyType.StudyType_CBCT),
    )?.id;

    switch (currentReport?.Type) {
      case ReportType.ReportType_CBCT_GP: {
        if (CBCTStudyID) {
          requestCBCTGPReport(CBCTStudyID);
        }
        break;
      }
      case ReportType.ReportType_Pano_GP: {
        const studyId = studiesMetaInfo.find(
          findStudyMetaInfoByType(StudyType.StudyType_PanoramicXRay),
        )?.id;

        if (studyId) {
          requestPanoGPReport(studyId);
        }
        break;
      }
      case ReportType.ReportType_IOXRay_GP: {
        const studyId = studiesMetaInfo.find(
          findStudyMetaInfoByType(StudyType.StudyType_IntraoralXRay),
        )?.id;

        if (studyId) {
          requestIOXRayGPReport(studyId);
        }
        break;
      }
      case ReportType.ReportType_IOS_Segmentation: {
        if (CBCTStudyID) {
          requestCBCTSegmentationReport(CBCTStudyID);
        }
        break;
      }
      case ReportType.ReportType_CBCT_IOS_Superimposition: {
        const IOSMeshesStudyID = studiesMetaInfo.find(
          findStudyMetaInfoByType(StudyType.StudyType_IOS_Meshes),
        )?.id;
        const DentalPhotoStudyID = studiesMetaInfo.find(
          findStudyMetaInfoByType(StudyType.StudyType_DentalPhoto),
        )?.id;

        if (CBCTStudyID) {
          requestCBCTIOSSuperimpositionReport(
            new ReportRequest_InputType_CBCT_IOS_Superimposition({
              CBCTStudyID,
              STLStudyID: IOSMeshesStudyID as string,
              DentalPhotoStudyID: DentalPhotoStudyID as string,
            }),
          );
        }
        break;
      }
      case ReportType.ReportType_CBCT_OrthoSlides: {
        if (CBCTStudyID) {
          requestCBCTOrthoReport(CBCTStudyID);
        }

        break;
      }
      case ReportType.ReportType_StudioOrtho: {
        const IOSMeshesStudyID = studiesMetaInfo.find(
          findStudyMetaInfoByType(StudyType.StudyType_IOS_Meshes),
        )?.id;
        const DentalPhotoStudyID = studiesMetaInfo.find(
          findStudyMetaInfoByType(StudyType.StudyType_DentalPhoto),
        )?.id;
        if (CBCTStudyID) {
          requestStudioOrthoReport(
            new ReportRequest_InputType_StudioOrtho({
              CBCTStudyID,
              IOSMeshesStudyID: IOSMeshesStudyID as string,
              DentalPhotoStudyID: DentalPhotoStudyID as string,
            }),
          );
        }
        break;
      }
      case ReportType.ReportType_StudioImplant: {
        const IOSMeshesStudyID = studiesMetaInfo.find(
          findStudyMetaInfoByType(StudyType.StudyType_IOS_Meshes),
        )?.id;
        const DentalPhotoStudyID = studiesMetaInfo.find(
          findStudyMetaInfoByType(StudyType.StudyType_DentalPhoto),
        )?.id;
        if (CBCTStudyID) {
          requestStudioImplantReport(
            new ReportRequest_InputType_StudioImplant({
              CBCTStudyID,
              IOSMeshesStudyID: IOSMeshesStudyID as string,
              DentalPhotoStudyID: DentalPhotoStudyID as string,
            }),
          );
        }
        break;
      }

      case ReportType.ReportType_CBCT_Segmentation:
        {
          if (CBCTStudyID) {
            requestCBCTSegmentationReport(CBCTStudyID);
          }
        }
        break;
    }
    closeModal();
  };

  return (
    <Modal
      headerClassName={styles.header}
      bodyClassName={styles.body}
      isOpen={visible}
      onCancel={closeModal}
      title={formatMessage({
        id: 'reportInformationModal.title',
        defaultMessage: 'Report Information',
      })}
      hideFooter={
        currentReport?.Type === ReportType.ReportType_Pano_Bitewings ||
        !canRequestReport
      }
      footer={
        <footer className={styles.footer}>
          <Button size="tiny" variant="secondary" onClick={reorderReport}>
            <FormattedMessage id="global.reorder" defaultMessage="Reorder" />
          </Button>
        </footer>
      }
    >
      <main className={cn(styles.content)}>
        {studiesMetaInfo.map((studyMetaInfo) => (
          <StudyInfo key={studyMetaInfo.id} {...studyMetaInfo} />
        ))}
        <ReportsVersions>
          {reportsInfo.map((reportInfo) => (
            <ReportInfoRow
              key={reportInfo.reportID}
              onRemoveReport={closeModalAndRemoveStudyHandler}
              {...reportInfo}
            />
          ))}
        </ReportsVersions>
      </main>
    </Modal>
  );
};
