import { MIRenderImageInterface } from '@/shared/graphics/medicalImageRender/MedicalImageRender';

import { IOXrayImageInterface } from '@/entities/IOXRayImagesMatrix';

export const convertToMIRenderInterface = (
  imageItem: IOXrayImageInterface,
): MIRenderImageInterface => ({
  ImageID:
    imageItem?.imageMeta?.GeneratedAssetID ||
    imageItem?.imageMeta?.StudyAssetID,
  OrientationAngle: imageItem?.imageMeta?.OrientationAngle,
  MedicalImageFeatures: {
    ViewOptions: imageItem?.imageMeta?.MedicalImageFeatures?.ViewOptions,
  },
  originalSize: imageItem?.originalSize,
  meta: { imageMetaID: imageItem?.imageMeta?.ID },
});
