import { useIntl } from 'react-intl';

import { interestTypes } from '../config/i18n';

export const useGetInterestOptions = () => {
  const { formatMessage } = useIntl();

  const interestOptions = [
    {
      label: formatMessage(interestTypes.improveDiagnosticsOf2Dimages),
      value: 'Improve diagnostics of 2D images',
    },
    {
      label: formatMessage(interestTypes.improveDiagnosticsOfCBCTImages),
      value: 'Improve diagnostics of CBCT images',
    },
    {
      label: formatMessage(interestTypes.segmentationOfCBCTAndIOS),
      value: 'Segmentation of CBCT and IOS',
    },
    {
      label: formatMessage(
        interestTypes.superimpositionofIOSSTLAndSegmentedSTLfromCBCT,
      ),
      value: 'Superimposition of IOS (STL) and segmented STL from CBCT',
    },
    {
      label: formatMessage(
        interestTypes.improvePatientsCommunicationAndCaseAcceptance,
      ),
      value: 'Improve patients communication and case acceptance',
    },
    {
      label: formatMessage(interestTypes.savepractitionerandpatienttime),
      value: 'Save practitioner and patient time',
    },
  ];

  interestOptions.push({
    label: formatMessage({ id: 'global.other', defaultMessage: 'Other' }),
    value: 'Other',
  });

  return interestOptions;
};
