import { useCallback, useState } from 'react';

import { ReportPrintSettings } from '@/shared/api/protocol-ts/model/dto_report_pb';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';

import { reportsModel } from '@/entities/reports';
import { organizationModel } from '@/entities/organization';

type UsePrintSettings = {
  isLoading: boolean;
  printSettings: ReportPrintSettings;
  updatePrintSettings: (newPrintSettings: ReportPrintSettings) => void;
  showLowProbability: boolean;
};

export const usePrintSettings = (reportID: string): UsePrintSettings => {
  const dispatch = useAppDispatch();

  const report = useAppSelector(reportsModel.selectors.selectByID(reportID));

  const printSettings = report?.PrintSettings ?? ({} as ReportPrintSettings);

  const [isLoading, setLoading] = useState(false);

  const hideProbability = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_Hide_Probabilities,
    ),
  );

  const showLowProbability =
    !hideProbability && (report?.Settings?.LowProbabilityMode ?? false);

  const updatePrintSettings = useCallback(
    async (newPrintSettings: Partial<ReportPrintSettings>) => {
      setLoading(true);

      try {
        const { Report } = await dispatch(
          reportsModel.thunks.setReportPrintSettings({
            ReportID: reportID,
            PrintSettings: newPrintSettings as ReportPrintSettings,
          }),
        ).unwrap();

        if (Report) {
          dispatch(reportsModel.actions.setNewestOne(Report));
        }
      } finally {
        setLoading(false);
      }
    },
    [dispatch, reportID],
  );

  return {
    isLoading,
    printSettings,
    updatePrintSettings,
    showLowProbability,
  };
};
