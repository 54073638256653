import api from '@/shared/api/api';
import { NotifyFileUploadFinishedReq } from '@/shared/api/protocol-ts/api/core/svc_upload_pb';

export const notifyFileUploadFinishedUpcoming = async (
  request: NotifyFileUploadFinishedReq,
) => {
  const response = api.storage.notifyFileUploadFinished_Upcoming(request);
  for await (const { Result } of response) {
    if (Result.case === 'UploadSession') {
      return Result.value;
    }
  }
};
