import { RootState } from '@/app/model/store';

import { allowedToothConditionsAdapter } from './allowedToothConditionsSlice';

const allowedToothConditionsSelectors =
  allowedToothConditionsAdapter.getSelectors(
    (state: RootState) => state.allowedToothConditions,
  );

export const { selectIds, selectEntities, selectTotal, selectAll } =
  allowedToothConditionsSelectors;

export const selectById = (toothID: string) => (state: RootState) =>
  allowedToothConditionsSelectors.selectById(state, toothID);
