import { defineMessages } from 'react-intl';

import { ToastErrors } from './constants';

export const toastErrors = defineMessages<ToastErrors>({
  errorHeading: {
    id: 'error.heading',
    defaultMessage: 'Error',
  },
  wrongFormat: {
    id: 'error.heading.wrongFormat',
    defaultMessage: 'Wrong format',
  },
});
