import { FC } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import { CompactCondition, ConditionDecisionChange } from '../../config/types';
import { ChildConditionGroups } from '../../config/const';
import { globalConditionGroupText } from '../../config/i18n';
import { ConditionToggle } from '../ConditionToggle/ConditionToggle';
import { ConditionToggleList } from '../ConditionToggleList/ConditionToggleList';
import { groupCompactCondition } from '../../lib';

import styles from './ConditionChildGroup.module.scss';

type ConditionChildGroupType = {
  className?: string;
  data: CompactCondition[];
  onChange: ConditionDecisionChange;
  disabled?: boolean;
};

export const ConditionChildGroup: FC<ConditionChildGroupType> = (props) => {
  const { className, data, onChange, disabled } = props;

  const { formatMessage } = useIntl();

  if (data.length === 0) {
    return null;
  }

  const groups = groupCompactCondition(data);

  console.log({ data, groups, globalConditionGroupText });

  return (
    <div className={cn(styles.container, className)}>
      {(Object.keys(groups) as ChildConditionGroups[]).map(
        (childGroupCode: ChildConditionGroups) => (
          <div key={childGroupCode} className={styles.group}>
            <h4 className={cn('p2', styles.childGroupTitle)}>
              {childGroupCode in globalConditionGroupText &&
                formatMessage(globalConditionGroupText[childGroupCode])}
              :
            </h4>

            <ConditionToggleList>
              {groups[childGroupCode].map((childConditionInterface) => (
                <ConditionToggle
                  key={childConditionInterface.code}
                  data={childConditionInterface}
                  onChange={onChange}
                  disabled={disabled}
                  className={styles.marginBottomInGroupsConditions}
                />
              ))}
            </ConditionToggleList>
          </div>
        ),
      )}
    </div>
  );
};
