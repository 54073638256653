import { useAppSelector } from '@/shared/hooks';
import { getName } from '@/shared/lib';

import { DoctorOptionType } from '@/entities/doctors';
import { organizationModel } from '@/entities/organization';
import { getAvatarSrcByUserID } from '@/entities/assets';
import { userModel } from '@/entities/user';

import { isTreatingDoctor } from '../lib/isTreatingDoctor';

export const useOrganizationDoctorsOptions = (): DoctorOptionType[] => {
  const organizationDoctorsAsUserRole =
    useAppSelector(organizationModel.selectors.selectTreatingDoctors) || [];

  const user = useAppSelector(userModel.selectors.selectCurrentUser);
  const userLocale = useAppSelector(userModel.selectors.selectUserLocale);

  const currentUserAsDoctor = organizationDoctorsAsUserRole.find(
    (doctor) => doctor.UserID === user.ID,
  );

  const isCurrentUserTreatingDoctor = isTreatingDoctor(
    currentUserAsDoctor?.Roles || [],
  );

  if (isCurrentUserTreatingDoctor) {
    const avatarSrc = getAvatarSrcByUserID(
      currentUserAsDoctor?.UserID as string,
      'thumbnail',
    );
    const doctorName = getName(
      currentUserAsDoctor?.PersonalData?.FirstName,
      currentUserAsDoctor?.PersonalData?.LastName,
      {
        userLocale,
      },
    );

    return [
      {
        label: doctorName,
        value: currentUserAsDoctor?.UserID as string,
        avatarSrc,
      },
    ];
  }

  return organizationDoctorsAsUserRole.map((doctor) => {
    const avatarSrc = getAvatarSrcByUserID(doctor?.UserID, 'thumbnail');
    const doctorName = getName(
      doctor?.PersonalData?.FirstName,
      doctor?.PersonalData?.LastName,
      {
        userLocale,
      },
    );

    return {
      label: doctorName,
      value: doctor?.UserID,
      avatarSrc,
    };
  });
};
