import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { Skeleton } from '@/shared/ui';
import { scrollToElementByID } from '@/shared/lib';
import { MedicalImageRender } from '@/shared/graphics/medicalImageRender/MedicalImageRender';

import { toothModel } from '@/entities/tooth';
import { FMXNavigationMode, reportsModel } from '@/entities/reports';
import { IOXRayImagesInterfaceModel } from '@/entities/IOXRayImagesMatrix';

import { ToothCard } from '@/widgets/ToothCard';

import styles from './PanowingsToothCardList.module.scss';

type PanowingsToothCardListProps = {
  reportID: string;
  patientID: string;
};

export const PanowingsToothCardList = (props: PanowingsToothCardListProps) => {
  // TODO: use currentReport and currentPatient data from store to avoid prop drilling
  const { reportID, patientID } = props;

  const dispatch = useAppDispatch();

  const allTeethIDs = useAppSelector(
    toothModel.selectors.selectReportAllIds(reportID),
  );

  const teethLoadingState = useAppSelector(toothModel.selectors.selectLoading);

  const navigationMode = useAppSelector(
    reportsModel.selectors.selectNavigationMode,
  );

  const isToothChartMode = navigationMode === FMXNavigationMode.ToothChart;

  const teethIDsForCrop = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectTeethIDsForCrop(reportID),
  );

  const displayedTeethIDs = isToothChartMode ? allTeethIDs : teethIDsForCrop;

  const handleToothClick = useCallback(
    (id: string) => {
      dispatch(reportsModel.actions.setActiveToothID(id));
      scrollToElementByID(id, 'smooth', 'start');
      MedicalImageRender.layoutModes.crop(id);
    },
    [dispatch],
  );

  if (teethLoadingState !== 'succeeded') {
    return <Skeleton />;
  }

  return (
    <>
      {displayedTeethIDs.map((toothID) => (
        <ToothCard
          key={toothID}
          id={toothID}
          className={styles.toothCard}
          patientID={patientID}
          reportID={reportID}
          showSlices={false}
          handleCardClick={handleToothClick}
          isToothChartMode={isToothChartMode}
        />
      ))}
    </>
  );
};
