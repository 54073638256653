import { createSelector } from '@reduxjs/toolkit';

import { getName } from '@/shared/lib';

import { RootState } from '@/app/model/store';

import { patientAdapter } from './patientSlice';

export const patientSelectors = patientAdapter.getSelectors(
  (state: RootState) => state.patient,
);

export const { selectAll, selectById, selectTotal, selectEntities, selectIds } =
  patientSelectors;

export const selectPatientByID = (patientID: string) => (state: RootState) =>
  selectById(state, patientID);

const selectSharedWithMeCount = (state: RootState) =>
  state.patient.sharedWithMeCount;
const sharedByMeCount = (state: RootState) => state.patient.sharedByMeCount;
const totalOrganizationPatientCount = (state: RootState) =>
  state.patient.totalOrganizationPatientCount;

export const selectCounters = createSelector(
  selectSharedWithMeCount,
  sharedByMeCount,
  totalOrganizationPatientCount,
  (sharedWithMeCount, sharedByMeCount, totalOrganizationPatientCount) => ({
    sharedWithMeCount,
    sharedByMeCount,
    totalOrganizationPatientCount,
  }),
);

export const selectAllPatientDoctors =
  (patientID: string) => (state: RootState) =>
    patientSelectors.selectById(state, patientID)?.Doctors;

export const selectPatientDoctorsIDs =
  (patientID: string) => (state: RootState) =>
    patientSelectors
      .selectById(state, patientID)
      ?.Doctors.map((patientDoctor) => patientDoctor.UserID);

export const selectTreatingDoctors =
  (patientID: string) => (state: RootState) =>
    patientSelectors
      .selectById(state, patientID)
      // ?.Doctors.filter((doctor) => doctor.TeamMember !== undefined);
      ?.Doctors.filter(
        (doctor) =>
          doctor.Membership.case === 'TeamMember' && doctor.Membership.value,
      );

export const selectTreatingDoctorsIDs =
  (patientID: string) => (state: RootState) =>
    patientSelectors
      .selectById(state, patientID)
      ?.Doctors.filter(
        (doctor) =>
          doctor.Membership.case === 'TeamMember' && doctor.Membership.value,
      )
      .map((doctor) => doctor.UserID);

export const selectSharedDoctors = (patientID: string) => (state: RootState) =>
  patientSelectors
    .selectById(state, patientID)
    ?.Doctors.filter(
      (doctor) =>
        doctor.Membership.case === 'Sharing' && doctor.Membership.value,
    );

// TODO: [1/l] Replace instances where patient is retrieved from the store solely for the purpose of obtaining the name (getPatientFullName) with selectPatientFullName
export const selectPatientFullName = (patientID: string) =>
  createSelector(
    (state: RootState) => patientSelectors.selectById(state, patientID),
    (state: RootState) => state.user.locale,
    (patient, userLocale) =>
      getName(
        patient?.PersonalData?.FirstName,
        patient?.PersonalData?.LastName,
        { userLocale },
      ),
  );

export const selectCanRequestReport = (patientID: string) =>
  createSelector(
    selectPatientByID(patientID),
    (patient) => patient?.YourPermissions?.CanRequestReport,
  );

export const selectLoading = (state: RootState) => state.patient.loading;

export const selectPatientsLastReportAssetIDs = (state: RootState) =>
  state.patient.lastReportAssetIDs;
