import { includes, map, partialRight } from 'ramda';

import { Condition } from '@/shared/api/protocol-ts/model/dto_report_condition_pb';
import { ToothStatus } from '@/shared/config';
import { ConditionCode } from '@/shared/api/protocol-ts/model/dto_report_condition_codes_pb';
import { Decision } from '@/shared/api/protocol-ts/model/dto_report_common_pb';
import { shouldConditionItemBeShown } from '@/shared/embeddedLibs/conditionsAndMasks';

import { getConditionStatus } from './getConditionStatus';

export const isToothMissing = (conditions: Condition[]) =>
  conditions.find(
    (condition) =>
      condition.Code === ConditionCode.Missing &&
      condition.Certainty?.EngineDecision === Decision.PositiveDecision,
  );

type ToothStatusConfig = {
  showLowProbability?: boolean;
  isFDA?: boolean;
  isFDANonAided?: boolean;
};

// TODO: It is necessary to bring the function to one type with entities/logicalCondition/utils.ts
export const getToothStatus = (
  toothConditions: Condition[],
  config?: ToothStatusConfig,
) => {
  const {
    showLowProbability = false,
    isFDA = false,
    isFDANonAided = false,
  } = config || {};
  const filteredConditions = toothConditions
    .filter((condition) =>
      shouldConditionItemBeShown(showLowProbability)(condition),
    )
    .filter((condition) => {
      if (condition.Code === ConditionCode.PeriodontalBoneLoss) {
        return false;
      }

      if (!isFDA) {
        return true;
      }

      const allowedCodes = [ConditionCode.Missing, ConditionCode.CodeTooth];

      if (!isFDANonAided) {
        allowedCodes.push(ConditionCode.PeriapicalRadiolucency);
      }

      return allowedCodes.includes(condition.Code);
    });

  const allToothStatusByConditions = map(
    partialRight(getConditionStatus, [isToothMissing]),
    filteredConditions,
  );

  if (isToothMissing(filteredConditions)) {
    return ToothStatus.missing;
  }

  if (includes(ToothStatus.lowProbability, allToothStatusByConditions)) {
    return ToothStatus.lowProbability;
  }

  if (includes(ToothStatus.unhealthy, allToothStatusByConditions)) {
    return ToothStatus.unhealthy;
  }

  if (includes(ToothStatus.treated, allToothStatusByConditions)) {
    return ToothStatus.treated;
  }

  return ToothStatus.healthy;
};
