import { useState } from 'react';

import { useAppDispatch } from '@/shared/hooks';

import { reportsModel, useCheckReportSignature } from '@/entities/reports';
import { toothModel } from '@/entities/tooth';
import { conditionModel } from '@/entities/condition';

export const useResetTooth = (toothID: string) => {
  const [isResetToothLoading, setResetToothLoading] = useState(false);

  const dispatch = useAppDispatch();

  const { checkReportSignature } = useCheckReportSignature();

  const resetTooth = () => {
    checkReportSignature({
      toothID,
      onSignatureChecked: async () => {
        setResetToothLoading(true);

        try {
          const { Teeth, Conditions } = await dispatch(
            reportsModel.thunks.resetReportTooth({
              ToothID: toothID,
            }),
          ).unwrap();

          const updatedTooth = Teeth.find(
            (changedTooth) => changedTooth.ID === toothID,
          );

          if (updatedTooth) {
            dispatch(toothModel.actions.setNewestOne(updatedTooth));
          }

          dispatch(conditionModel.actions.setNewestMany(Conditions));
        } finally {
          setResetToothLoading(false);
        }
      },
    });
  };

  return { resetTooth, isResetToothLoading };
};
