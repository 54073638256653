import { useMemo } from 'react';

import { useAppSelector } from '@/shared/hooks';
import { ConditionCode } from '@/shared/api/protocol-ts/model/dto_report_condition_codes_pb';

import { conditionModel } from '@/entities/condition';

import { makePathologyGroup } from '../lib/makePathologyGroup';
import {
  TOOTH_PAGE_GROUPS,
  TOOTH_PAGE_PATHOLOGY_GROUPS,
} from '../config/constants';
import { Group, PathologyGroup } from '../config/medicalImages.types';
import { makeGroupByOrientation } from '../lib/makeGroupByOrientation';
import * as assetsModel from '../model';

// TODO: [8:m] need to refactor all groups and perio masks logic
export const useGetMedicalImageGroups = (
  toothID: string,
): Array<Group | PathologyGroup> => {
  const toothAssets = useAppSelector(
    assetsModel.selectors.selectByToothID(toothID),
  );

  const allSlicesMasks = useAppSelector(
    conditionModel.selectors.selectSlicesMasks(toothID, [
      ConditionCode.PeriapicalRadiolucency,
    ]),
  );

  const pathologyGroups = useMemo(
    () =>
      TOOTH_PAGE_PATHOLOGY_GROUPS.map((group) =>
        makePathologyGroup(group, toothAssets, allSlicesMasks),
      ),
    [toothAssets],
  );

  const groups = useMemo(
    () =>
      TOOTH_PAGE_GROUPS.map((group) =>
        makeGroupByOrientation(group, toothAssets),
      ),
    [toothAssets],
  );

  return [...groups, ...pathologyGroups];
};
