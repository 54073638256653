import { createSelector } from '@reduxjs/toolkit';

import { convertConditionColorToHEX } from '@/shared/lib';
import { conditionIsUncertain } from '@/shared/embeddedLibs/conditionsAndMasks';

import { reportsModel } from '@/entities/reports';
import { conditionModel, getConditionCodeColor } from '@/entities/condition';

type RenderConditionBbox = {
  min: { x: number; y: number };
  max: { x: number; y: number };
  color: string;
  width: number;
};

export const selectHoveredBBoxList = createSelector(
  conditionModel.selectors.selectHoveredConditions,
  reportsModel.selectors.selectLowProbabilityMode,
  (hoveredConditions, showLowProbability) => {
    if (hoveredConditions.length === 0) {
      return undefined;
    }
    const code = hoveredConditions[0].Code;
    const localizations = hoveredConditions.flatMap(
      (condition) => condition.Localizations,
    );

    const conditionByCertainty = hoveredConditions.sort(
      (a, b) => (b.Certainty?.ModelScore ?? 0) - (a.Certainty?.ModelScore ?? 0),
    )?.[0];

    const isUncertain = conditionIsUncertain(conditionByCertainty.Certainty);

    const BBoxColor = getConditionCodeColor(
      code,
      isUncertain,
      showLowProbability,
    );

    const hoveredConditionBBoxes = localizations.reduce(
      (accum, localization) => {
        const assetID = localization.TargetAssetID;
        const BBox = localization.BBox;

        const MinBBoxSize = 20;

        const isBBoxTooSmall =
          BBox?.X?.Max &&
          BBox?.X?.Min &&
          BBox?.Y?.Max &&
          BBox?.Y?.Min &&
          BBox?.X?.Max - BBox?.X?.Min < MinBBoxSize &&
          BBox?.Y?.Max - BBox?.Y?.Min < MinBBoxSize;

        const PADDING_BBOX = isBBoxTooSmall ? 10 : 0;

        const transformedBbox = {
          max: {
            x: (BBox?.X?.Max ?? 0) + PADDING_BBOX,
            y: (BBox?.Y?.Max ?? 0) + PADDING_BBOX,
          },
          min: {
            x: (BBox?.X?.Min ?? 0) - PADDING_BBOX,
            y: (BBox?.Y?.Min ?? 0) - PADDING_BBOX,
          },
          color: convertConditionColorToHEX(BBoxColor),
          width: 2,
        } as RenderConditionBbox;

        return {
          ...accum,
          [localization.TargetAssetID]: {
            imageID: assetID,
            boxes: accum[assetID]
              ? [...accum[assetID].boxes, transformedBbox]
              : [transformedBbox],
          },
        };
      },
      {} as Record<string, { imageID: string; boxes: RenderConditionBbox[] }>,
    );

    const hoveredConditionBBoxesList = Object.values(hoveredConditionBBoxes);

    return hoveredConditionBBoxesList;
  },
);
