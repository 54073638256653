import { defineMessages } from 'react-intl';

import { ProductStateStatus } from '@/shared/api/protocol-ts/api/billing_new/dto_product_new_pb';

import { ProductStatus } from '@/widgets/CurrentSubscription/config/types';

export const subscriptionStatusDefaultMessages = defineMessages<ProductStatus>({
  [ProductStateStatus.ProductStateStatusActive]: {
    id: 'subscription.subscriptionStatus.active',
    defaultMessage: 'Active',
  },
  [ProductStateStatus.ProductStateStatusDeleted]: {
    id: 'subscription.subscriptionStatus.blocked',
    defaultMessage: 'Blocked',
  },
  [ProductStateStatus.ProductStateStatusLifeTimeIsOver]: {
    id: 'subscription.subscriptionStatus.expired',
    defaultMessage: 'Expired',
  },
  [ProductStateStatus.ProductStateStatusAwaitingActivation]: {
    id: 'subscription.subscriptionStatus.awaitingActivation',
    defaultMessage: 'Awaiting activation',
  },
});

export const packageStatusDefaultMessages = defineMessages<ProductStatus>({
  [ProductStateStatus.ProductStateStatusActive]: {
    id: 'subscription.packageStatus.active',
    defaultMessage: 'Active',
  },
  [ProductStateStatus.ProductStateStatusDeleted]: {
    id: 'subscription.packageStatus.blocked',
    defaultMessage: 'Blocked',
  },
  [ProductStateStatus.ProductStateStatusLifeTimeIsOver]: {
    id: 'subscription.packageStatus.expired',
    defaultMessage: 'Expired',
  },
  [ProductStateStatus.ProductStateStatusAwaitingActivation]: {
    id: 'subscription.packageStatus.awaitingActivation',
    defaultMessage: 'Awaiting activation',
  },
});
