import { FC, ReactNode, useEffect, useLayoutEffect, useRef } from 'react';
import cn from 'classnames';
import { useParams } from 'react-router';

import { CORS_POLICY, RenderPreviewSettings } from '@/shared/config';
import { useAppSelector } from '@/shared/hooks';
import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';
import { MedicalImageRender } from '@/shared/graphics/medicalImageRender/MedicalImageRender';
import { MedicalImageViewOptions } from '@/shared/api/protocol-ts/model/dto_common_image_view_options_pb';

import { reportsModel } from '@/entities/reports';
import {
  IOXRayImagesInterfaceModel,
  IOXrayImageInterface,
} from '@/entities/IOXRayImagesMatrix';
import { toothModel } from '@/entities/tooth';
import { organizationModel } from '@/entities/organization';

import { hoveredConditionBBoxesModel } from '@/features/hoveredConditionBBoxes';
import { maskFiltersModel } from '@/features/renderMasks';
import { convertToMIRenderInterface } from '@/features/MIRenderInterface';

import styles from './PanoReportRender.module.scss';

type PanoReportRenderProps = {
  className?: string;
  children?: ReactNode;
  previewSettings?: RenderPreviewSettings;
};

export const PanoReportRender: FC<PanoReportRenderProps> = (props) => {
  const { className, children, previewSettings } = props;

  const { reportID } = useParams<{ reportID: string }>();

  const isPreviewMode = previewSettings?.isPreview;

  const mainViewRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const readyForRender = useAppSelector(
    reportsModel.selectors.selectReportReadyForRender,
  );
  const panoAssetID = useAppSelector(
    reportsModel.selectors.selectCurrentReportPanoAssetID,
  );

  const panoImageInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectPanoImageInterfaceByReportID(
      reportID as string,
    ),
  ) as IOXrayImageInterface;

  const panoMIRenderInterface = convertToMIRenderInterface(panoImageInterface);

  const reportViewOptions = useAppSelector(
    reportsModel.selectors.selectCurrentReportViewOptions,
  );

  // Hovered condition BBoxes
  const hoveredBBoxes = useAppSelector(
    hoveredConditionBBoxesModel.selectors.selectHoveredBBoxList,
  );

  // Masks
  const masks2DRenderData = useAppSelector(
    maskFiltersModel.selectors.selectReportActiveMasks,
  );
  const hideMasksAndBBoxes = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_Hide_ConditionsMasks,
    ),
  );
  const activeMaskFilters = useAppSelector(
    maskFiltersModel.selectors.selectActiveFilters,
  );

  useLayoutEffect(() => {
    MedicalImageRender.setViewRef(mainViewRef);
    MedicalImageRender.setCanvasRef(canvasRef);
  }, []);

  const teeth = useAppSelector(toothModel.selectors.selectAll);

  useEffect(() => {
    const shouldRunRender =
      !!reportID && !!panoMIRenderInterface && readyForRender && teeth.length;

    if (shouldRunRender) {
      MedicalImageRender.setCredentials(CORS_POLICY);

      const isRenderStartCorrect = MedicalImageRender.run(
        reportID,
        [[[panoMIRenderInterface]]],
        reportViewOptions as MedicalImageViewOptions,
        { [panoMIRenderInterface.ImageID]: { viewportType: 'pano' } },
      );

      if (!isRenderStartCorrect) {
        return;
      }

      if (isPreviewMode) {
        // WARN: this is temporary solution to show masks in preview mode. It will be fixed later.
        // MedicalImageRender.layoutModes.fullScreenMatrix();
        MedicalImageRender.activateMode('printMode');
      } else {
        MedicalImageRender.setTeeth(teeth);
        MedicalImageRender.layoutModes.focus(panoMIRenderInterface.ImageID);
      }
    }
  }, [reportID, panoAssetID, readyForRender, isPreviewMode, teeth]);

  // BBoxes render
  useEffect(() => {
    if (MedicalImageRender.isRunning() && !hideMasksAndBBoxes) {
      if (hoveredBBoxes) {
        MedicalImageRender.deleteConditionBoxes();

        MedicalImageRender.addConditionBoxes(hoveredBBoxes);
      } else {
        MedicalImageRender.deleteConditionBoxes();
      }
    }
  }, [hoveredBBoxes]);

  // Render masks
  useEffect(() => {
    if (isPreviewMode) {
      if (
        MedicalImageRender.isRunning() &&
        readyForRender &&
        !hideMasksAndBBoxes
      ) {
        MedicalImageRender.deleteMasks();
        if (masks2DRenderData.length > 0 && previewSettings.showMasks) {
          MedicalImageRender.addMasks(masks2DRenderData);
          MedicalImageRender.showMasks(activeMaskFilters);
        }
      }
    } else {
      if (
        MedicalImageRender.isRunning() &&
        readyForRender &&
        !hideMasksAndBBoxes
      ) {
        MedicalImageRender.deleteMasks();
        if (masks2DRenderData.length > 0) {
          MedicalImageRender.addMasks(masks2DRenderData);
          MedicalImageRender.showMasks(activeMaskFilters);
        }
      }
    }
  }, [
    masks2DRenderData.length, // WARN: there is no need to check array objects. check length is enough
    readyForRender,
    hideMasksAndBBoxes,
    activeMaskFilters,
    isPreviewMode,
    previewSettings?.showMasks,
  ]);

  // Preview settings
  useEffect(() => {
    if (
      readyForRender &&
      MedicalImageRender.isRunning() &&
      previewSettings?.isPreview
    ) {
      MedicalImageRender.activateMode('printMode');

      if (previewSettings?.isInverted) {
        MedicalImageRender.invertColors();
      } else if (!previewSettings?.isInverted) {
        MedicalImageRender.straightColors();
      }
    }
  }, [readyForRender, previewSettings?.isPreview, previewSettings?.isInverted]);

  useEffect(
    () => () => {
      MedicalImageRender.shutdown();
    },
    [],
  );

  return (
    <div
      ref={mainViewRef}
      className={cn(
        styles.container,
        previewSettings?.isPreview ? styles.preview : '',
        className,
      )}
      id="report_render"
    >
      {children}
      <canvas ref={canvasRef} className={styles.canvas} />
    </div>
  );
};
