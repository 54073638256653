import React, { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { Tooth } from '@/shared/api/protocol-ts/model/dto_report_tooth_pb';
import { MedicalImage } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';
import { DEFAULT_MEDICAL_IMAGE_HEIGHT, ToothStatus } from '@/shared/config';
import {
  Report,
  ReportType,
} from '@/shared/api/protocol-ts/model/dto_report_pb';
import { RCCropImage } from '@/shared/graphics/RenderComponents/RCCropImage/RCCropImage';
import { RCContainer } from '@/shared/graphics/RenderComponents/RCContainer/RCContainer';
import { ObjectFitContain } from '@/shared/graphics/RenderComponents/ObjectFitContain';
import { AnnotationsLayer } from '@/shared/graphics/RenderComponents/AnnotationsLayer/AnnotationsLayer';
import { AbsoluteBlock } from '@/shared/graphics/RenderComponents/AbsoluteBlock';
import { BWIcons, ColoredIcons } from '@/shared/assets/images/ToothStatusIcon';
import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';

import {
  getDisplayToothNumber,
  isMissingThirdMolarTooth,
} from '@/entities/tooth';
import {
  getImageSrc,
  makeAnnotations,
  makeImageViewOptions,
  MedicalImagesGroups,
  useGetSelectedMedicalImages,
} from '@/entities/assets';
import { ConditionColor } from '@/entities/condition';
import { transformBBoxToCrop } from '@/entities/tooth/lib/transformBBoxToCropp';
import { organizationModel } from '@/entities/organization';
import { reportsModel } from '@/entities/reports';
import { composedModel } from '@/entities/composed';
import { conditionText } from '@/entities/condition/config/i18n';

import { ContextRequest } from 'graphics';

import styles from './PreviewToothCard.module.scss';

type PreviewToothCardProps = {
  tooth: Tooth;
  showProbability: boolean;
  showConditions: boolean;
  showMedicalImages: boolean;
  inverted: boolean;
  blackAndWhiteMode: boolean;
  isDICOM: boolean;
  report?: Report;
};

const colorStyle: Record<ConditionColor, string> = {
  yellow: styles.colorYellow,
  red: styles.colorRed,
  purple: styles.colorPurple,
  white: styles.colorWhite,
};

export const PreviewToothCard: FC<PreviewToothCardProps> = (props) => {
  const {
    tooth,
    showProbability,
    showConditions,
    showMedicalImages,
    inverted,
    blackAndWhiteMode,
    report,
    isDICOM,
  } = props;

  const { formatMessage } = useIntl();
  const hidePerioMasksAndSlices = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_Hide_PerioMasksAndSlices,
    ),
  );
  const hideCariesSigns = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_Hide_CariesSigns,
    ),
  );

  const dentalNotationFormat = useAppSelector(
    organizationModel.selectors.selectDentalNotationFormat,
  );

  const assetsOrientationAngle = useAppSelector(
    reportsModel.selectors.selectAssetsOrientationAngle,
  );

  const reportType = report?.Type;

  const showToothCard = !!tooth.Comment || showConditions || showMedicalImages;

  const conditionItems = useAppSelector(
    composedModel.selectors.selectPreviewCompactToothConditions(tooth.ID),
  );

  const medicalImages = useGetSelectedMedicalImages(tooth.ID);

  const toothStatus = useAppSelector(
    composedModel.selectors.selectToothStatus(tooth.ID, true),
  );

  const isMissingThirdMolar = isMissingThirdMolarTooth(
    toothStatus,
    tooth.Numeration?.ISO ?? 0,
  );

  const shouldShowCroppedImages =
    (reportType === ReportType.ReportType_IOXRay_GP ||
      reportType === ReportType.ReportType_Pano_GP ||
      reportType === ReportType.ReportType_Pano_Bitewings) &&
    showMedicalImages;

  const displayToothNumber = getDisplayToothNumber(
    tooth?.Numeration?.ISO ?? 0,
    dentalNotationFormat,
  ).toString();

  const cropImageKind = isDICOM ? 'dicom' : 'raster';

  if (!showToothCard) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div
        className={cn(
          styles.header,
          toothStatus === ToothStatus.missing && styles.missingTooth,
        )}
      >
        <div className={styles.toothNumberWrapper}>
          {!isMissingThirdMolar && (
            <img
              className={styles.blackAndWhiteIcon}
              src={
                blackAndWhiteMode
                  ? BWIcons[toothStatus]
                  : ColoredIcons[toothStatus]
              }
              alt={toothStatus}
            />
          )}

          <h4
            className={cn(
              'h4',
              !blackAndWhiteMode
                ? !isMissingThirdMolar && `${toothStatus}ToothNumber`
                : styles.blackAndWhiteToothNumber,
            )}
          >
            <FormattedMessage
              id="toothCard.title"
              defaultMessage="Tooth {number}"
              values={{
                number: displayToothNumber,
              }}
            />
          </h4>
        </div>

        {showConditions && (
          <div className={styles.toothConditionContainer}>
            {conditionItems.map((item, index) => {
              const selectedChildConditions = item.childConditionInterfaces;

              const showTags = !!selectedChildConditions?.length;

              const isLastCondition = index === conditionItems.length - 1;
              const withChildConditions = selectedChildConditions?.length;

              const childConditionCloseComma = isLastCondition ? `)  ` : `),  `;

              return (
                <React.Fragment key={item.id}>
                  <span
                    className={cn(
                      styles.toothCondition,
                      !blackAndWhiteMode &&
                        !isMissingThirdMolar &&
                        colorStyle[item.color],
                      'p1',
                    )}
                  >
                    <span className={styles.toothConditionText}>
                      {item.code in conditionText &&
                        formatMessage(conditionText[item.code])}
                      {showProbability && (
                        <sup className={styles.percent}>
                          {item.probabilityText}
                        </sup>
                      )}
                      {!isLastCondition && !withChildConditions && ', '}
                    </span>
                  </span>

                  {showTags &&
                    selectedChildConditions.map((tag, tagIndex) => (
                      <span
                        key={tag.id}
                        className={cn(
                          styles.toothCondition,
                          !blackAndWhiteMode &&
                            !isMissingThirdMolar &&
                            colorStyle[tag.color],
                          'p2',
                          styles.childConditions,
                        )}
                      >
                        {tagIndex === 0 ? `  (` : ``}
                        <span className={styles.toothConditionText}>
                          {tag.code in conditionText &&
                            formatMessage(conditionText[tag.code])}
                        </span>

                        {showProbability && (
                          <sup className={styles.percent}>
                            {tag.probabilityText}
                          </sup>
                        )}
                        {tagIndex !== selectedChildConditions.length - 1
                          ? ',  '
                          : childConditionCloseComma}
                      </span>
                    ))}
                </React.Fragment>
              );
            })}
          </div>
        )}
      </div>

      {shouldShowCroppedImages && (
        <div className={styles.medicalImages}>
          {tooth.Detections.map(({ Localizations }, index) => {
            if (!Localizations) {
              return null;
            }

            const { BBox, TargetAssetID } = Localizations;

            const localizationOrientationAngle =
              assetsOrientationAngle?.[TargetAssetID] ?? 0;

            const crop = transformBBoxToCrop(BBox);
            const cropImageSrc: ContextRequest = {
              url: getImageSrc(TargetAssetID, 'original'),
              kind: cropImageKind,
            };

            const viewOptions = makeImageViewOptions(
              Localizations?.CropMedicalImageFeatures?.ViewOptions,
            );

            const annotations = makeAnnotations(
              Localizations?.CropMedicalImageFeatures?.Annotations?.Layers,
            );

            return (
              <RCContainer
                style={{
                  height: DEFAULT_MEDICAL_IMAGE_HEIGHT,
                }}
                // TODO: fix this after correct IDS will be added
                key={Localizations.ID + index}
              >
                <RCCropImage
                  style={{
                    height: DEFAULT_MEDICAL_IMAGE_HEIGHT,
                    boxShadow: '0 0 0 1px rgba(var(--black-rgb), .2)',
                    borderRadius: '8px',
                  }}
                  angle={localizationOrientationAngle}
                  uniforms={{
                    invert: inverted,
                    ww: viewOptions.wwwc.ww,
                    wc: viewOptions.wwwc.wc,
                    sharpness: viewOptions.sharpness,
                  }}
                  crop={crop}
                  src={cropImageSrc}
                />
                <AbsoluteBlock style={{ top: 0 }}>
                  <ObjectFitContain objectWidth={crop.w} objectHeight={crop.h}>
                    <AnnotationsLayer
                      pixelSpacing={[1, 1]}
                      annotations={annotations}
                      annotationKind="eraser"
                      setAnnotations={() => {}}
                      editable={false}
                    />
                  </ObjectFitContain>
                </AbsoluteBlock>
              </RCContainer>
            );
          })}
        </div>
      )}
      {showMedicalImages && (
        <div className={styles.medicalImages}>
          {medicalImages
            ?.filter(({ groupName }) => {
              if (groupName === MedicalImagesGroups.Caries) {
                return !hideCariesSigns;
              }

              if (groupName === MedicalImagesGroups.PeriapicalLesion) {
                return !hidePerioMasksAndSlices;
              }

              return true;
            })
            ?.map((medicalImage) => {
              const { annotations, viewOptions } = medicalImage;

              return (
                <MedicalImage
                  key={medicalImage.src}
                  medicalImage={medicalImage}
                  annotations={annotations}
                  inverted={inverted}
                  sharpness={viewOptions?.sharpness}
                  ww={viewOptions?.wwwc?.ww}
                  wc={viewOptions?.wwwc?.wc}
                  className={styles.notHovered}
                />
              );
            })}
        </div>
      )}

      {tooth?.Comment && <p className="p2">{tooth.Comment}</p>}
    </div>
  );
};
