import { FloatPoint2D } from '../api/protocol-ts/model/dto_common_geometry_pb';
import { Localization } from '../api/protocol-ts/model/dto_report_localization_pb';
import { PolygonMask } from '../graphics/RenderComponents/RCPolygonMask/RCPolygonMask';
import { vec2 } from '../graphics/RenderComponents/vec2';

const maskPointsToVec2 = (points: FloatPoint2D): vec2 => ({
  x: points.X,
  y: points.Y,
});

const PATHOLOGIES = '#FF4C00'; // mandarin color

export const localizationToPerioMasks = (
  localization: Localization,
): PolygonMask[] => {
  return localization.PolygonalMask.map((mask) => {
    return {
      polygon: mask.Points.map(maskPointsToVec2),
      color: PATHOLOGIES,
      stroke: PATHOLOGIES,
      opacity: 0.2,
    };
  });
};
