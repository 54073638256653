import { useAppSelector } from '@/shared/hooks';
import { Condition } from '@/shared/api/protocol-ts/model/dto_report_condition_pb';

import { conditionModel } from '@/entities/condition';
import { toothLandmarksModel } from '@/entities/toothLandmarks';

import { PblConditionWithChildren, PBLLandmark } from '../config';
import { filterPblConditions } from '../lib/filterPblConditions';
import { getPblLandmarksFromConditions } from '../lib/getPblLandmarksFromConditions';

type GetPBLForImage = (imageTargetAssetID: string) => PBLLandmark[];

export const useTeethLandmarks = (
  teethIDs: string[],
): {
  pblLandmarks: PBLLandmark[];
  getPBLsForImage: GetPBLForImage;
} => {
  // WARN: It's return all report conditions, actually.
  // TODO: Research possibility to not select all condition (could be more then 2k)
  const teethConditions = useAppSelector(
    conditionModel.selectors.selectByTeethIDs(teethIDs),
  );
  const teethLandmarks = useAppSelector(
    toothLandmarksModel.selectors.selectByTeethIDs(teethIDs),
  );

  if (teethIDs.length === 0) {
    return {
      pblLandmarks: [] as PBLLandmark[],
      getPBLsForImage: (() => []) as GetPBLForImage,
    };
  }

  const pblConditions = filterPblConditions(teethConditions);

  const pblConditionsWithChildren = pblConditions.map((condition) => {
    const conditionChildren = teethConditions.filter(
      (childCondition) =>
        childCondition.ParentID && childCondition.ParentID === condition.ID,
    );
    const newCondition = new Condition(condition) as PblConditionWithChildren;
    newCondition.children = conditionChildren;
    return newCondition;
  });

  const pblLandmarks = getPblLandmarksFromConditions(
    pblConditionsWithChildren,
    teethLandmarks,
  );

  const getPBLsForImage = (imageTargetAssetID: string) =>
    pblLandmarks.filter(
      (landmark) => landmark.imageTargetAssetID === imageTargetAssetID,
    );

  return {
    pblLandmarks,
    getPBLsForImage,
  };
};
