import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FileWithPath } from 'react-dropzone';
import { useParams } from 'react-router';

import { AssetType } from '@/shared/api/protocol-ts/model/dto_asset_pb';
import { StudyType } from '@/shared/api/protocol-ts/model/dto_study_pb';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { Modal } from '@/shared/ui';
import {
  ReportRequest_InputType_CBCT_IOS_Superimposition,
  ReportRequest_InputType_StudioImplant,
  ReportRequest_InputType_StudioOrtho,
} from '@/shared/api/protocol-ts/model/dto_report_request_pb.js';
import { ServiceType } from '@/shared/api/protocol-ts/api/billing_new/dto_services_new_pb.js';

import { ModalID, modalModel } from '@/entities/modal';
import { patientModel } from '@/entities/patient';
import { billingModel } from '@/entities/billing/index.ts';

import { useUploadAssetContext } from '@/features/uploadAsset';
import { useNewReport } from '@/features/addNewReport';

import {
  ORDER_REPORT_ASSET_TYPE,
  ORDER_REPORT_STUDY_TYPE,
  OrderReportModalStep,
  UPLOAD_STUDY_FORM_ID,
  UploadStudyPayload,
  uploadStudySchema,
} from '../../config';
import { selectFirstStudyID } from '../../model/orderReportModal.selectors';
import { orderReportTitle } from '../../config/i18n';
import { OrderReportStep } from '../OrderReportStep/OrderReportStep.tsx';

import styles from './OrderReportModal.module.scss';

export const OrderReportModal = () => {
  const dispatch = useAppDispatch();
  const { patientID } = useParams();
  const { formatMessage } = useIntl();

  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [IOSMeshesFiles, setIOSMeshesFFiles] = useState<FileWithPath[]>([]);
  const [dentalPhotoFiles, setDentalPhotoFiles] = useState<FileWithPath[]>([]);
  const [filesUploadErrorMessage, setFilesUploadErrorMessage] = useState('');
  const [
    IOSMeshesFilesUploadErrorMessage,
    setIOSMeshesFilesUploadErrorMessage,
  ] = useState('');
  const [
    dentalPhotoFilesUploadErrorMessage,
    setDentalPhotoFilesUploadErrorMessage,
  ] = useState('');

  const {
    visible,
    data: { reportType },
  } = useAppSelector(modalModel.selectors.selectOrderReportModal);

  const isCBCTIOSSuperimpositionAnalysisAvailable = useAppSelector(
    billingModel.selectors.selectIsServiceActiveByType(
      ServiceType.ServiceType_Order_CBCT_IOS_Superimposition_Analysis,
    ),
  );

  const isOrthoStudioAnalysisAvailable = useAppSelector(
    billingModel.selectors.selectIsServiceActiveByType(
      ServiceType.ServiceType_Order_OrthoStudio_Analysis,
    ),
  );

  const { startUploadAsset: startUploadStudy } = useUploadAssetContext();

  const {
    requestCBCTGPReport,
    requestIOXRayGPReport,
    requestPanoGPReport,
    requestCBCTSegmentationReport,
    requestStudioOrthoReport,
    requestStudioImplantReport,
    requestCBCTIOSSuperimpositionReport,
    requestCBCTOrthoReport,
    requestIOSSegmentationReport,
  } = useNewReport();

  const patientName = useAppSelector(
    patientModel.selectors.selectPatientFullName(patientID as string),
  );
  const firstMainStudyID = useAppSelector(
    selectFirstStudyID(ORDER_REPORT_STUDY_TYPE[reportType]),
  );
  const firstIOSMeshesStudyID = useAppSelector(
    selectFirstStudyID(StudyType.StudyType_IOS_Meshes),
  );

  const { watch, control, handleSubmit, reset, resetField } =
    useForm<UploadStudyPayload>({
      resolver: yupResolver(uploadStudySchema),
    });

  const isImplantStudioReport = reportType === 'ImplantStudio';

  const canOrderCBCTIOSSuperimpositionReport =
    isCBCTIOSSuperimpositionAnalysisAvailable && reportType === '3DStudio';

  const canOrderOrthoStudioAnalysis =
    isOrthoStudioAnalysisAvailable && reportType === 'OrthoStudio';

  const firstStepStudyAssetType = ORDER_REPORT_ASSET_TYPE[reportType];

  const isSecondStepAvailable =
    isImplantStudioReport ||
    canOrderCBCTIOSSuperimpositionReport ||
    canOrderOrthoStudioAnalysis;

  const isSecondStepOptional = canOrderCBCTIOSSuperimpositionReport;

  const showDentalPhotoUpload =
    isImplantStudioReport ||
    canOrderCBCTIOSSuperimpositionReport ||
    canOrderOrthoStudioAnalysis;

  const currentMainStudyID = watch('mainStudyID');
  const currentIOSMeshesStudyID = watch('IOSMeshesStudyID');
  const currentDentalPhotoStudyID = watch('DentalPhotoStudyID');

  const uploadFilesWithError = !!(
    filesUploadErrorMessage ||
    IOSMeshesFilesUploadErrorMessage ||
    dentalPhotoFilesUploadErrorMessage
  );

  const disableSubmit =
    (!currentMainStudyID && files.length === 0) ||
    (isSecondStepAvailable &&
      !isSecondStepOptional &&
      !currentIOSMeshesStudyID &&
      IOSMeshesFiles.length === 0) ||
    uploadFilesWithError;

  const setErrorMessage =
    (filesStep: OrderReportModalStep) => (errorMessage: string) => {
      switch (filesStep) {
        case 1: {
          setFilesUploadErrorMessage(errorMessage);
          break;
        }
        case 2: {
          setIOSMeshesFilesUploadErrorMessage(errorMessage);
          break;
        }
        case 3: {
          setDentalPhotoFilesUploadErrorMessage(errorMessage);
        }
      }
    };

  const addFiles =
    (filesStep: OrderReportModalStep) => (acceptedFiles: FileWithPath[]) => {
      switch (filesStep) {
        case 1: {
          setFilesUploadErrorMessage('');
          setFiles((currentFiles) => currentFiles.concat(acceptedFiles));
          break;
        }
        case 2: {
          setIOSMeshesFFiles((currentFiles) =>
            currentFiles.concat(acceptedFiles),
          );
          break;
        }
        case 3: {
          setDentalPhotoFiles((currentFiles) =>
            currentFiles.concat(acceptedFiles),
          );
          break;
        }
      }
    };

  const removeFiles = (filesStep: OrderReportModalStep) => () => {
    switch (filesStep) {
      case 1: {
        setFiles([]);
        resetField('mainStudyID');
        break;
      }
      case 2: {
        setIOSMeshesFFiles([]);
        resetField('IOSMeshesStudyID');
        break;
      }
      case 3: {
        setDentalPhotoFiles([]);
        resetField('DentalPhotoStudyID');
        break;
      }
    }
  };

  const removeAllFiles = () => {
    setFiles([]);
    setIOSMeshesFFiles([]);
    setDentalPhotoFiles([]);
  };

  const handleClose = useCallback(() => {
    reset();
    dispatch(modalModel.actions.closeModal(ModalID.OrderReport));

    removeAllFiles();
  }, [dispatch, removeAllFiles]);

  const onSubmit: SubmitHandler<UploadStudyPayload> = async (data) => {
    handleClose();

    const uploadStudyPromises: Promise<void>[] = [];
    const {
      mainStudyID: currentMainStudyID,
      IOSMeshesStudyID: currentIOSMeshesStudyID,
      DentalPhotoStudyID: currentDentalPhotoStudyID,
    } = data;

    let mainStudyID = currentMainStudyID;
    let IOSMeshesStudyID = currentIOSMeshesStudyID;
    let DentalPhotoStudyID = currentDentalPhotoStudyID;

    if (files.length > 0) {
      const uploadStudyPromise = startUploadStudy({
        patientID,
        files,
        assetType: firstStepStudyAssetType,
        meta: {
          studyType: firstStepStudyAssetType,
          patientName,
        },
      }).then(({ studyID }) => {
        mainStudyID = studyID;
      });

      uploadStudyPromises.push(uploadStudyPromise);
    }

    if (IOSMeshesFiles.length > 0) {
      const uploadIOSMeshesStudyPromise = startUploadStudy({
        patientID,
        files: IOSMeshesFiles,
        assetType: AssetType.AssetType_Study_IOS_Meshes,
        meta: {
          studyType: AssetType.AssetType_Study_IOS_Meshes,
          patientName,
        },
      }).then(({ studyID }) => {
        IOSMeshesStudyID = studyID;
      });

      uploadStudyPromises.push(uploadIOSMeshesStudyPromise);
    }

    // Add the uploadDentalPhotoStudy promise if there are dental photo files
    if (dentalPhotoFiles.length > 0) {
      const uploadDentalPhotoStudyPromise = startUploadStudy({
        patientID,
        files: dentalPhotoFiles,
        assetType: AssetType.AssetType_Study_DentalPhoto,
        meta: {
          studyType: AssetType.AssetType_Study_DentalPhoto,
          patientName,
        },
      }).then(({ studyID }) => {
        DentalPhotoStudyID = studyID;
      });

      uploadStudyPromises.push(uploadDentalPhotoStudyPromise);
    }

    const uploadStudyResults = await Promise.allSettled(uploadStudyPromises);

    if (
      mainStudyID &&
      !uploadStudyResults.some((result) => result.status === 'rejected')
    ) {
      switch (reportType) {
        case 'CBCT': {
          requestCBCTGPReport(mainStudyID);
          break;
        }
        case 'Pano': {
          requestPanoGPReport(mainStudyID);
          break;
        }
        case 'IOXRay': {
          requestIOXRayGPReport(mainStudyID);
          break;
        }
        case 'IOSSegmentation': {
          requestIOSSegmentationReport(mainStudyID);
          break;
        }
        case '3DStudio': {
          isCBCTIOSSuperimpositionAnalysisAvailable && IOSMeshesStudyID
            ? requestCBCTIOSSuperimpositionReport(
                new ReportRequest_InputType_CBCT_IOS_Superimposition({
                  CBCTStudyID: mainStudyID,
                  STLStudyID: IOSMeshesStudyID as string,
                  DentalPhotoStudyID: DentalPhotoStudyID as string,
                }),
              )
            : requestCBCTSegmentationReport(mainStudyID);
          break;
        }
        case 'ImplantStudio': {
          requestStudioImplantReport(
            new ReportRequest_InputType_StudioImplant({
              CBCTStudyID: mainStudyID,
              IOSMeshesStudyID: IOSMeshesStudyID as string,
              DentalPhotoStudyID: DentalPhotoStudyID as string,
            }),
          );
          break;
        }
        case 'OrthoStudio': {
          isOrthoStudioAnalysisAvailable
            ? requestStudioOrthoReport(
                new ReportRequest_InputType_StudioOrtho({
                  CBCTStudyID: mainStudyID,
                  IOSMeshesStudyID: IOSMeshesStudyID as string,
                  DentalPhotoStudyID: DentalPhotoStudyID as string,
                }),
              )
            : requestCBCTOrthoReport(mainStudyID);

          break;
        }

        default: {
          break;
        }
      }
    }
  };

  useEffect(() => {
    reset({
      mainStudyID: firstMainStudyID,
      IOSMeshesStudyID: isSecondStepOptional
        ? undefined
        : firstIOSMeshesStudyID,
    });
  }, [firstMainStudyID, firstIOSMeshesStudyID, isSecondStepOptional]);

  return (
    <Modal
      containerClassName={styles.modalContainer}
      title={formatMessage(orderReportTitle[reportType])}
      isOpen={visible}
      onCancel={handleClose}
      borderless
      okButtonText={
        <FormattedMessage
          id="orderReportModal.applyButtonText"
          defaultMessage="Plan"
        />
      }
      okButtonProps={{
        type: 'submit',
        form: UPLOAD_STUDY_FORM_ID,
        disabled: disableSubmit,
      }}
      cancelButtonProps={{
        variant: 'tertiary',
      }}
    >
      <form
        id={UPLOAD_STUDY_FORM_ID}
        onSubmit={handleSubmit(onSubmit)}
        className={styles.container}
      >
        <OrderReportStep
          control={control}
          currentStudyID={currentMainStudyID}
          step={isSecondStepAvailable ? 1 : undefined}
          orderReportType={reportType}
          studyType={ORDER_REPORT_STUDY_TYPE[reportType]}
          assetType={firstStepStudyAssetType}
          errorMessage={filesUploadErrorMessage}
          files={files}
          onlyUpload={reportType === 'IOSSegmentation'}
          onAddFiles={addFiles(1)}
          onDeleteFiles={removeFiles(1)}
          onError={setErrorMessage(1)}
        />

        {isSecondStepAvailable && (
          <OrderReportStep
            control={control}
            currentStudyID={currentIOSMeshesStudyID}
            step={2}
            orderReportType={reportType}
            studyType={StudyType.StudyType_IOS_Meshes}
            assetType={AssetType.AssetType_Study_IOS_Meshes}
            errorMessage={IOSMeshesFilesUploadErrorMessage}
            files={IOSMeshesFiles}
            onAddFiles={addFiles(2)}
            onDeleteFiles={removeFiles(2)}
            onError={setErrorMessage(2)}
            onReset={() => resetField('IOSMeshesStudyID')}
            isOptional={isSecondStepOptional}
            infoTooltip={{
              show: isSecondStepAvailable,
              message: formatMessage({
                id: 'orderReportModal.superimpositionInfo',
                defaultMessage:
                  'Requires 1 file for upper jaw and 1 file for lower jaw',
              }),
            }}
          />
        )}

        {showDentalPhotoUpload && (
          <OrderReportStep
            control={control}
            currentStudyID={currentDentalPhotoStudyID}
            step={3}
            orderReportType={reportType}
            studyType={StudyType.StudyType_DentalPhoto}
            assetType={AssetType.AssetType_Study_DentalPhoto}
            errorMessage={dentalPhotoFilesUploadErrorMessage}
            files={dentalPhotoFiles}
            isOptional
            onlyUpload
            onAddFiles={addFiles(3)}
            onDeleteFiles={removeFiles(3)}
            onReset={() => resetField('DentalPhotoStudyID')}
            onError={setErrorMessage(3)}
          />
        )}
      </form>
    </Modal>
  );
};
