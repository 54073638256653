import { Tooth } from '@/shared/api/protocol-ts/model/dto_report_tooth_pb';
import { LocalizationType } from '@/shared/api/protocol-ts/model/dto_report_localization_pb';
import { Localization } from '@/shared/graphics/RenderComponents/RCDetectedTeeth/RCDetectedTeeth.tsx';

export const makeDetectionsFromDeletedToothLocalizations = (teeth: Tooth[]) => {
  if (!teeth.length) {
    return [];
  }

  return teeth.reduce((deletedTeethLocalizationsAccumulator, tooth) => {
    const localizations = tooth.Detections.find(
      (detection) =>
        detection.Localizations?.Type === LocalizationType.LocalizationType2D,
    )?.Localizations;

    if (localizations) {
      const BBox = localizations?.BBox;

      deletedTeethLocalizationsAccumulator.push({
        tooth: {
          toothID: tooth.ID,
          ISONumber: tooth.Numeration?.ISO ?? 0,
          SupernumeraryIndex: tooth.Numeration?.SupernumeraryIndex ?? 0,
          isRemoved: tooth?.IsRemoved,
          Localization: localizations,
        },
        xmin: BBox?.X?.Min ?? 0,
        ymin: BBox?.Y?.Min ?? 0,
        xmax: BBox?.X?.Max ?? 0,
        ymax: BBox?.Y?.Max ?? 0,
      });
    }

    return deletedTeethLocalizationsAccumulator;
  }, [] as Localization[]);
};
