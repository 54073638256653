import { ReportType } from '@/shared/api/protocol-ts/model/dto_report_pb';
import segmentronPreview from '@/shared/assets/images/segmentronPreview.png';
import intraoralSegmentronPreview from '@/shared/assets/images/intraoralSegmentronPreview.png';

export const getSegmentronPreview = (reportType: ReportType) => {
  if (reportType === ReportType.ReportType_IOS_Segmentation) {
    return intraoralSegmentronPreview;
  }

  return segmentronPreview;
};
