import groupBy from 'lodash/groupBy';

import { Condition } from '@/shared/api/protocol-ts/model/dto_report_condition_pb';

import { splitConditions } from './splitConditions';
import { makeLogicalCondition } from './makeLogicalCondition';

export const processLogicalConditions = (toothConditions: Condition[]) => {
  const { conditions, childrenConditions } = splitConditions(toothConditions);

  const logicalConditions = Object.entries(conditions).flatMap(
    ([code, groupedConditions]) => {
      const children = childrenConditions[code];
      const childrenGroupedByCode = groupBy(children, 'Code');

      const logicalCondition = makeLogicalCondition(groupedConditions);

      const logicalChildrenCondition = Object.values(
        childrenGroupedByCode,
      ).flatMap(makeLogicalCondition);

      // WARN: add merged child conditions into parent
      logicalCondition.Children = logicalChildrenCondition;

      return logicalCondition;
    },
  );

  return logicalConditions;
};
