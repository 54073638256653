import { ascend, pathOr, prop, sortWith } from 'ramda';
import { useIntl } from 'react-intl';

import { SelectProps } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';
import { getName } from '@/shared/lib';
import { OrganizationUserRole } from '@/shared/api/protocol-ts/model/dto_organization_pb';

import { organizationModel } from '@/entities/organization';
import { userModel } from '@/entities/user';

import { ALL_DOCTORS_OPTION } from '../config';

export const useOrganizationUsersOptions = (
  currentUserID: string,
): SelectProps['options'] => {
  const { formatMessage } = useIntl();

  const treatingDoctors = useAppSelector(
    organizationModel.selectors.selectTreatingDoctors,
  );
  const userLocale = useAppSelector(userModel.selectors.selectUserLocale);

  const sortedUsers: OrganizationUserRole[] = sortWith<OrganizationUserRole>(
    [
      ascend((user) => (user.UserID === currentUserID ? 0 : 1)),
      ascend(pathOr('', ['PersonalData', 'LastName'])),
      ascend(pathOr('', ['PersonalData', 'FirstName'])),
      ascend(prop('UserID')),
    ],
    treatingDoctors ?? [],
  );

  const options = sortedUsers.map((user) => ({
    value: user.UserID,
    label: getName(user.PersonalData?.FirstName, user.PersonalData?.LastName, {
      userLocale,
    }),
  }));

  return [
    {
      label: formatMessage({ id: 'global.all', defaultMessage: 'All' }),
      value: ALL_DOCTORS_OPTION,
    },
    ...options,
  ];
};
