import React, { FC, memo, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { useAppSelector } from '@/shared/hooks';

import { slicesModel } from '@/entities/slices';

import { useAddAndRemoveMedicalImage } from '@/features/useAddAndRemoveMedicalImage';

import styles from './SelectedMedicalImages.module.scss';

type SelectedMedicalImagesProps = {
  toothID: string;
  checkIsTheMedicalImageSelected: (assetID: string) => boolean;
  className?: string;
};

// Simplified for tooth card component as dnd placeholder
export const SelectedMedicalImages: FC<SelectedMedicalImagesProps> = memo(
  (props) => {
    const { className, toothID, checkIsTheMedicalImageSelected } = props;

    const draggedSliceID = useAppSelector(
      slicesModel.selectors.selectDraggedSliceID,
    );

    const [dragOvered, setDragOvered] = useState(false);

    const { addMedicalImageToSelected } = useAddAndRemoveMedicalImage();

    const handleDragOver = (event: React.MouseEvent) => {
      event.preventDefault();
      setDragOvered(true);
    };

    const handleDragLeave = (event: React.MouseEvent) => {
      event.preventDefault();
      setDragOvered(false);
    };

    const handleDrop = (event: React.MouseEvent) => {
      event.preventDefault();
      if (!checkIsTheMedicalImageSelected(draggedSliceID)) {
        addMedicalImageToSelected(draggedSliceID, toothID);
      }

      setDragOvered(false);
    };

    return (
      <div
        className={cn(
          className,
          styles.container,
          draggedSliceID && styles.dragged,
          dragOvered && styles.dragOvered,
        )}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <h6 className={cn(styles.title, 'h6')}>
          <FormattedMessage
            id="selectedMedicalImages.addImage"
            defaultMessage="Add image"
          />
        </h6>

        <span className={cn(styles.description, 'p3')}>
          <FormattedMessage
            id="selectedMedicalImages.dragDropDescription"
            defaultMessage="drag&drop images here"
          />
        </span>
      </div>
    );
  },
);
