import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import { generatePath, useNavigate } from 'react-router';
import { FieldValues, UseFormSetError } from 'react-hook-form';
import { ConnectError } from '@bufbuild/connect';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { toastCaller } from '@/shared/ui';
import {
  PATHS,
  ToastErrors,
  toastErrors,
  UserBadRequestError,
} from '@/shared/config';
import { getName, safeJSONParse } from '@/shared/lib';

import { accessModel } from '@/entities/access';
import { PATH_TO_PATIENTS_SHARED_WITH_ME_LIST } from '@/entities/patient';
import { userModel } from '@/entities/user';

import { useAcceptSharingPatientInvite } from '@/features/sharePatient/hooks/useAcceptSharingPatientInvite';

export const useOrganizationInvite = (invitationToken: string) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const handleGetInvitationByToken = async (token: string) => {
    try {
      const { Invitation } = await dispatch(
        accessModel.thunks.getInvitationByToken({ Token: token }),
      ).unwrap();

      if (Invitation) {
        dispatch(accessModel.actions.setOne(Invitation));
      }
    } catch (error) {
      navigate(PATHS.patients);
    }
  };

  // initialize the invite
  useEffect(() => {
    handleGetInvitationByToken(invitationToken);
  }, []);

  const invitation = useAppSelector(
    accessModel.selectors.selectInvitationByToken(invitationToken),
  );
  const userLocale = useAppSelector(userModel.selectors.selectUserLocale);

  const invitationStatus = invitation?.Status;

  const invitationCreatedAt = invitation?.Created?.At;

  const senderFullName = getName(
    invitation?.TargetDetails?.SenderFirstName,
    invitation?.TargetDetails?.SenderLastName,
    { userLocale },
  );

  const acceptSharingPatientInvite = useAcceptSharingPatientInvite();

  const navigateForAcceptedInvite = () => {
    if (invitation?.Target?.Target.case === 'Patient') {
      navigate({
        pathname: generatePath(PATHS.patientProfile_DEPRECATED, {
          patientID: invitation?.Target?.Target.value.PatientID,
        }),
      });
    } else if (invitation?.Target?.Target.case === 'Organization') {
      navigate(PATHS.patients, {
        state: {
          invitationOrganizationID: invitation?.OrganizationID,
          invitationOrganizationName:
            invitation?.TargetDetails?.OrganizationName,
        },
      });
    }
  };

  const navigateToSharedWithMeList = () => {
    navigate(PATH_TO_PATIENTS_SHARED_WITH_ME_LIST);
  };

  const acceptSharingPatientInviteHandle = async (
    accessCode: string,
    setError: UseFormSetError<FieldValues>,
  ) => {
    try {
      if (invitation) {
        await acceptSharingPatientInvite({
          invitationID: invitation.ID,
          accessCode,
        });
      }

      navigateForAcceptedInvite();
    } catch (error) {
      if (error instanceof ConnectError) {
        const parsedMessage = safeJSONParse(error.rawMessage);
        const errorCode = parsedMessage?.code;

        if (errorCode === UserBadRequestError.INCORRECT_INVITATION_SECRET) {
          setError('accessCode', {
            message: formatMessage({
              id: 'addSharingPatient.error.incorrectAccessCode',
              defaultMessage:
                'Access code is incorrect. Contact the sender to receive the correct access code.',
            }),
          });
        } else if (
          errorCode ===
          UserBadRequestError.INVITATION_SECRET_INPUT_LIMIT_EXCEEDED
        ) {
          setError('accessCode', {
            type: 'canceledCode',
            message: formatMessage({
              id: 'addSharingPatient.error.canceledAccessCode',
              defaultMessage:
                'You have entered access code too many times. Your access to the patient has been canceled. Contact the sender to send you a patient profile once again.',
            }),
          });
        }
      }
    }
  };

  // NOTE: This is not an error in the direct sense; it is a message about the cancellation of the invitation
  const showCancelledInvitationToast = () => {
    const errorHeading = formatMessage(toastErrors[ToastErrors.errorHeading]);

    toastCaller({
      type: 'error',
      heading: errorHeading,
      message: formatMessage({
        id: 'addSharingPatient.error.noAccess',
        defaultMessage:
          'The invitation has been canceled. You no longer have access to the patient.',
      }),
      autoClose: 10000,
    });
  };

  return {
    invitationStatus,
    senderFullName,
    invitationCreatedAt,
    acceptSharingPatientInviteHandle,
    navigateForAcceptedInvite,
    navigateToSharedWithMeList,
    showCancelledInvitationToast,
  };
};
