import { FC, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { compose, filter, sort, map } from 'ramda';
import cn from 'classnames';
import { groupBy } from 'lodash';

import { Modal, Input, Tabs, WidgetCard } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { ConditionCode } from '@/shared/api/protocol-ts/model/dto_report_condition_codes_pb';

import {
  ConditionToggle,
  ConditionToggleList,
  ConditionChildGroup,
  ConditionToggleContainer,
  sortCompactConditionByProbability,
  ConditionListByCategory,
  CompactCondition,
  i18n,
  compactConditionHasProbability,
  ConditionGroups,
} from '@/entities/condition';
import { modalModel, ModalID } from '@/entities/modal';
import { composedModel } from '@/entities/composed';

import { useUpdateConditionDecision } from '@/features/updateConditionDecision';

import styles from './AddConditionModal.module.scss';

enum TabValue {
  Probability = 'Probability',
  Category = 'Category',
  Alphabetically = 'Alphabetically',
}

const useAddCondition = (data: CompactCondition[]) => {
  const [search, setSearch] = useState('');

  const { formatMessage } = useIntl();

  // Create a mapping of codes to localized messages
  const localizedMap = data.reduce(
    (acc, item) => {
      if (item.code in i18n.conditionText) {
        acc[item.code] = formatMessage(i18n.conditionText[item.code]);
      }

      if (item.childConditionInterfaces.length > 0) {
        item.childConditionInterfaces.forEach((childrenItem) => {
          if (childrenItem.code in i18n.conditionText) {
            acc[childrenItem.code] = formatMessage(
              i18n.conditionText[childrenItem.code],
            );
          }
        });
      }

      return acc;
    },
    {} as Record<ConditionCode, string>,
  );

  // Filter data based on search term
  const filteredData = data.filter((compactCondition) => {
    const lowerCaseSearch = search.toLowerCase();
    const searchIsMatch = localizedMap[compactCondition.code]
      .toLowerCase()
      .includes(lowerCaseSearch);

    if (!searchIsMatch) {
      compactCondition.childConditionInterfaces =
        compactCondition.childConditionInterfaces?.filter(
          (childrenCompactCondition) =>
            localizedMap[childrenCompactCondition.code]
              .toLowerCase()
              .includes(lowerCaseSearch),
        );
    }

    return (
      searchIsMatch ||
      (compactCondition.childConditionInterfaces?.length ?? 0) > 0
    );
  });

  const compactedConditionsSortedByAlphabet = sort(
    (a, b) => localizedMap[a.code].localeCompare(localizedMap[b.code]),
    filteredData,
  );

  const compactedConditionsSortedByProbability = compose(
    map<CompactCondition, CompactCondition>((compactCondition) => {
      const compactConditionClone = structuredClone(compactCondition);

      compactConditionClone.childConditionInterfaces = compose(
        sort(sortCompactConditionByProbability),
        filter(compactConditionHasProbability),
      )(compactConditionClone.childConditionInterfaces);

      return compactConditionClone;
    }),
    sort(sortCompactConditionByProbability),
    filter(compactConditionHasProbability),
  )(filteredData);

  const conditionItemsByGroups = groupBy(filteredData, 'group') as Record<
    ConditionGroups,
    CompactCondition[]
  >;

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
  };

  return {
    search,
    handleSearch,
    conditionItemsSortedByProbability: compactedConditionsSortedByProbability,
    conditionItemsByGroups,
    conditionItemsSortedByAlphabet: compactedConditionsSortedByAlphabet,
  };
};

type AddConditionCardProps = {
  className?: string;
  tabContentClassName?: string;
  title?: React.ReactNode;
  toothID: string;
  conditionID?: string;
};

export const AddConditionCard: FC<AddConditionCardProps> = (props) => {
  const { title, className, tabContentClassName, toothID, conditionID } = props;

  const { formatMessage } = useIntl();

  const compactConditions = useAppSelector(
    composedModel.selectors.selectAllowedCompactToothConditions(toothID),
  );

  const {
    search,
    handleSearch,
    conditionItemsSortedByProbability,
    conditionItemsByGroups,
    conditionItemsSortedByAlphabet,
  } = useAddCondition(compactConditions);

  const { updateConditionDecision, isLoading } =
    useUpdateConditionDecision(toothID);

  const defaultTabValue = conditionID
    ? TabValue.Category
    : TabValue.Probability;

  return (
    <WidgetCard className={cn(styles.card, className)}>
      {title && <h4 className="h4">{title}</h4>}

      <Tabs.Root className={styles.tabRoot} defaultValue={defaultTabValue}>
        <Tabs.List size="small">
          <Tabs.Trigger value={TabValue.Probability} size="small">
            <FormattedMessage
              id="addConditionModal.tab.probability"
              defaultMessage="Probability"
            />
          </Tabs.Trigger>

          <Tabs.Trigger value={TabValue.Category} size="small">
            <FormattedMessage
              id="addConditionModal.tab.category"
              defaultMessage="Category"
            />
          </Tabs.Trigger>

          <Tabs.Trigger value={TabValue.Alphabetically} size="small">
            <FormattedMessage
              id="addConditionModal.tab.alphabetically"
              defaultMessage="Alphabetically"
            />
          </Tabs.Trigger>
        </Tabs.List>

        <Input
          className={styles.search}
          type="search"
          name="search-condition"
          inputMode="search"
          icon="search"
          placeholder={formatMessage({
            id: 'addConditionModal.search',
            defaultMessage: 'Search',
          })}
          value={search}
          onChange={handleSearch}
        />

        <Tabs.Content
          value={TabValue.Probability}
          className={cn(styles.tabContent, tabContentClassName)}
        >
          <ConditionToggleList>
            {conditionItemsSortedByProbability.map((data) => {
              const { childConditionInterfaces } = data;

              return childConditionInterfaces &&
                childConditionInterfaces.length > 0 ? (
                <ConditionToggleContainer key={data.code}>
                  <ConditionToggle
                    data={data}
                    onChange={updateConditionDecision}
                    disabled={isLoading}
                  />

                  <div className={styles.flatChildsContainer}>
                    {childConditionInterfaces.map((child) => (
                      <ConditionToggle
                        key={child.code}
                        data={child}
                        onChange={updateConditionDecision}
                      />
                    ))}
                  </div>
                </ConditionToggleContainer>
              ) : (
                <ConditionToggle
                  key={data.code}
                  data={data}
                  onChange={updateConditionDecision}
                  disabled={isLoading}
                />
              );
            })}
          </ConditionToggleList>
        </Tabs.Content>

        <Tabs.Content
          value={TabValue.Category}
          className={cn(styles.tabContent, tabContentClassName)}
        >
          <ConditionListByCategory
            conditionID={conditionID}
            conditionItemsByGroups={conditionItemsByGroups}
            updateConditionDecision={updateConditionDecision}
            disabled={isLoading}
            toothID={toothID}
          />
        </Tabs.Content>

        <Tabs.Content
          value={TabValue.Alphabetically}
          className={cn(styles.tabContent, tabContentClassName)}
        >
          <ConditionToggleList>
            {conditionItemsSortedByAlphabet.map((data) =>
              data.childConditionInterfaces.length > 0 ? (
                <ConditionToggleContainer key={data.code}>
                  <ConditionToggle
                    data={data}
                    onChange={updateConditionDecision}
                    disabled={isLoading}
                  />

                  <ConditionChildGroup
                    className={styles.childGroup}
                    data={data.childConditionInterfaces}
                    onChange={updateConditionDecision}
                    disabled={isLoading || !data?.isChecked}
                  />
                </ConditionToggleContainer>
              ) : (
                <ConditionToggle
                  key={data.code}
                  data={data}
                  onChange={updateConditionDecision}
                  disabled={isLoading}
                />
              ),
            )}
          </ConditionToggleList>
        </Tabs.Content>
      </Tabs.Root>
    </WidgetCard>
  );
};

export const AddConditionModal: FC = () => {
  const dispatch = useAppDispatch();

  const {
    visible,
    data: { toothID, conditionID, displayToothNumber },
  } = useAppSelector((state) => state.modal.AddCondition);

  const handleClose = useCallback(() => {
    dispatch(modalModel.actions.closeModal(ModalID.AddCondition));
  }, [dispatch]);

  return (
    <Modal
      title={
        <FormattedMessage
          id="addConditionModal.title"
          defaultMessage="Tooth {toothNumber} conditions"
          values={{ toothNumber: displayToothNumber }}
        />
      }
      isOpen={visible}
      onCancel={handleClose}
      className={styles.modal}
      containerClassName={styles.modalContainer}
      hideFooter
      borderless
    >
      <AddConditionCard
        toothID={toothID}
        conditionID={conditionID}
        className={styles.modalCard}
      />
    </Modal>
  );
};
