import {
  StartUploadSessionReq,
  StartUploadSessionResp,
  CloseSessionReq,
  CloseSessionResp,
  NotifyFileUploadStartedReq,
  NotifyFileUploadStartedResp,
  NotifyFileUploadProgressReq,
  NotifyFileUploadProgressResp,
  NotifyFileUploadFinishedReq,
  FailSessionReq,
  FailSessionResp,
} from '@/shared/api/protocol-ts/api/core/svc_upload_pb';
import api from '@/shared/api/api';
import { createThunkGenerator } from '@/shared/lib';
import { SliceName } from '@/shared/config';
import {
  DeleteStudyReq,
  DeleteStudyResp,
} from '@/shared/api/protocol-ts/api/core/svc_study_pb';
import { StorageService } from '@/shared/api/protocol-ts/api/core/svc_upload_connectweb';
import { StudyService } from '@/shared/api/protocol-ts/api/core/svc_study_connectweb';
import { UploadSession } from '@/shared/api/protocol-ts/model/dto_upload_session_pb';

import { notifyFileUploadFinishedUpcoming } from '../lib';

export type StorageRequestsType = keyof typeof StorageService.methods;

type StudyRequestsType = keyof typeof StudyService.methods;

const generateStorageThunk = createThunkGenerator<StorageRequestsType>(
  SliceName.storage,
);

const generateStudyThunk = createThunkGenerator<StudyRequestsType>(
  SliceName.study,
);

export const deleteStudy = generateStudyThunk<DeleteStudyReq, DeleteStudyResp>(
  'deleteStudy',
  api.study.deleteStudy,
);

export const startUploadSession = generateStorageThunk<
  StartUploadSessionReq,
  StartUploadSessionResp
>('startUploadSession', api.storage.startUploadSession);

export const closeSession = generateStorageThunk<
  CloseSessionReq,
  CloseSessionResp
>('closeSession', api.storage.closeSession);

export const notifyFileUploadStarted = generateStorageThunk<
  NotifyFileUploadStartedReq,
  NotifyFileUploadStartedResp
>('notifyFileUploadStarted', api.storage.notifyFileUploadStarted);

export const notifyFileUploadProgress = generateStorageThunk<
  NotifyFileUploadProgressReq,
  NotifyFileUploadProgressResp
>('notifyFileUploadProgress', api.storage.notifyFileUploadProgress);

export const notifyFileUploadFinished = generateStorageThunk<
  NotifyFileUploadFinishedReq,
  UploadSession | undefined
>('notifyFileUploadFinished_Upcoming', notifyFileUploadFinishedUpcoming);

export const failSession = generateStorageThunk<
  FailSessionReq,
  FailSessionResp
>('failSession', api.storage.failSession);
