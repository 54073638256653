import { filter, keys } from 'ramda';

import { CompactCondition, ConditionGroups } from '../config';

type ConditionItemsByGroupsType = Record<ConditionGroups, CompactCondition[]>;

export const useConditionGroupsList = (
  conditionItemsByGroups: ConditionItemsByGroupsType,
) => {
  const filteredConditionItemsByGroups = Object.entries(
    conditionItemsByGroups,
  ).reduce((result, [group, items]) => {
    if (items.length > 0) {
      result[group as keyof ConditionItemsByGroupsType] = items;
    }

    return result;
  }, {} as Partial<ConditionItemsByGroupsType>);

  const conditionGroupsList = keys(filteredConditionItemsByGroups);

  const openConditionGroups = filter((group: ConditionGroups) => {
    const conditionItems = filteredConditionItemsByGroups[group] ?? [];
    return conditionItems.some((item) => item.isChecked);
  })(conditionGroupsList);

  return { conditionGroupsList, openConditionGroups };
};
